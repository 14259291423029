import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, FieldDto, ITablePageable, getEnumOptions } from '@shared/classes';

@Component({
  selector: 'app-dynamic-field-type-selector',
  templateUrl: './dynamic-field-type-selector.component.html',
  styleUrls: ['./dynamic-field-type-selector.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: DynamicFieldTypeSelectorComponent }],
})
export class DynamicFieldTypeSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = false;
  @Input() optionValue: string = 'value';
  @Input() appendTo: string = null;
  constructor() {
    super();
  }
  items: any[] = getEnumOptions(FieldDto.TypeEnum).map((x) =>
    x.value == FieldDto.TypeEnum.String ? { label: 'Text', value: FieldDto.TypeEnum.String } : { ...x }
  );
  pageInfo: ITablePageable = new ITablePageable();
  isLoading: boolean = false;
  @Input() dataType: DataTypeEnum = DataTypeEnum.Icon;

  ngOnInit(): void { }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
  }
}
