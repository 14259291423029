<div class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start overflow-hidden">
  @if (true) {
    @if (label) {
      <label [for]="label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label"
        >{{ label }}
        @if (isControlRequired) {
          <span class="text-red-500"> *</span>
        }
      </label>
    }
  }
  <div class="field mt-2 w-full">
    <div cdkDropList [cdkDropListData]="list" (cdkDropListDropped)="onDrop($event)" class="w-full">
      <div
        *ngFor="let task of list; let i = index; trackBy: trackByFn"
        cdkDrag
        class="w-full flex items-center py-1 rounded-md my-1"
      >
        <i
          *ngIf="viewMode != 'view'"
          cdkDragHandle
          class="custom-plus icon-size-5 text-hint layout-inline-menu-icon pi pi-bars"
          title="Drag to reorder"
        ></i>
        <input
          appMaxCharacterValidator
          label="Text Field"
          pInputText
          [disabled]="viewMode == 'view'"
          [placeholder]="placeholder || 'Add URL'"
          [value]="task"
          (input)="updateTaskOnNote(myinput.value, i)"
          #myinput
          class="mb-1"
        />
        <i
          *ngIf="viewMode != 'view'"
          class="custom-plus -ml-0.5 icon-size-5 text-hint layout-inline-menu-icon pi pi-times"
          (click)="removeTaskFromNote(i)"
        ></i>
      </div>
    </div>

    @if (viewMode != 'view') {
      <div class="flex items-center">
        <!-- <i
          class="custom-plus cursor-auto icon-size-5 text-hint layout-inline-menu-icon pi pi-bars"
          style="color: transparent"
        ></i> -->
        <input
          appMaxCharacterValidator
          [class.ng-invalid]="ngControl?.invalid"
          [class.ng-touched]="ngControl?.touched"
          [class.ng-dirty]="ngControl?.dirty"
          class="w-full"
          pInputText
          [placeholder]="placeholder || 'Add URL'"
          (keydown.enter)="addTaskToNote(newTaskInput.value); newTaskInput.value = ''"
          #newTaskInput
        />
        <i
          class="custom-plus -ml-0.5 icon-size-5 text-hint layout-inline-menu-icon pi pi-plus"
          (click)="addTaskToNote(newTaskInput.value); newTaskInput.value = ''"
        ></i>
      </div>
    }
    @if (ngControl?.dirty) {
      @if (ngControl?.hasError('required')) {
        <small class="p-error p-invalid">{{ label }} {{ 'is Required' }}</small>
      }
      @if (ngControl?.hasError('minlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a minimum length of {{ ngControl?.errors?.minlength?.requiredLength }}
        </small>
      }
      @if (ngControl?.hasError('maxlength')) {
        <small class="p-error p-invalid">
          {{ label }} must have a maximum length of {{ ngControl?.errors?.maxlength?.requiredLength }}
        </small>
      }
      @if (ngControl?.hasError('min')) {
        <small class="p-error p-invalid">
          {{ label }} must be greater than or equal to {{ ngControl?.errors?.min?.min }}
        </small>
      }
      @if (ngControl?.hasError('max')) {
        <small class="p-error p-invalid">
          {{ label }} must be less than or equal to {{ ngControl?.errors?.max?.max }}
        </small>
      }
    }
  </div>
</div>
