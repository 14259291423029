export const locales = [
  { code: 'AED', locale: 'en-US', direction: 'LTR', name: 'English (United States)' }, // United Arab Emirates Dirham ar-AE
  { code: 'AFN', locale: 'ps-AF', direction: 'RTL', name: 'پښتو (افغانستان)' }, // Afghan Afghani
  { code: 'ALL', locale: 'sq-AL', direction: 'LTR', name: 'Shqip (Shqipëria)' }, // Albanian Lek
  { code: 'AMD', locale: 'hy-AM', direction: 'LTR', name: 'Հայերեն (Հայաստան)' }, // Armenian Dram
  { code: 'ANG', locale: 'nl-AW', direction: 'LTR', name: 'Nederlands (Aruba)' }, // Netherlands Antillean Guilder
  { code: 'AOA', locale: 'pt-AO', direction: 'LTR', name: 'Português (Angola)' }, // Angolan Kwanza
  { code: 'ARS', locale: 'es-AR', direction: 'LTR', name: 'Español (Argentina)' }, // Argentine Peso
  { code: 'AUD', locale: 'en-AU', direction: 'LTR', name: 'English (Australia)' }, // Australian Dollar
  { code: 'AWG', locale: 'nl-AW', direction: 'LTR', name: 'Nederlands (Aruba)' }, // Aruban Florin
  { code: 'AZN', locale: 'az-AZ', direction: 'LTR', name: 'Azərbaycan (Azərbaycan)' }, // Azerbaijani Manat
  { code: 'BAM', locale: 'bs-Latn-BA', direction: 'LTR', name: 'Bosanski (Bosna i Hercegovina)' }, // Bosnia-Herzegovina Convertible Mark
  { code: 'BBD', locale: 'en-BB', direction: 'LTR', name: 'English (Barbados)' }, // Barbadian Dollar
  { code: 'BDT', locale: 'bn-BD', direction: 'LTR', name: 'বাংলা (বাংলাদেশ)' }, // Bangladeshi Taka
  { code: 'BGN', locale: 'bg-BG', direction: 'LTR', name: 'Български (България)' }, // Bulgarian Lev
  { code: 'BHD', locale: 'ar-BH', direction: 'RTL', name: 'العربية (البحرين)' }, // Bahraini Dinar
  { code: 'BIF', locale: 'fr-BI', direction: 'LTR', name: 'Français (Burundi)' }, // Burundian Franc
  { code: 'BMD', locale: 'en-BM', direction: 'LTR', name: 'English (Bermuda)' }, // Bermudian Dollar
  { code: 'BND', locale: 'ms-BN', direction: 'LTR', name: 'Bahasa Melayu (Brunei)' }, // Brunei Dollar
  { code: 'BOB', locale: 'es-BO', direction: 'LTR', name: 'Español (Bolivia)' }, // Bolivian Boliviano
  { code: 'BRL', locale: 'pt-BR', direction: 'LTR', name: 'Português (Brasil)' }, // Brazilian Real
  { code: 'BSD', locale: 'en-BS', direction: 'LTR', name: 'English (Bahamas)' }, // Bahamian Dollar
  { code: 'BTN', locale: 'dz-BT', direction: 'LTR', name: 'རྫོང་ཁ (འབྲུག)' }, // Bhutanese Ngultrum
  { code: 'BWP', locale: 'en-BW', direction: 'LTR', name: 'English (Botswana)' }, // Botswana Pula
  { code: 'BYN', locale: 'be-BY', direction: 'LTR', name: 'Беларуская (Беларусь)' }, // Belarusian Ruble
  { code: 'BZD', locale: 'en-BZ', direction: 'LTR', name: 'English (Belize)' }, // Belize Dollar
  { code: 'CAD', locale: 'en-CA', direction: 'LTR', name: 'English (Canada)' }, // Canadian Dollar
  { code: 'CDF', locale: 'fr-CD', direction: 'LTR', name: 'Français (Congo-Kinshasa)' }, // Congolese Franc
  { code: 'CHF', locale: 'de-CH', direction: 'LTR', name: 'Deutsch (Schweiz)' }, // Swiss Franc
  { code: 'CLP', locale: 'es-CL', direction: 'LTR', name: 'Español (Chile)' }, // Chilean Peso
  { code: 'CNY', locale: 'zh-CN', direction: 'LTR', name: '中文 (中国)' }, // Chinese Yuan Renminbi
  { code: 'COP', locale: 'es-CO', direction: 'LTR', name: 'Español (Colombia)' }, // Colombian Peso
  { code: 'CRC', locale: 'es-CR', direction: 'LTR', name: 'Español (Costa Rica)' }, // Costa Rican Colon
  { code: 'CUP', locale: 'es-CU', direction: 'LTR', name: 'Español (Cuba)' }, // Cuban Peso
  { code: 'CVE', locale: 'pt-CV', direction: 'LTR', name: 'Português (Cabo Verde)' }, // Cape Verdean Escudo
  { code: 'CZK', locale: 'cs-CZ', direction: 'LTR', name: 'Čeština (Česko)' }, // Czech Koruna
  { code: 'DJF', locale: 'fr-DJ', direction: 'LTR', name: 'Français (Djibouti)' }, // Djiboutian Franc
  { code: 'DKK', locale: 'da-DK', direction: 'LTR', name: 'Dansk (Danmark)' }, // Danish Krone
  { code: 'DOP', locale: 'es-DO', direction: 'LTR', name: 'Español (República Dominicana)' }, // Dominican Peso
  { code: 'DZD', locale: 'ar-DZ', direction: 'RTL', name: 'العربية (الجزائر)' }, // Algerian Dinar
  { code: 'EGP', locale: 'ar-EG', direction: 'RTL', name: 'العربية (مصر)' }, // Egyptian Pound
  { code: 'ERN', locale: 'aa-ER', direction: 'LTR', name: 'ትግርኛ (ኤርትራ)' }, // Eritrean Nakfa
  { code: 'ETB', locale: 'am-ET', direction: 'LTR', name: 'አማርኛ (ኢትዮጵያ)' }, // Ethiopian Birr
  { code: 'EUR', locale: 'en-EU', direction: 'LTR', name: 'English (European Union)' }, // Euro
  { code: 'FJD', locale: 'en-FJ', direction: 'LTR', name: 'English (Fiji)' }, // Fijian Dollar
  { code: 'FKP', locale: 'en-FK', direction: 'LTR', name: 'English (Falkland Islands)' }, // Falkland Islands Pound
  { code: 'FOK', locale: 'en-FO', direction: 'LTR', name: 'English (Faroe Islands)' }, // Faroese Króna
  { code: 'GBP', locale: 'en-GB', direction: 'LTR', name: 'English (United Kingdom)' }, // British Pound Sterling
  { code: 'GEL', locale: 'ka-GE', direction: 'LTR', name: 'ქართული (საქართველო)' }, // Georgian Lari
  { code: 'GGP', locale: 'en-GG', direction: 'LTR', name: 'English (Guernsey)' }, // Guernsey Pound
  { code: 'GHS', locale: 'ak-GH', direction: 'LTR', name: 'Akan (Ghana)' }, // Ghanaian Cedi
  { code: 'GIP', locale: 'en-GI', direction: 'LTR', name: 'English (Gibraltar)' }, // Gibraltar Pound
  { code: 'GMD', locale: 'en-GM', direction: 'LTR', name: 'English (Gambia)' }, // Gambian Dalasi
  { code: 'GNF', locale: 'fr-GN', direction: 'LTR', name: 'Français (Guinée)' }, // Guinean Franc
  { code: 'GTQ', locale: 'es-GT', direction: 'LTR', name: 'Español (Guatemala)' }, // Guatemalan Quetzal
  { code: 'GYD', locale: 'en-GY', direction: 'LTR', name: 'English (Guyana)' }, // Guyanese Dollar
  { code: 'HKD', locale: 'zh-HK', direction: 'LTR', name: '中文 (香港)' }, // Hong Kong Dollar
  { code: 'HNL', locale: 'es-HN', direction: 'LTR', name: 'Español (Honduras)' }, // Honduran Lempira
  { code: 'HRK', locale: 'hr-HR', direction: 'LTR', name: 'Hrvatski (Hrvatska)' }, // Croatian Kuna
  { code: 'HTG', locale: 'ht-HT', direction: 'LTR', name: 'Kreyòl Ayisyen (Ayiti)' }, // Haitian Gourde
  { code: 'HUF', locale: 'hu-HU', direction: 'LTR', name: 'Magyar (Magyarország)' }, // Hungarian Forint
  { code: 'IDR', locale: 'id-ID', direction: 'LTR', name: 'Bahasa Indonesia (Indonesia)' }, // Indonesian Rupiah
  { code: 'ILS', locale: 'he-IL', direction: 'RTL', name: 'עברית (ישראל)' }, // Israeli New Shekel
  { code: 'IMP', locale: 'en-IM', direction: 'LTR', name: 'English (Isle of Man)' }, // Isle of Man Pound
  { code: 'INR', locale: 'hi-IN', direction: 'LTR', name: 'हिन्दी (भारत)' }, // Indian Rupee
  { code: 'IQD', locale: 'ar-IQ', direction: 'RTL', name: 'العربية (العراق)' }, // Iraqi Dinar
  { code: 'IRR', locale: 'fa-IR', direction: 'RTL', name: 'فارسی (ایران)' }, // Iranian Rial
  { code: 'ISK', locale: 'is-IS', direction: 'LTR', name: 'Íslenska (Ísland)' }, // Icelandic Króna
  { code: 'JEP', locale: 'en-JE', direction: 'LTR', name: 'English (Jersey)' }, // Jersey Pound
  { code: 'JMD', locale: 'en-JM', direction: 'LTR', name: 'English (Jamaica)' }, // Jamaican Dollar
  { code: 'JOD', locale: 'ar-JO', direction: 'RTL', name: 'العربية (الأردن)' }, // Jordanian Dinar
  { code: 'JPY', locale: 'ja-JP', direction: 'LTR', name: '日本語 (日本)' }, // Japanese Yen
  { code: 'KES', locale: 'sw-KE', direction: 'LTR', name: 'Kiswahili (Kenya)' }, // Kenyan Shilling
  { code: 'KGS', locale: 'ky-KG', direction: 'LTR', name: 'Кыргызча (Кыргызстан)' }, // Kyrgyzstani Som
  { code: 'KHR', locale: 'km-KH', direction: 'LTR', name: 'ភាសាខ្មែរ (កម្ពុជា)' }, // Cambodian Riel
  { code: 'KID', locale: 'en-KI', direction: 'LTR', name: 'English (Kiribati)' }, // Kiribati Dollar
  { code: 'KMF', locale: 'fr-KM', direction: 'LTR', name: 'Français (Comores)' }, // Comorian Franc
  { code: 'KPW', locale: 'ko-KP', direction: 'LTR', name: '한국어 (조선민주주의인민공화국)' }, // North Korean Won
  { code: 'KRW', locale: 'ko-KR', direction: 'LTR', name: '한국어 (대한민국)' }, // South Korean Won
  { code: 'KWD', locale: 'ar-KW', direction: 'RTL', name: 'العربية (الكويت)' }, // Kuwaiti Dinar
  { code: 'KYD', locale: 'en-KY', direction: 'LTR', name: 'English (Cayman Islands)' }, // Cayman Islands Dollar
  { code: 'KZT', locale: 'kk-KZ', direction: 'LTR', name: 'Қазақ (Қазақстан)' }, // Kazakhstani Tenge
  { code: 'LAK', locale: 'lo-LA', direction: 'LTR', name: 'ລາວ ລາວ' }, // Lao Kip
  { code: 'LBP', locale: 'ar-LB', direction: 'RTL', name: 'العربية لبنان' }, // Lebanese Pound
  { code: 'LKR', locale: 'si-LK', direction: 'LTR', name: 'සිංහල ශ්‍රී ලංකාව' }, // Sri Lankan Rupee
  { code: 'LRD', locale: 'en-LR', direction: 'LTR', name: 'English Liberia' }, // Liberian Dollar
  { code: 'LSL', locale: 'en-LS', direction: 'LTR', name: 'English Lesotho' }, // Lesotho Loti
  { code: 'LYD', locale: 'ar-LY', direction: 'RTL', name: 'العربية ليبيا' }, // Libyan Dinar
  { code: 'MAD', locale: 'ar-MA', direction: 'RTL', name: 'العربية المغرب' }, // Moroccan Dirham
  { code: 'MDL', locale: 'ro-MD', direction: 'LTR', name: 'Română Moldova' }, // Moldovan Leu
  { code: 'MGA', locale: 'fr-MG', direction: 'LTR', name: 'Français Madagascar' }, // Malagasy Ariary
  { code: 'MKD', locale: 'mk-MK', direction: 'LTR', name: 'Македонски Македонија' }, // Macedonian Denar
  { code: 'MMK', locale: 'my-MM', direction: 'LTR', name: 'မြန်မာ မြန်မာ' }, // Myanmar Kyat
  { code: 'MNT', locale: 'mn-MN', direction: 'LTR', name: 'Монгол Монгол' }, // Mongolian Tugrik
  { code: 'MOP', locale: 'zh-MO', direction: 'LTR', name: '中文 澳门' }, // Macanese Pataca
  { code: 'MRU', locale: 'ar-MR', direction: 'RTL', name: 'العربية موريتانيا' }, // Mauritanian Ouguiya
  { code: 'MUR', locale: 'en-MU', direction: 'LTR', name: 'English Mauritius' }, // Mauritian Rupee
  { code: 'MVR', locale: 'dv-MV', direction: 'RTL', name: 'ދިވެހިބަސް ދިވެހި' }, // Maldivian Rufiyaa
  { code: 'MWK', locale: 'ny-MW', direction: 'LTR', name: 'Chichewa Malawi' }, // Malawian Kwacha
  { code: 'MXN', locale: 'es-MX', direction: 'LTR', name: 'Español México' }, // Mexican Peso
  { code: 'MYR', locale: 'ms-MY', direction: 'LTR', name: 'Bahasa Malaysia Malaysia' }, // Malaysian Ringgit
  { code: 'MZN', locale: 'pt-MZ', direction: 'LTR', name: 'Português Moçambique' }, // Mozambican Metical
  { code: 'NAD', locale: 'en-NA', direction: 'LTR', name: 'English Namibia' }, // Namibian Dollar
  { code: 'NGN', locale: 'ig-NG', direction: 'LTR', name: 'Igbo Nigeria' }, // Nigerian Naira
  { code: 'NIO', locale: 'es-NI', direction: 'LTR', name: 'Español Nicaragua' }, // Nicaraguan Córdoba
  { code: 'NOK', locale: 'nb-NO', direction: 'LTR', name: 'Norsk Norge' }, // Norwegian Krone
  { code: 'NPR', locale: 'ne-NP', direction: 'LTR', name: 'नेपाली नेपाल' }, // Nepalese Rupee
  { code: 'NZD', locale: 'en-NZ', direction: 'LTR', name: 'English New Zealand' }, // New Zealand Dollar
  { code: 'OMR', locale: 'ar-OM', direction: 'RTL', name: 'العربية عمان' }, // Omani Rial
  { code: 'PAB', locale: 'es-PA', direction: 'LTR', name: 'Español Panamá' }, // Panamanian Balboa
  { code: 'PEN', locale: 'es-PE', direction: 'LTR', name: 'Español Perú' }, // Peruvian Nuevo Sol
  { code: 'PGK', locale: 'en-PG', direction: 'LTR', name: 'English Papua New Guinea' }, // Papua New Guinean Kina
  { code: 'PHP', locale: 'fil-PH', direction: 'LTR', name: 'Filipino Pilipinas' }, // Philippine Peso
  { code: 'PKR', locale: 'ur-PK', direction: 'RTL', name: 'اردو پاکستان' }, // Pakistani Rupee
  { code: 'PLN', locale: 'pl-PL', direction: 'LTR', name: 'Polski Polska' }, // Polish Zloty
  { code: 'PYG', locale: 'es-PY', direction: 'LTR', name: 'Español Paraguay' }, // Paraguayan Guarani
  { code: 'QAR', locale: 'ar-QA', direction: 'RTL', name: 'العربية قطر' }, // Qatari Riyal
  { code: 'RON', locale: 'ro-RO', direction: 'LTR', name: 'Română România' }, // Romanian Leu
  { code: 'RSD', locale: 'sr-Cyrl-RS', direction: 'LTR', name: 'Српски Србија' }, // Serbian Dinar
  { code: 'RUB', locale: 'ru-RU', direction: 'LTR', name: 'Русский Россия' }, // Russian Ruble
  { code: 'RWF', locale: 'rw-RW', direction: 'LTR', name: 'Kinyarwanda Rwanda' }, // Rwandan Franc
  { code: 'SAR', locale: 'ar-SA', direction: 'RTL', name: 'العربية السعودية' }, // Saudi Riyal
  { code: 'SBD', locale: 'en-SB', direction: 'LTR', name: 'English Solomon Islands' }, // Solomon Islands Dollar
  { code: 'SCR', locale: 'en-SC', direction: 'LTR', name: 'English Seychelles' }, // Seychellois Rupee
  { code: 'SDG', locale: 'ar-SD', direction: 'RTL', name: 'العربية السودان' }, // Sudanese Pound
  { code: 'SEK', locale: 'sv-SE', direction: 'LTR', name: 'Svenska Sverige' }, // Swedish Krona
  { code: 'SGD', locale: 'en-SG', direction: 'LTR', name: 'English Singapore' }, // Singapore Dollar
  { code: 'SHP', locale: 'en-SH', direction: 'LTR', name: 'English Saint Helena' }, // Saint Helena Pound
  { code: 'SLL', locale: 'en-SL', direction: 'LTR', name: 'English Sierra Leone' }, // Sierra Leonean Leone
  { code: 'SOS', locale: 'so-SO', direction: 'LTR', name: 'Af Soomaali Soomaaliya' }, // Somali Shilling
  { code: 'SRD', locale: 'nl-SR', direction: 'LTR', name: 'Nederlands Suriname' }, // Surinamese Dollar
  { code: 'SSP', locale: 'en-SS', direction: 'LTR', name: 'English South Sudan' }, // South Sudanese Pound
  { code: 'STN', locale: 'pt-ST', direction: 'LTR', name: 'Português São Tomé e Príncipe' }, // São Tomé and Príncipe Dobra
  { code: 'SVC', locale: 'es-SV', direction: 'LTR', name: 'Español El Salvador' }, // Salvadoran Colón
  { code: 'SYP', locale: 'ar-SY', direction: 'RTL', name: 'العربية سوريا' }, // Syrian Pound
  { code: 'SZL', locale: 'en-SZ', direction: 'LTR', name: 'English Eswatini' }, // Swazi Lilangeni
  { code: 'THB', locale: 'th-TH', direction: 'LTR', name: 'ภาษาไทย ประเทศไทย' }, // Thai Baht
  { code: 'TJS', locale: 'tg-TJ', direction: 'LTR', name: 'Тоҷикӣ Тоҷикистон' }, // Tajikistani Somoni
  { code: 'TMT', locale: 'tk-TM', direction: 'LTR', name: 'Türkmençe Türkmenistan' }, // Turkmenistani Manat
  { code: 'TND', locale: 'ar-TN', direction: 'RTL', name: 'العربية تونس' }, // Tunisian Dinar
  { code: 'TOP', locale: 'to-TO', direction: 'LTR', name: 'Lea faka-Tonga Tonga' }, // Tongan Pa'anga
  { code: 'TRY', locale: 'tr-TR', direction: 'LTR', name: 'Türkçe Türkiye' }, // Turkish Lira
  { code: 'TTD', locale: 'en-TT', direction: 'LTR', name: 'English Trinidad and Tobago' }, // Trinidad and Tobago Dollar
  { code: 'TWD', locale: 'zh-TW', direction: 'LTR', name: '中文 台灣' }, // New Taiwan Dollar
  { code: 'TZS', locale: 'sw-TZ', direction: 'LTR', name: 'Kiswahili Tanzania' }, // Tanzanian Shilling
  { code: 'UAH', locale: 'uk-UA', direction: 'LTR', name: 'Українська Україна' }, // Ukrainian Hryvnia
  { code: 'UGX', locale: 'sw-UG', direction: 'LTR', name: 'Kiswahili Uganda' }, // Ugandan Shilling
  { code: 'USD', locale: 'en-US', direction: 'LTR', name: 'English United States' }, // United States Dollar
  { code: 'UYU', locale: 'es-UY', direction: 'LTR', name: 'Español Uruguay' }, // Uruguayan Peso
  { code: 'UZS', locale: 'uz-UZ', direction: 'LTR', name: 'O‘zbekcha O‘zbekiston' }, // Uzbekistan Som
  { code: 'VES', locale: 'es-VE', direction: 'LTR', name: 'Español Venezuela' }, // Venezuelan Bolívar Soberano
  { code: 'VND', locale: 'vi-VN', direction: 'LTR', name: 'Tiếng Việt Việt Nam' }, // Vietnamese Dong
  { code: 'VUV', locale: 'bi-VU', direction: 'LTR', name: 'Bislama Vanuatu' }, // Vanuatu Vatu
  { code: 'WST', locale: 'en-WS', direction: 'LTR', name: 'English Samoa' }, // Samoan Tala
  { code: 'XAF', locale: 'fr-XAF', direction: 'LTR', name: 'Français Afrique Centrale' }, // Central African CFA Franc
  { code: 'XCD', locale: 'en-XC', direction: 'LTR', name: 'English East Caribbean' }, // East Caribbean Dollar
  { code: 'XOF', locale: 'fr-XOF', direction: 'LTR', name: 'Français Afrique de l’Ouest' }, // West African CFA Franc
  { code: 'XPF', locale: 'fr-XPF', direction: 'LTR', name: 'Français CFP' }, // CFP Franc
  { code: 'YER', locale: 'ar-YE', direction: 'RTL', name: 'العربية اليمن' }, // Yemeni Rial
  { code: 'ZAR', locale: 'en-ZA', direction: 'LTR', name: 'English South Africa' }, // South African Rand
  { code: 'ZMW', locale: 'en-ZM', direction: 'LTR', name: 'English Zambia' }, // Zambian Kwacha
  { code: 'ZWL', locale: 'en-ZW', direction: 'LTR', name: 'English Zimbabwe' }, // Zimbabwean Dollar
  // Add more locales as needed...
];
