import { Type } from '@angular/core';
import { FieldDto, FilterItem, SearchFilter } from '../model';
import { PageableOptions } from '../model/frontend/pageable-options';
import { IBaseInput } from './BaseInput';
import { DynamicComponentBase } from './DynamicComponentBase';
import { DataTypeEnum, TargetTypeEnum } from './view-enums';

export interface IColumn {
  name: string;
  key: string;
  dataType: DataTypeEnum;
  translationKeyPrefix?: string;
  filter?: IFilter;
  frozen?: boolean;
  alignFrozen?: 'left' | 'right';
  options?: any;
  sortDisabled?: boolean;
  passRowAsData?: boolean;
  permissions?: string | string[];
}
export class IFilter {
  type:
    | 'text'
    | 'date'
    | 'numeric'
    | 'boolean'
    | 'dropdown'
    | 'multiDropdown'
    | 'slider'
    | 'enum'
    | 'recordCode'
    | 'userSelector'
    | 'userAndGroupSelector'
    | 'tagsSelector'
    | 'color'
    | 'relation';
  matchMode:
    | 'startsWith'
    | 'contains'
    | 'notContains'
    | 'endsWith'
    | 'equals'
    | 'notEquals'
    | 'dateIs'
    | 'dateIsNot'
    | 'dateBefore'
    | 'dateAfter'
    | 'dateIsBefore'
    | 'dateIsAfter'
    | 'in'
    | 'between'
    | 'equals'
    | 'notEquals'
    | 'lt'
    | 'lte'
    | 'gt'
    | 'gte';
  display?: string = 'menu';
  showMatchModes?: boolean;
  showOperator?: boolean;
  showAddButton?: boolean;
  showClearButton?: boolean;
  showApplyButton?: boolean;
  placeholder?: string;
  options?: any;
  enumClass?: any;
  recordCodeType?: TargetTypeEnum;
  dynamicInput?: IDynamicComponent;
  extraKey?: string;
}
export enum MatchModes {
  startsWith = 'startsWith',
  contains = 'contains',
  notContains = 'notContains',
  endsWith = 'endsWith',
  equals = 'equals',
  notEquals = 'notEquals',
  dateIs = 'dateIs',
  dateIsNot = 'dateIsNot',
  dateIsBefore = 'dateIsBefore',
  dateIsAfter = 'dateIsAfter',
  in = 'in',
  between = 'between',
}
export class ITablePageable {
  pagination: PageableOptions = new PageableOptions();
  totalElements: number = 0;
}
export class ITableLoadEvent {
  pageInfo: ITablePageable = new ITablePageable();
  query?: any = {};
  filters?: FilterItem[] = [];
  tableOriginalFilters?: { [x: string]: any } = {};
  showLoader?: boolean = true;
  textSearch?: SearchFilter;
}
export class IDynamicComponent {
  inputType?: FieldDto.TypeEnum;
  componentType?: Type<DynamicComponentBase>;
  options: IBaseInput;
  viewModeOnly?: boolean;
  styleClass?: string; //Not used yet
}

export class OfflineConfig {
  lazy: boolean = true;
  paginator: boolean = true;
  showActionBar: boolean = true;
}
