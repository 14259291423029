import { Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { IssueType } from '@shared/classes/model/backend/issue';
import { IssuesMappingService } from '@shared/services/mappings/issue/issues-mapping.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import {
  ContentViewDto,
  ItemBasePage,
  makePlural,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  RequestHandlerOptions,
  routeToLocaleCase,
  TargetTypeEnum,
  toCamelCase,
  toKebabCase,
} from 'app/shared/classes';
import { combineLatest, takeUntil } from 'rxjs';
import { IssueTypeDataService } from '../../services/data/issue-type-data.service';

@Component({
  selector: 'app-issue-types-item',
  templateUrl: './issue-types-item.component.html',
  styleUrls: ['./issue-types-item.component.scss'],
})
export class IssueTypesItemComponent extends ItemBasePage<IssueType> {
  realTimeFormData: any = {}; //Copy of form data that changes realtime when form changes

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: false,
  };

  constructor(
    private requestService: IssueTypeDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    public localeService: LocaleService,
    private issueMappingService: IssuesMappingService
  ) {
    super(
      {
        moduleKeyword: ModuleKeywords.IssueType,
        routePrefix: ModuleRoutePrefix.IssueManagement,
      },
      router,
      requestService,
      toastService
    );

    combineLatest([this.activatedRoute.paramMap])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.itemId = data[0].get('id');
        this.editMode = !!this.itemId;
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(this.ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
                ),
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
                ),
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId
                  ? this.itemId
                  : this.localeService.translate('general.actions.add') +
                    ' ' +
                    this.localeService.translate(
                      `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
                    ),
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.IssueType,
        });
      });
    this.setRemediationActionsTableCardObj();
  }
  remediationActionTableCardContent: ContentViewDto;
  setRemediationActionsTableCardObj() {
    const tableModule: ModuleKeywords = ModuleKeywords.Issue;
    this.remediationActionTableCardContent = {
      // ...this.cardContent?.contentViewObject,
      name: this.localeService.translate(
        `modules.${routeToLocaleCase(ModuleKeywordRootPath[tableModule])}.${toCamelCase(tableModule)}.${makePlural(toCamelCase(tableModule))}`
      ),
      targetType: tableModule as any,
      tableViewConfig: {
        // ...this.cardContent?.contentViewObject?.drillDownTableViewConfig,
        columns: [...this.issueMappingService.tableFields],
        showActions: false, //change to show actions
        showGlobalFilter: true,
        showOperationActions: false,
        showDeleteAction: true,
        showEditAction: true,
        showLockAction: true,
        showActivateAction: true,
        showDeactivateAction: true,
        showStatusActions: true,
        showSettingsLink: false,
        showFileExportAction: false,
        showPackageExportAction: false,
        showBulkOperationAction: true,
      },
      filterView: {
        // ...this.cardContent?.contentViewObject?.filterView,
        targetType: tableModule as any,
        filter: {
          // ...this.cardContent?.contentViewObject?.filterView.filter,
          filters: [{ property: 'issueTypeCode', operation: 'EQUAL', value: this.itemId }],
        },
      },
    };
  }
}
