import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, FrameworkExceptionDto, IFieldDefinition, getEnumOptions } from '@shared/classes';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DateInputComponent } from '@shared/components/ui/input-fields/date-input/date-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class FrameworkExceptionsMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Title',
      key: 'title',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Title',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Title',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Status of the exception',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: FrameworkExceptionDto.StatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(FrameworkExceptionDto.StatusEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Target',
      key: 'targetCode',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Target element of the exception',
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
    },
    {
      name: 'Type',
      key: 'type',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Type of the exception',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: FrameworkExceptionDto.TypeEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(FrameworkExceptionDto.TypeEnum),
              multi: false
            },
          },
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner of the exception',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: 'Owner',
          name: 'owner',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'duration From',
      key: 'durationFrom',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Start from this date for the exception',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'lte',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: '2022-11-22',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dateInput: {
              showIcon: true,
              showTime: false
            },
          },
        },
      },
    },
    {
      name: 'duration To',
      key: 'durationTo',
      dataType: DataTypeEnum.DateLong,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'End with this date for the exception',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'lte',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: '2022-11-22',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dateInput: {
              showIcon: true,
              showTime: false
            },
          },
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: true,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },

    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
