<ng-template
  appDynamicFormComponent
  [dynamicComponent]="viewForm"
  (formSubmit)="onSubmit($event)"
  (formSubmitSave)="onSubmit($event, 'PAGE')"
  [formData]="data"
  [showSaveAndClose]="false"
  [viewModeOnly]="viewModeOnly"
  [isOptionsCollapsed]="!fromCreate"
></ng-template>
