import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AcknowledgmentRequestDto, AppPermissions, DataTypeEnum, IFieldDefinition } from '@shared/classes';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { UserAndGroupTreeSelectorComponent } from '@shared/components/selectors/user-and-group-tree-selector/user-and-group-tree-selector.component';
import { AttachmentInputComponent } from '@shared/components/ui/input-fields/attachment-input/attachment-input.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { CheckBoxComponent } from '@shared/components/ui/input-fields/check-box/check-box.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';
import { AcknowledgementRequestProgressBarComponent } from '../../../../modules/acknowledgement-module/acknowledgement-request/acknowledgement-request-progress-bar/acknowledgement-request-progress-bar.component';
import { AcknowledgementRequestProgressFinishedComponent } from '../../../../modules/acknowledgement-module/acknowledgement-request/acknowledgement-request-progress-finished/acknowledgement-request-progress-bar.component';

@Injectable({
  providedIn: 'root',
})
export class AcknowledgmentRequestMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner group of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined,
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Approver',
      key: 'approver',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadResponsibility,
      description: 'Users who should approve',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined,
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Acknowledgers',
      key: 'acknowledgers',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      permissions: AppPermissions.ReadResponsibility,
      description: 'Users who will are required to acknowledge',
      tableDefinition: {
        sortDisabled: true,
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
          extraKey: 'name',
        },
      },
      formField: {
        componentType: UserAndGroupTreeSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Is Satisfied',
      key: 'isSatisfied',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the acknowledgment request satisfied?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
      formField: {
        componentType: CheckBoxComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Progress Status',
      key: 'progress',
      dataType: DataTypeEnum.DynamicComponent,
      required: false,
      showInTable: true,
      description: 'Status of the request progress',
      tableDefinition: {
        sortDisabled: true,
        options: {
          dynamicComponent: AcknowledgementRequestProgressBarComponent,
        },
        passRowAsData: true,
      },
      formField: {
        componentType: CheckBoxComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Acknowledgment Request Progress',
      key: 'ackReqProgress',
      dataType: DataTypeEnum.DynamicComponent,
      required: false,
      showInTable: true,
      description: 'Progress of the acknowledgement request',
      tableDefinition: {
        sortDisabled: true,
        options: {
          dynamicComponent: AcknowledgementRequestProgressFinishedComponent,
        },
        passRowAsData: true,
      },
    },
    {
      name: 'Status',
      key: 'status',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Status of the acknowledgement request',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AcknowledgmentRequestDto.StatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Require Signed Document',
      key: 'requireSignedDocument',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Record Requires Signed Document?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
      formField: {
        componentType: CheckBoxComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },
    {
      name: 'Document',
      key: 'documentUrl',
      required: true,
      showInTable: true,
      description: 'Document link',
      dataType: DataTypeEnum.Link,
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: AttachmentInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null),
        },
      },
    },

    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongText,
      required: false,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'contains',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true,
            },
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
