import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, isNullObj } from '@shared/classes';
import { UserDataService } from 'app/modules/users/services/data/user-data.service';
import { unionBy } from 'lodash-es';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
})
export class UserSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = true;
  users: { id: string; userName: string; email: string }[] = [];
  dataType: DataTypeEnum = DataTypeEnum.Text;
  @Input() appendTo: string = null;
  @Input() optionLabel: string = 'userName';
  @Input() optionValue: string = 'userName';
  @Input() dataKey: string = 'userName';
  @Input() listBoxMode: boolean = false;
  isLoading: boolean = false;
  constructor(private service: UserDataService) {
    super();
  }

  ngOnInit(): void {
    this.getOptions();
    this.onChanges.subscribe((res) => {
      this.users = this.formatItemList(this.users);
    });
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
  }
  getOptions(username = null) {
    this.isLoading = true;
    this.subs.sink = (
      isNullObj(username)
        ? this.service.getSelector({ showLoading: false, showMsg: false })
        : this.service.searchUser(username, { showLoading: false, showMsg: false })
    ).subscribe({
      next: (res) => {
        this.users = this.formatItemList(res);
      },
      complete: () => {
        this.isLoading = false;
      },
    });
  }
  getViewData() {
    let value = this.data || this.control.value;
    if (typeof value === 'string' || value instanceof String) {
      return value;
    } else if (Array.isArray(value)) {
      return value.map((x) => (x.userName ? x.userName : (x[this.optionValue] ?? x)));
    } else {
      return value;
    }
  }
  onFilter(event) {
    this.getOptions(event);
  }
  formatItemList(data) {
    let tempUsers = data;
    if (!isNullObj(this.fControl.value)) {
      if (this.multi) {
        if (this.optionValue != undefined) {
          tempUsers = unionBy(
            tempUsers,
            this.fControl.value.map((x) => {
              return { [this.optionValue]: x };
            }),
            this.optionValue
          );
        } else {
          tempUsers = unionBy(tempUsers, this.fControl.value, this.dataKey);
        }
      } else {
        if (this.optionValue != undefined) {
          tempUsers = unionBy(tempUsers, [{ [this.optionValue]: this.fControl.value }] as any[], this.optionValue);
        } else {
          tempUsers = unionBy(tempUsers, [this.fControl.value], this.dataKey);
        }
      }
    }
    return tempUsers;
  }
}
