import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';
import { isArray } from 'lodash-es';

@Component({
  selector: 'app-user-list-view',
  templateUrl: './user-list-view.component.html',
  styleUrls: ['./user-list-view.component.scss'],
})
export class UserListViewComponent extends BaseViewItem implements OnInit {
  @Input() size: 'large' | 'xlarge' = null;
  @Input() showName: boolean = true;
  @Input() grayColor: boolean = false;
  constructor() {
    super();
  }
  ngOnInit(): void {}

  get _value() {
    if (!this.data) return [];
    let parseVal = this.data;
    if (typeof this.data === 'string' || this.data instanceof String) parseVal = [this.data];
    else if (isArray(this.data)) {
      let hasNameArray = false;
      for (let index = 0; index < this.data?.length; index++) {
        const element = this.data?.[index];
        if (element.name) {
          hasNameArray = true;
          break;
        }
      }
      if (hasNameArray) {
        parseVal = this.data?.map((x) => x.name);
      }
    } else if (this.data.name) {
      parseVal = [this.data.name];
    }
    const unique = [...new Set(parseVal)];
    return unique;
  }
}
