import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class LicenseHandlerService {
  public licenseDialog: boolean = false;
  public currentLicenseDialogMessage: Message;
  private _currentLicenseData: LicenseInfo = {
    licenseStatus: LicenseStatus.VALID,
    licenseName: null,
    licenseExpiryDate: null,
  };
  get currentLicenseData() {
    return this._currentLicenseData;
  }
  set currentLicenseData(currentLicenseData: LicenseInfo) {
    this._currentLicenseData = currentLicenseData;

    this.onLicenseStatusChange(currentLicenseData);
  }
  messages: Message[] | undefined = [];
  constructor() {}
  onLicenseStatusChange(currentLicenseData: LicenseInfo) {
    const currentLicenseStatus = currentLicenseData.licenseStatus;
    switch (currentLicenseStatus) {
      case 'VALID':
        if (this.messages?.length > 0) {
          this.messages = [];
          // this.messages = [{ id:3, severity: 'warn', summary: '', detail: mapLicenseStatusToMessage('EXPIRES_SOON'), icon:'pi pi-exclamation-triangle',data:{...currentLicenseData} }];
        }
        // this.messages = [{ id:3, severity: 'warn', summary: '', detail: mapLicenseStatusToMessage('EXPIRES_SOON'), icon:'pi pi-exclamation-triangle',data:{...currentLicenseData} }];
        break;
      case 'EXPIRED':
        if (this.messages?.length > 0 && this.messages?.[0]?.id == 1 && this.messages?.[0]?.data.licenseExpiryDate) {
        } else {
          this.messages = [
            {
              id: 1,
              severity: 'error',
              summary: '',
              detail: mapLicenseStatusToMessage(currentLicenseStatus),
              icon: 'pi pi-times-circle',
              data: { ...currentLicenseData },
            },
          ];
        }
        break;
      case 'INVALID':
        if (this.messages?.length > 0 && this.messages?.[0]?.id == 2 && this.messages?.[0]?.data.licenseExpiryDate) {
        } else {
          this.messages = [
            {
              id: 2,
              severity: 'error',
              summary: '',
              detail: mapLicenseStatusToMessage(currentLicenseStatus),
              icon: 'pi pi-times-circle',
              data: { ...currentLicenseData },
            },
          ];
        }
        break;
      case 'EXPIRES_SOON':
        if (this.messages?.length > 0 && this.messages?.[0]?.id == 3 && this.messages?.[0]?.data.licenseExpiryDate) {
        } else {
          this.messages = [
            {
              id: 3,
              severity: 'warn',
              summary: '',
              detail: mapLicenseStatusToMessage(currentLicenseStatus),
              icon: 'pi pi-exclamation-triangle',
              data: { ...currentLicenseData },
            },
          ];
        }
        break;
      default:
        if (this.messages?.length > 0 && this.messages?.[0]?.id == 4 && this.messages?.[0]?.data.licenseExpiryDate) {
        } else {
          this.messages = [
            {
              id: 4,
              severity: 'warn',
              summary: '',
              detail: mapLicenseStatusToMessage(currentLicenseStatus),
              icon: 'pi pi-exclamation-triangle',
              data: { ...currentLicenseData },
            },
          ];
        }
        break;
    }
  }
  toggleLicenseDialog(message: any) {
    this.licenseDialog = !this.licenseDialog;
    this.currentLicenseDialogMessage = message;
  }
}
export function mapLicenseStatusToMessage(status: LicenseStatus | string) {
  let ret = '';
  switch (status) {
    case 'VALID':
      ret = '';
      break;
    case 'EXPIRED':
      ret = 'License has expired';
      break;
    case 'EXPIRES_SOON':
      ret = 'License will expire soon';
      break;
    case 'INVALID':
      ret = 'License is invalid';
      break;
    default:
      ret = 'License has issues';
      break;
  }
  return ret;
}
export enum LicenseStatus {
  'VALID' = 'VALID',
  'EXPIRED' = 'EXPIRED',
  'EXPIRES_SOON' = 'EXPIRES_SOON',
  'INVALID' = 'INVALID',
}
export interface LicenseInfo {
  licenseStatus: LicenseStatus;
  licenseName: string;
  licenseExpiryDate: Date; //Timestamp epoch Should be parsed
}
