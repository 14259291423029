import { Component, EventEmitter, Output } from "@angular/core";
import { FileInfo, FileRestrictions, SelectEvent } from "@progress/kendo-angular-upload";

export interface ImageInfo {
  src: string;
  width: number;
  height: number;
}

@Component({
  selector: "my-upload",
  template: `
    <kendo-fileselect
      (select)="onSelect($event)"
      (remove)="onRemove()"
      [multiple]="false"
       [restrictions]="myRestrictions"
    >
      <kendo-fileselect-messages [select]="'general.actions.selectImage'|translate :'Select Image'"> </kendo-fileselect-messages>
    </kendo-fileselect>
  `,
})
export class TextEditorUploadComponent {
  public uploadSaveUrl = "saveUrl"; // Has to represent an actual API endpoint.
  public uploadRemoveUrl = "removeUrl"; // Has to represent an actual API endpoint.
  myRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".png"],
  };
  @Output() public valueChange: EventEmitter<ImageInfo> =
    new EventEmitter<ImageInfo>();

  public onSelect(ev: SelectEvent): void {
    ev.files.forEach((file: FileInfo) => {
      if (file.rawFile) {
        const reader = new FileReader();

        reader.onloadend = () => {
          const img = new Image();

          img.src = <string>reader.result;
          img.onload = () => {
            this.valueChange.emit({
              src: img.src,
              height: img.height,
              width: img.width,
            });
          };
        };

        reader.readAsDataURL(file.rawFile);
      }
    });
  }

  public onRemove(): void {
    this.valueChange.emit(null);
  }
}
