import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuditCase } from '@shared/classes/model/backend/audit';
import { IFieldDefinition } from '@shared/classes/view/AppFieldDefinition';
import { DataTypeEnum } from '@shared/classes/view/view-enums';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DateInputComponent } from '@shared/components/ui/input-fields/date-input/date-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class AuditCaseMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Audit Case Status',
      key: 'auditCaseStatus',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Audit Case Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AuditCase.AuditCaseStatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Audit Program',
      key: 'auditProgram',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Program to this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['AUDIT_PROGRAM'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Audit Project',
      key: 'auditProject',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Project to this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['AUDIT_PROJECT'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: 'Owner',
          name: 'owner',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Respondent',
      key: 'respondent',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Respondent of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: 'Respondent',
          name: 'respondent',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Auditor',
      key: 'auditor',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Auditor of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: 'Auditor',
          name: 'auditor',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Auditee',
      key: 'auditee',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Auditee of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Audit Questionnaire',
      key: 'auditQuestionnaire',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Audit Questionnaire to this Record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['QUESTIONNAIRE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      }, formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Evidence Questionnaire',
      key: 'evidenceQuestionnaire',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Evidence Questionnaire of the record',
      tableDefinition: {
        filter: {
          type: 'multiDropdown',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: TargetCodeSelectorComponent,
            options: {
              label: '',
              name: '',
              inputOptions: {
                codeSelectorInput: {
                  targetTypes: ['QUESTIONNAIRE'],
                },
                dropDownInput: {
                  optionLabel: 'name',
                  optionValue: 'code',
                  multi: false,
                  items: undefined,
                },
              },
              control: new FormControl(null),
            },
          },
        },
      },
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Planned Start Date',
      key: 'plannedStartDate',
      required: false,
      showInTable: true,
      description: 'Planned Start Date of the record',
      dataType: DataTypeEnum.DateLong,
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Planned End Date',
      key: 'plannedEndDate',
      required: false,
      showInTable: true,
      description: 'Planned End Date of the record',
      dataType: DataTypeEnum.DateLong,
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Planned Duration',
      key: 'plannedDuration',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Planned Duration of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: NumberInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Actual Start Date',
      key: 'actualStartDate',
      required: false,
      showInTable: true,
      description: 'Actual Start Date of the record',
      dataType: DataTypeEnum.DateLong,
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Actual End Date',
      key: 'actualEndDate',
      required: false,
      showInTable: true,
      description: 'Actual End Date of the record',
      dataType: DataTypeEnum.DateLong,
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    // {
    //   name: 'Actual Duration',
    //   key: 'actualDuration',
    //   dataType: DataTypeEnum.Text,
    //   required: false,
    //   showInTable: true,
    //   description: 'Actual Duration of the record',
    //   tableDefinition: {
    //     filter: {
    //       type: 'text',
    //       display: 'menu',
    //       matchMode: 'startsWith',
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    //   formField: {
    //     componentType: NumberInputComponent,
    //     options: {
    //       label: '',
    //       name: '',
    //       control: new FormControl(null)
    //     },
    //   },
    // },
    // {
    //   name: 'Actual Time Resources',
    //   key: 'actualTimeResources',
    //   dataType: DataTypeEnum.Text,
    //   required: false,
    //   showInTable: true,
    //   description: 'Actual Time Resources of the record',
    //   tableDefinition: {
    //     filter: {
    //       type: 'text',
    //       display: 'menu',
    //       matchMode: 'startsWith',
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    //   formField: {
    //     componentType: NumberInputComponent,
    //     options: {
    //       label: '',
    //       name: '',
    //       control: new FormControl(null)
    //     },
    //   },
    // },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
