import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, DataTypeEnum, IAction, IDynamicComponent, RiskMethodologyImpactFactorOptionDto } from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { ViewModeService } from '@shared/services/view-mode.service';
import { RiskMethodologyImpactFactorOptionDataService } from 'app/modules/risk/services/data/risk-methodology-impact-factor-option-data.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, catchError, forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-impact-factor-options-form-popup',
  templateUrl: './impact-factor-options-form-popup.component.html',
  styleUrls: ['./impact-factor-options-form-popup.component.scss'],
})
export class ImpactFactorOptionsFormPopupComponent extends BaseForm<any> implements OnInit, AfterViewInit {
  private loadingAdd = new Subject<boolean>();
  loadingAdd$ = this.loadingAdd.asObservable();

  submitButtonAction: IAction = {
    id: 1,
    label: 'Ok',
    buttonType: 'button',
    command: this.onSubmitForm.bind(this),
    icon: 'pi pi-save',
    loading$: this.loadingAdd$,
    status$: this.formValid$,
  };

  impactValueOptions: { name: string; value: any }[] = [];
  optionsList: RiskMethodologyImpactFactorOptionDto[];

  optionsFields: IDynamicComponent[] = [];
  control;
  itemCode;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public viewModeService: ViewModeService,
    public requestService: RiskMethodologyImpactFactorOptionDataService
  ) {
    super(viewModeService, 'RISK_METHODOLOGY');
    this.impactValueOptions = this.config?.data?.impactValueOptions;
    this.control = this.config?.data?.control;
  }
  emitPaused = true;
  ngOnInit(): void {
    this.handleValueChanges(
      this.formGroup,
      'impactFactorItemsDto',
      this.optionsList,
      this.getDataKeyValueFormatFromPrev.bind(this),
      this.getDataKeyValueFormat.bind(this),
      {
        patchSingleUpdate: this.requestService.patchSingleUpdate.bind(this.requestService),
        create: this.requestService.create.bind(this.requestService),
        updateValues: this.updateOptions.bind(this),
        getUpdatedValues: () => this.optionsList,
      }
    );
    this.optionsFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Name',
          name: 'name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: DropDownInputComponent,
        options: {
          label: 'Impact Value',
          name: 'impactValueDto',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            dropDownInput: {
              multi: false,
              showClear: true,
              optionLabel: 'name',
              optionValue: undefined,
              items: this.impactValueOptions,
              showStatusBadge: false,
              appendTo: 'body',
            },
          },
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    ];
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setData(this.config.data);
    }, 100);
    this._setupSubscriptions();
  }
  onSubmitForm() {
    const list = [...this.control.value ?? []];
    list.forEach((element, index) => {
      if (element.code == this.itemCode) {
        list[index] = { ...element, ...this.getData() };
      }
    });
    this.control.patchValue(list);
    this.ref.close(null);
  }
  getData() {
    return {
      description: this.formGroup.value.description,
      impactFactorItems: this.formGroup.value.impactFactorItemsDto?.map(x => x.code),
    };
  }
  mapToArray(obj: { [key: string]: any }): { name: string; value: any }[] {
    if (typeof obj !== 'object' || obj === null) {
      return [];
    }
    if (Array.isArray(obj)) {
      return obj;
    }
    const arr = [];
    for (const [key, value] of Object.entries(obj)) {
      arr.push({ name: key, value: value });
    }
    return arr;
  }

  arrayToMap(arr: { name: string; value: any }[]): { [key: string]: any } {
    if (!Array.isArray(arr)) {
      return {};
    }
    const obj = {};
    for (const { name, value } of arr) {
      obj[name] = value;
    }
    return obj;
  }

  handleValueChanges(
    formGroup,
    controlName,
    oldValues,
    getDataKeyValueFormatFromPrev,
    getDataKeyValueFormat,
    serviceMethods
  ) {
    formGroup.controls[controlName].valueChanges.subscribe(async (newValues) => {
      if (this.emitPaused) return;
      const oldVals = serviceMethods.getUpdatedValues();
      console.log('values changed');
      if (newValues?.length) {
        const forkList = [];
        newValues.forEach(element => {
          element.impactValue = element.impactValueDto.code;
        })

        newValues.forEach((element) => {
          let existedVal = null;

          if (element.code && oldVals?.length) {
            existedVal = oldVals.find((y) => y.code === element.code);

            if (existedVal) {
              const req = getDataKeyValueFormatFromPrev(element, existedVal);
              if (req?.length) {
                forkList.push(
                  serviceMethods.patchSingleUpdate(
                    req,
                    element.code
                  )
                );
              }
            }
          } else if (!element.code) {
            forkList.push(
              serviceMethods.create(
                getDataKeyValueFormat({ ...element, code: undefined })
              )
            );
          }
        });

        if (forkList.length) {
          this.loadingAdd.next(true);
          forkJoin(
            forkList.map((obs) =>
              obs.pipe(
                catchError((err) => {
                  console.error('Error in processing request:', err);
                  return of(null); // Handle error and continue
                })
              )
            )
          ).subscribe({
            next: (responses) => {
              this.loadingAdd.next(false);

              if (responses?.length) {
                responses.forEach((data) => {
                  if (data) {
                    serviceMethods.updateValues(data.data);
                  } else {
                    formGroup.controls[controlName].patchValue(
                      serviceMethods.getUpdatedValues(),
                      { emitEvent: false }
                    );
                  }
                });
              }
            },
          });
        }
      }
    });
  }

  updateOptions(newVal) {
    const optionsListDraft = this.formGroup.controls.impactFactorItemsDto.value ?? [];
    const map = {}

    optionsListDraft.forEach(element => {
      if (element.code) {
        map[element.code] = element;
      }
    });
    if (newVal.code)
      map[newVal.code] = newVal;
    this.optionsList = map ? Object.values(map) : [];
    this.emitPaused = true;
    this.formGroup.controls.impactFactorItemsDto.patchValue(this.optionsList);
    setTimeout(() => { this.emitPaused = false; }, 100);
  }

  setData(data: any) {
    if (data.code) {
      this.itemCode = data.code;
    }
    this.formGroup.patchValue({ description: data.description, impactFactorItemsDto: data?.control?.value?.find(x => x.code == this.itemCode).impactFactorItemsDto });
    this.data = data;
    this.emitPaused = false;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      description: new FormControl(null),
      impactFactorItemsDto: new FormControl([])
      // questionType: new FormControl(null, Validators.required),
      // options: new FormControl(null),
    });

  }
}
