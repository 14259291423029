import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  AppPermissions,
  DataTypeEnum,
  IFieldDefinition,
  IssueDto,
  TargetTypeEnum,
  getEnumOptions,
} from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class IssuesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Parent',
      key: 'parent',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      description: 'Parent of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Issue,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['ISSUE'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              optionLabel: 'name',
              optionValue: 'code',
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Criticality',
      key: 'criticality',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Record Type',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: IssueDto.CriticalityEnum,
        },
      },
      useInBulk: true,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(IssueDto.CriticalityEnum),
              multi: false,
            },
          },
        },
      },
    },
    // {
    //   name: 'Relation Type',
    //   key: 'sourceType',
    //   dataType: DataTypeEnum.Badge,
    //   required: true,
    //   showInTable: true,
    //   description: 'Record relation source type',
    //   translationKeyPrefix: 'general.fields.targetType',
    //   tableDefinition: {
    //     filter: {
    //       type: 'enum',
    //       display: 'menu',
    //       matchMode: 'in',
    //       showMatchModes: false,
    //       showAddButton: false,
    //       showOperator: false,
    //       enumClass: TargetTypeEnum,
    //     },
    //   },
    //   formField: {
    //     componentType: DropDownInputComponent,
    //     options: {
    //       label: '',
    //       name: '',
    //       placeholder: 'Select Item',
    //       control: new FormControl(null),
    //       inputOptions: {
    //         dropDownInput: {
    //           optionLabel: 'label',
    //           optionValue: 'value',
    //           appendTo: 'body',
    //           items: getEnumOptions(TargetTypeEnum),
    //           multi: false,
    //         },
    //       },
    //     },
    //   },
    // },
    // {
    //   name: 'Source Code',
    //   key: 'sourceCode',
    //   dataType: DataTypeEnum.CodeLink,
    //   required: true,
    //   showInTable: true,
    //   description: 'Record linked code',
    //   tableDefinition: {
    //     filter: {
    //       type: 'text',
    //       display: 'menu',
    //       matchMode: 'startsWith',
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    //   formField: {
    //     componentType: TargetCodeSelectorComponent,
    //     options: {
    //       label: '',
    //       name: '',
    //       placeholder: 'Search Items',
    //       control: new FormControl(null),
    //       inputOptions: {
    //         codeSelectorInput: {
    //           targetTypes: [],
    //         },
    //         dropDownInput: {
    //           multi: false,
    //           items: null,
    //           customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
    //           optionLabel: 'name',
    //           optionValue: 'code',
    //         },
    //       },
    //       showLabelInViewMode: false,
    //     },
    //   },
    // },
    {
      name: 'Type',
      key: 'issueTypeCode',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: true,
      description: 'Linked issue type of the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.IssueType,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: [],
            },
            dropDownInput: {
              multi: false,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              optionLabel: 'name',
              optionValue: 'code',
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Owner',
      key: 'issueOwner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: false,
      showInTable: true,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined,
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Status',
      key: 'issueStatus',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: IssueDto.IssueStatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(IssueDto.IssueStatusEnum),
              multi: false,
            },
          },
        },
      },
    },
    {
      name: 'Assignee',
      key: 'assignee',
      dataType: DataTypeEnum.ResponsibilityListView,
      permissions: AppPermissions.ReadResponsibility,
      required: false,
      showInTable: true,
      description: 'Owner of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined,
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: true,
      showInTable: true,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true,
            },
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Cause Items',
      key: 'causeItems',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Record Cause Items',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: [],
            },
            dropDownInput: {
              multi: true,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              optionLabel: 'name',
              optionValue: 'code',
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Effect Items',
      key: 'effectItems',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Record Effect Items',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: [],
            },
            dropDownInput: {
              multi: true,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              optionLabel: 'name',
              optionValue: 'code',
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Cause Items Types',
      key: 'causeItemsTypes',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Record Cause Items Types',
      translationKeyPrefix: 'general.fields.targetType',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: TargetTypeEnum,
        },
        options: {
          multi: true,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(TargetTypeEnum),
              multi: false,
            },
          },
        },
      },
    },
    {
      name: 'Effect Items Types',
      key: 'effectItemsTypes',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      description: 'Record relation source type',
      translationKeyPrefix: 'general.fields.targetType',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: TargetTypeEnum,
        },
        options: {
          multi: true,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(TargetTypeEnum),
              multi: false,
            },
          },
        },
      },
    },
    {
      name: 'Estimated Resolution Date',
      key: 'estimatedResolutionDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Estimated Resolution Date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'actualResolutionDate',
      key: 'actualResolutionDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Actual Resolution Date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    {
      name: 'Creation To Resolution Days',
      key: 'creationToResolutionDays',
      dataType: DataTypeEnum.Number,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'The Creation To Resolution Days',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
    },
    {
      name: 'Resolution Delay Days',
      key: 'resolutionDelayDays',
      dataType: DataTypeEnum.Number,
      required: false,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'The Resolution Delay Days',
      tableDefinition: {
        filter: {
          type: 'numeric',
          display: 'menu',
          matchMode: 'lte',
        },
      },
    },
    {
      name: 'Last Escalation Date',
      key: 'lastEscalationDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Last Escalation Date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    },
    // // causeItems
    // // effectItems
    // // causeItemsTypes
    // // effectItemsTypes
    // // estimatedResolutionDate
    // // actualResolutionDate
    // // creationToResolutionDays
    // // resolutionDelayDays
    // lastEscalation
    // // lastEscalationDate
    // lastEscalationOrder
    // escalations
    // escalationsArchiveItems
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
