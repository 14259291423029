import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  AppPermissions,
  DataTypeEnum,
  IFieldDefinition,
  TargetTypeEnum
} from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DateInputComponent } from '@shared/components/ui/input-fields/date-input/date-input.component';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class CampaignMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Questionnaire',
      key: 'questionnaire',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Linked Questionnaire',
      permissions: AppPermissions.ReadQuestionnaire,
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Questionnaire,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['QUESTIONNAIRE'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              optionLabel: 'name',
              optionValue: 'code',
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    // {
    //   name: 'Respondent',
    //   key: 'respondent',
    //   dataType: DataTypeEnum.ResponsibilityListView,
    //   required: true,
    //   showInTable: true,
    //   // permissions: AppPermissions.ReadGroup,
    //   description: 'Owner group of the record',
    //   tableDefinition: {
    //     filter: {
    //       type: 'text',
    //       display: 'menu',
    //       matchMode: 'startsWith',
    //       showMatchModes: true,
    //       showAddButton: true,
    //       showOperator: true,
    //     },
    //   },
    //   useInBulk: true,
    //   formField: {
    //     componentType: UserAndGroupSelectorComponent,
    //     options: {
    //       label: '',
    //       name: '',
    //       placeholder: 'Search...',
    //       control: new FormControl(null),
    //       inputOptions: {
    //         extra: { principleMode: true },
    //         dropDownInput: {
    //           optionValue: 'undefined',
    //           multi: false,
    //           items: null,
    //           optionLabel: undefined,
    //         },
    //       },
    //       showLabelInViewMode: false,
    //     },
    //   },
    // },
    {
      name: 'Owner',
      key: 'owner',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Owner group of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined,
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Responders',
      key: 'responders',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: false,
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search...',
          control: new FormControl(null),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined,
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    // {
    //   name: 'Scoring Type',
    //   key: 'scoringType',
    //   dataType: DataTypeEnum.Badge,
    //   required: true,
    //   showInTable: false,
    //   useInBulk: true,
    //   formField: {
    //     componentType: DropDownInputComponent,
    //     options: {
    //       label: '',
    //       name: '',
    //       placeholder: 'Select Item',
    //       control: new FormControl(null),
    //       inputOptions: {
    //         dropDownInput: {
    //           optionLabel: 'label',
    //           optionValue: 'value',
    //           appendTo: 'body',
    //           items: getEnumOptions(CampaignDto.ScoringTypeEnum),
    //           multi: false,
    //         },
    //       },
    //     },
    //   },
    // },
    {
      name: 'Progress',
      key: 'progress',
      dataType: DataTypeEnum.GenericProgressBar,
      required: false,
      showInTable: true,
      useInBulk: false,
    },
    {
      name: 'Close Date',
      key: 'closeDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: false,
      description: 'Record is valid from',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      useInBulk: true,
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: '2022-11-22',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dateInput: {
              showIcon: true,
              showTime: false,
            },
          },
        },
      },
    },
    {
      name: 'Score',
      key: 'score',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      useInBulk: true,
      formField: {
        componentType: NumberInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: '',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: true,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true,
            },
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
