import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTypeEnum, DynamicComponentBase, IAction, RequestWithProgressModel, isNullObj } from '@shared/classes';
import { ToastService } from '@shared/services';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-attachment-input',
  templateUrl: './attachment-input.component.html',
  styleUrls: ['./attachment-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: AttachmentInputComponent }],
})
export class AttachmentInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Link;
  showCancelFile = false;
  cancelFileSelectionAction: IAction = {
    id: 2,
    label: 'Cancel',
    buttonType: 'button',
    command: this.clearFile.bind(this),
    icon: 'pi pi-times',
  };
  @Output() onFileUpload: EventEmitter<any> = new EventEmitter();
  @Input() externalCode: String | string = null;
  @Input() bucketId: string = 'root';
  @Input() mode: 'basic' | 'advanced' = 'basic';
  @Input() multiple: boolean = false;
  @Input() accept: string = null;
  @Input() maxFileSize: number = null;
  @Input() fileLimit: number = null;
  @Input() dragAndDropMode: boolean = true;
  @Input() useGlobalPasteEventListener: boolean = false;
  @Input() uploadLocation: 'CODE' | 'CUSTOM_FOLDER' = 'CUSTOM_FOLDER';
  @Input() acceptCustomLinks: boolean = false;
  @Input() staticUploadWidth: boolean = false;
  @Input() preivewMode: 'inline' | 'popup' = 'popup';

  uploadRequestList: { [x: string]: RequestWithProgressModel } = {};
  @Output() onUploadRequestListChanges: EventEmitter<{ [x: string]: RequestWithProgressModel }> = new EventEmitter();
  @Output() onFilesChange: EventEmitter<{ files?: any[] }> = new EventEmitter();

  @HostListener('window:paste', ['$event']) onPaste(event: ClipboardEvent) {
    if (this.useGlobalPasteEventListener) {
      if (event && event.clipboardData.files.length > 0) {
        this.onBasicUpload(event.clipboardData as any);
      }
    }
  }
  constructor(
    private fileService: FilesDataService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        if (this.uploadLocation == 'CODE' && params['id']) {
          this.bucketId = 'code/' + params['id'];
          this.externalCode = params['id'];
        }
      },
    });
  }
  setInputOptions() {
    this.bucketId = this.inputOptions?.attachmentInput?.bucketId ?? this.bucketId;
    this.mode = this.inputOptions?.attachmentInput?.mode ?? this.mode;
    // this.multiple = this.inputOptions?.attachmentInput?.multiple ?? this.multiple;
    this.multiple = (this.inputOptions?.attachmentInput?.maxFileSize ?? this.maxFileSize) ? true : false;
    this.accept = this.inputOptions?.attachmentInput?.accept ?? this.accept;
    this.maxFileSize = this.inputOptions?.attachmentInput?.maxFileSize ?? this.maxFileSize;
    this.fileLimit = this.inputOptions?.attachmentInput?.fileLimit ?? this.fileLimit;
    this.externalCode = this.inputOptions?.attachmentInput?.externalCode ?? this.externalCode;
    this.uploadLocation = this.inputOptions?.attachmentInput?.uploadLocation ?? this.uploadLocation;
    this.acceptCustomLinks = this.inputOptions?.attachmentInput?.acceptCustomLinks ?? this.acceptCustomLinks;
  }
  onUploadRequestListChange() {
    this.onUploadRequestListChanges.emit(this.uploadRequestList);
  }
  onCancelUpload(fileUUID: string) {
    this.uploadRequestList[fileUUID].requestSubscriber.unsubscribe();
    this.uploadRequestList[fileUUID].progress = 0;
    this.uploadRequestList[fileUUID].inProgress = false;
    delete this.uploadRequestList[fileUUID];
    this.onUploadRequestListChange();
  }
  onDeleteItem(event, index) { }
  onDeleteItemByDocumentCode(code) {
    if (this.multiple) {
      const currentValue: any[] = this.fControl.getRawValue();
      const idx = currentValue?.indexOf(code);
      if (idx != -1) {
        currentValue.splice(idx, 1);
        this.fControl.patchValue([...currentValue]);
      }
    } else {
      this.fControl.patchValue(null);
    }
  }
  onBasicUpload(event: { files?: any[] }) {
    if (!event?.files) return;
    this.onFilesChange.emit(event);
    for (const file of event?.files) {
      const fileUUID = uuidv4();
      this.uploadRequestList[fileUUID] = {
        progress: 0,
        inProgress: false,
        requestSubscriber: null,
        requestResult: { versionName: file.name },
      };
      this.uploadRequestList[fileUUID].inProgress = true;
      this.uploadRequestList[fileUUID].progress = 0;
      this.onUploadRequestListChange();
      this.uploadRequestList[fileUUID].requestSubscriber = this.fileService
        .uploadFileWithProgress(
          file,
          { description: file.name, versionName: file.name },
          this.externalCode ? this.bucketId : '',
          {
            params: this.externalCode
              ? {
                name: file.name,
              }
              : {
                idOrPath: this.uploadLocation == 'CODE' ? '/tmp' : this.bucketId,
                description: file.name,
                versionName: file.name,
                location: this.uploadLocation == 'CODE' ? 'PATH' : 'ID', //NOT SURE
              },
            showLoading: true,
            showMsg: false,
          }
        )
        .subscribe({
          next: (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.uploadRequestList[fileUUID].inProgress = true;
              this.uploadRequestList[fileUUID].progress = (event.loaded / event.total) * 100;
              this.onUploadRequestListChange();
            }
            if (event.type === HttpEventType.Response) {
              this.uploadRequestList[fileUUID].inProgress = false;
              this.uploadRequestList[fileUUID].progress = 0;

              this.uploadRequestList[fileUUID].requestResult = event?.body;
              this.onUploadRequestListChange();
              let calculatedPatchValue: any = this.multiple ? [] : null;
              const currentControlValue = this.control?.getRawValue();
              if (this.multiple) {
                if (
                  isNullObj(this.fileLimit) ||
                  this.fileLimit > (currentControlValue as any[])?.length ||
                  isNullObj(currentControlValue)
                ) {
                  calculatedPatchValue = isNullObj(currentControlValue)
                    ? [event.body.document]
                    : [...currentControlValue, event.body.document];
                } else {
                  //this shouldn't happen but better to handle it
                  calculatedPatchValue = [...currentControlValue, event.body.document];
                  calculatedPatchValue.shift();
                }
              } else {
                calculatedPatchValue = event.body.document;
              }
              this.onFileUpload.emit(
                calculatedPatchValue
                //    this.uploadLocation == 'CODE' ? event.body.document : `${environment.websiteUrl}/file-manager/documents/download/${event?.body?.fileId}`
              );
              this.control.patchValue(
                calculatedPatchValue
                // this.uploadLocation == 'CODE' ? event.body.document : `${environment.websiteUrl}/file-manager/documents/download/${event?.body?.fileId}`
              );
              if (!this.staticUploadWidth) {
                delete this.uploadRequestList[fileUUID];
              }
            }
          },
          error: (err) => {
            this.uploadRequestList[fileUUID] = undefined;
            this.onUploadRequestListChange();
            this.toastService.error('Upload Failed', 'File upload failed please try again');
          },
        });
      this.onUploadRequestListChange();
    }
    // for (let file of event.files) {

    //     this.fileService
    //         .uploadFile(
    //             file,
    //             { description: file.name, versionName: file.name },
    //             `${this.bucketId}${this.dragAndDropMode ? '?name=' + file.name : ''}`
    //         )
    //         .subscribe((res) => {
    //             this.onFileUpload.emit(`${environment.websiteUrl}/file-manager/documents/download/${res.fileId}`)
    //             this.control.patchValue(`${environment.websiteUrl}/file-manager/documents/download/${res.fileId}`)
    //         })
    // }
  }
  onSelectFile() {
    this.showCancelFile = true;
  }
  clearFile(fileBrowser) {
    fileBrowser?.clear();
    this.showCancelFile = false;
  }

  isDragOverTarget: boolean = false;
  dragLeave(event) {
    event.preventDefault();
    // document.getElementById("file-drop-zone").innerHTML = "The text is no longer OVER the droptarget.";
    // event.target.style.border = "4px dotted blue";
    this.isDragOverTarget = false;
  }
  dragOver(event) {
    event.preventDefault();
    // document.getElementById("file-drop-zone").innerHTML = "The text is OVER the droptarget.";
    // event.target.style.border = "4px dotted green";
    this.isDragOverTarget = true;
  }

  drop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.onBasicUpload(event.dataTransfer);
    this.isDragOverTarget = false;
    // const data = event.dataTransfer.getData("Text");
    // event.target.appendChild(document.getElementById(data));
    // document.getElementById("file-drop-zone").innerHTML = "The text was dropped.";
  }
  fileChangeEvent(event: any): void {
    this.onSelectFile();
    if (this.dragAndDropMode) {
      this.onBasicUpload(event?.target);
    }
  }
  get attachmentsFromFormControl() {
    const value = this.fControl.getRawValue();

    let id = [];
    if (Array.isArray(value)) {
      id = value.map((x) => {
        const splitData = (x as string)?.split('/');
        return splitData?.[splitData?.length - 1];
      });
    } else {
      const splitData = (value as string)?.split('/');
      id = [splitData?.[splitData?.length - 1]];
    }
    return id;
  }
}
