import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';
import { getProp } from '@shared/classes';

@Pipe({
  name: 'translateField',
})
export class TranslateFieldPipe implements PipeTransform {
  constructor(private localeService: LocaleService) {}
  transform(data: any, key: string, args?: any) {
    let labelField = key;
    if (this.localeService?.language?.langCode && data?.originalLanguage != this.localeService?.language?.langCode) {
      labelField = `translations.${this.localeService?.language?.langCode}.${key}`;
    }
    return getProp(data, labelField) || getProp(data, key);
  }
}
