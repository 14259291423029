<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-text-editor
        [name]="'description'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
        [height]="'120px'"
      ></app-text-editor>
    </div>
    <div class="col-12">
      <app-form-repeater
        [title]="'Options'"
        [viewMode]="fieldViewMode"
        [cols]="optionsFields"
        [control]="formGroup?.controls.impactFactorItemsDto"
        [addOrderFieldToOptions]="false"
      ></app-form-repeater>
    </div>
  </div>

  <div class="flex flex-row-reverse gap-2">
    @if (fieldViewMode != 'view') {
      <app-button [action]="submitButtonAction"></app-button>
    }
  </div>
</form>
