<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-6 border-right-2 border-300 pr-5">
      <div class="w-12">
        <app-basic-input
          [label]="'general.cards.language.originalLanguage.label' | translate: 'Original Language'"
          [placeholder]="'general.cards.language.originalLanguage.placeholder' | translate: 'Enter Original Language'"
          [control]="viewOnlyForm?.controls?.originalLanguage"
          [viewMode]="'edit'"
        ></app-basic-input>
      </div>
      @for (field of oldTextFields; track field; let i = $index) {
        <div class="w-12">
          <ng-template appDynamicComponent [mode]="'edit'" [dynamicComponentInfo]="field"></ng-template>
        </div>
      }
    </div>
    <div class="col-6 pl-5" [dir]="currentLangData?.direction">
      <div class="w-12">
        <app-language-selector
          [langFile]="newFieldsLangFile"
          [viewMode]="fieldViewMode"
          [control]="formGroup?.controls?.langCode"
        >
        </app-language-selector>
        <!-- <app-target-code-selector
          [label]="
            'modules.dataManagement.language.fields.langCode.label' | translate: 'Language Code' : newFieldsLangFile
          "
          [placeholder]="
            'modules.dataManagement.language.fields.langCode.placeholder'
              | translate: 'Enter Language Code' : this.newFieldsLangFile
          "
          [control]="formGroup?.controls?.langCode"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'langCode'"
          [targetTypes]="['LANGUAGE']"
          [customProjectionFields]="['code', 'name', 'recordStatus', 'langCode', 'direction']"
        >
        </app-target-code-selector> -->
      </div>
      @for (field of newTextFields; track field; let i = $index) {
        <div class="w-12">
          <ng-template appDynamicComponent [mode]="'create'" [dynamicComponentInfo]="field"></ng-template>
        </div>
      }
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      <app-button [action]="submitButtonAction"></app-button>
      <app-button [action]="cancelButtonAction"></app-button>
    </div>
  </div>
</form>
