/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseIssueEvent { 
    status?: number;
    data?: ApiResponseIssueEvent.DataEnum;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseIssueEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_ANALYSIS' | 'TO_REMEDIATION' | 'TO_REVIEW' | 'TO_CLOSED_RESOLVED' | 'TO_CLOSED_UNRESOLVED';
    export const DataEnum = {
        Draft: 'TO_DRAFT' as DataEnum,
        Analysis: 'TO_ANALYSIS' as DataEnum,
        Remediation: 'TO_REMEDIATION' as DataEnum,
        Review: 'TO_REVIEW' as DataEnum,
        ClosedResolved: 'TO_CLOSED_RESOLVED' as DataEnum,
        ClosedUnresolved: 'TO_CLOSED_UNRESOLVED' as DataEnum
    };
}


