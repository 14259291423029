<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.issueTypeCode.label' | translate: 'Type'"
        [placeholder]="moduleFieldString + '.issueTypeCode.placeholder' | translate: 'Search Issue Types'"
        [control]="formGroup?.controls?.issueTypeCode"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [customProjectionFields]="[
          'code',
          'recordStatus',
          'label',
          'name',
          'color',
          'icon',
          'options',
          'uuid',
          'resolutionDays',
          'lockResolutionDate',
        ]"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['ISSUE_TYPE']"
        (onChangesObject)="onIssueTypeChanges($event)"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.estimatedResolutionDate.label' | translate: 'Estimated Resolution Date'"
        [placeholder]="'2022-11-22'"
        [name]="'resolutionDate'"
        [showIcon]="false"
        [minDate]="minDate"
        [control]="formGroup?.controls?.estimatedResolutionDate"
        [viewMode]="lockResolutionDate ? 'view' : fieldViewMode"
      ></app-date-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.issueOwner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.issueOwner.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.issueOwner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.assignee.label' | translate: 'Assignee'"
        [placeholder]="moduleFieldString + '.assignee.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.assignee"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.criticality.label' | translate: 'Criticality'"
        [placeholder]="moduleFieldString + '.criticality.placeholder' | translate: 'Select Item'"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="listOfCriticality"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.criticality"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.parent.label' | translate: 'Parent'"
        [placeholder]="moduleFieldString + '.parent.placeholder' | translate: 'Search Issues'"
        [control]="formGroup?.controls?.parent"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [excludeCodes]="data?.code ? [data?.code] : []"
        [targetTypes]="['ISSUE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
    <p-divider class="w-full" align="left">
      <b>{{ moduleFieldString + '.causeItems.label' | translate: 'Cause Items' }}</b>
    </p-divider>
    <div class="col-12">
      <app-drop-down-input
        [label]="moduleFieldString + '.causeItemsTypes.label' | translate: 'Cause Items Types'"
        [placeholder]="moduleFieldString + '.causeItemsTypes.placeholder' | translate: 'Select Cause Items Types'"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="targetTypes"
        [control]="formGroup?.controls?.causeItemsTypes"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
        [multi]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.causeItems.label' | translate: 'Cause Items'"
        [placeholder]="moduleFieldString + '.causeItems.placeholder' | translate: 'Enter Cause Items'"
        [control]="formGroup?.controls?.causeItems"
        [viewMode]="fieldViewMode"
        [inlineMode]="false"
        [appendTo]="'body'"
        [multi]="true"
        [tableMode]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'name', 'recordStatus']"
        [targetTypes]="selectedTypedToInclude1"
      >
      </app-target-code-selector>
    </div>
    <p-divider class="w-full" align="left">
      <b>{{ moduleFieldString + '.effectItems.label' | translate: 'Effect Items' }}</b>
    </p-divider>
    <div class="col-12">
      <app-drop-down-input
        [label]="moduleFieldString + '.effectItemsTypes.label' | translate: 'Effect Item Types'"
        [placeholder]="moduleFieldString + '.effectItemsTypes.placeholder' | translate: 'Enter Effect Item Types'"
        [name]="'dropdown'"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        [items]="targetTypes"
        [control]="formGroup?.controls?.effectItemsTypes"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
        [multi]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.effectItems.label' | translate: 'Effect Items'"
        [placeholder]="moduleFieldString + '.effectItems.placeholder' | translate: 'Enter Effect Items'"
        [control]="formGroup?.controls?.effectItems"
        [viewMode]="fieldViewMode"
        [inlineMode]="false"
        [appendTo]="'body'"
        [multi]="true"
        [tableMode]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'name', 'recordStatus']"
        [targetTypes]="selectedTypedToInclude2"
      >
      </app-target-code-selector>
    </div>
  </div>
  <!-- Used to have special condition on cancel (showCancelButton) -->
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' || showCancelButton) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
