/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChangeStatusBody } from './changeStatusBody';


export interface BulkChangeStatusBodyControlEvent { 
    codes?: Array<string>;
    toStatus?: BulkChangeStatusBodyControlEvent.ToStatusEnum;
    changeStatusBody?: ChangeStatusBody;
}
export namespace BulkChangeStatusBodyControlEvent {
    export type ToStatusEnum = 'TO_ATTEST' | 'TO_MONITOR' | 'TO_RETIRED';
    export const ToStatusEnum = {
        Attest: 'TO_ATTEST' as ToStatusEnum,
        Monitor: 'TO_MONITOR' as ToStatusEnum,
        Retired: 'TO_RETIRED' as ToStatusEnum
    };
}


