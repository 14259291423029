import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseCampaignEvent, CampaignDto } from '@shared/classes';
import { BaseRequestControllerWithRuleService, DataService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class CampaignDataService extends BaseRequestControllerWithRuleService<
  CampaignDto,
  ApiResponseCampaignEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.questions, '/campaigns', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'description',
      'questionnaire',
      'owner',
      'respondent',
      'responders',
      'progress',
      'name'
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`question-management/campaign/list`);
  }
}
