<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <div class="field" [class.mb-1]="control?.dirty && this.control.errors">
    <span
      class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full algin-items-start"
      [class.p-float-label]="floatLabel"
      [class.p-input-icon-left]="leftIcon"
      [class.p-input-icon-right]="rightIcon"
    >
      <ng-container *ngIf="!floatLabel; then labelTemplate"></ng-container>
      <i *ngIf="leftIcon" [class]="' pi ' + leftIcon"></i>
      <i *ngIf="rightIcon" [class]="' pi ' + rightIcon"></i>
      @if (altMode) {
        <app-tag-search-view [data]="data || control?.value">
          <ng-template #lastElementTemplate>
            <app-button [action]="editButton"></app-button>
          </ng-template>
        </app-tag-search-view>
      } @else {
        <p-autoComplete
          #autoComplete
          placeholder="Search ..."
          class="btnAddQ w-full tag-search-autocomplete"
          styleClass="pl-0 pr-0"
          [placeholder]="placeholder"
          [(ngModel)]="items"
          (keydown.enter)="onKeyUpEvent($event)"
          [emptyMessage]="
            exist
              ? ('general.messages.tagExist'
                | translate: 'The entered tag already exists. Please select a different tag or create a new one')
              : ('general.messages.noTagFound' | translate: 'No tags found, Press Enter to add a new Tag...')
          "
          [ngModelOptions]="{ standalone: true }"
          [suggestions]="searchItems$ | async"
          (completeMethod)="search($event.query)"
          field="name"
          [delay]="0"
          [multiple]="true"
          [name]="name || label || placeholder"
        >
          <ng-template let-item pTemplate="item">
            <app-badge-item
              [customColor]="stringToColorFunc(item?.name)"
              [customIcon]="'hidden'"
              [text]="item?.name"
            ></app-badge-item>
          </ng-template>
          <ng-template let-item pTemplate="selectedItem">
            <app-badge-item
              [styleClass]="'border-noround-right'"
              [customColor]="stringToColorFunc(item?.name)"
              [customIcon]="'hidden'"
              [text]="item?.name"
            ></app-badge-item>
          </ng-template>
        </p-autoComplete>
        @if (addButton) {
          <app-button class="w-full" [action]="addTagButton"></app-button>
        }
      }
      <ng-container *ngIf="floatLabel; then labelTemplate"></ng-container>
      <ng-template #labelTemplate>
        <label
          [for]="name || label"
          class="md:w-12rem md:max-w-12rem md:min-w-12rem input-field-label"
          *ngIf="label && !instanceInFormRepeater"
          >{{ label }}<span *ngIf="isControlRequired" class="text-red-500"> *</span>
          @if (resetFieldCheckVisable) {
            <br />
            <app-reset-switch-input
              [checked]="!resetable"
              (onChange)="setFieldResetable($event)"
            ></app-reset-switch-input>
          }
        </label>
      </ng-template>
    </span>

    <ng-container *ngIf="control?.dirty">
      <small class="p-error p-invalid" *ngIf="control?.hasError('required')">{{ label }} {{ 'is Required' }}</small>
      <small class="p-error p-invalid" *ngIf="control?.hasError('taken')">{{ label }} {{ 'is Taken' }}</small>
      <small class="p-error p-invalid" *ngIf="control?.hasError('invalidUrl')">{{ label }} {{ 'is Invalid' }}</small>
      <small class="p-error p-invalid" *ngIf="control?.hasError('email')">Enter a Valid Email</small>
      <small class="p-error p-invalid" *ngIf="control?.hasError('pattern')">{{ label }} {{ patternError }}</small>
      <small class="p-error p-invalid" *ngIf="control?.hasError('minlength')">
        {{ label }} must have a minimum length of {{ control?.errors?.minlength?.requiredLength }}
      </small>
      <small class="p-error p-invalid" *ngIf="control?.hasError('maxlength')">
        {{ label }} must have a maximum length of {{ control?.errors?.maxlength?.requiredLength }}
      </small>
      <small class="p-error p-invalid" *ngIf="control?.hasError('min')">
        {{ label }} must be greater than or equal to {{ control?.errors?.min?.min }}
      </small>
      <small class="p-error p-invalid" *ngIf="control?.hasError('max')">
        {{ label }} must be less than or equal to {{ control?.errors?.max?.max }}
      </small>
      <small class="p-error p-invalid" *ngIf="customError && control?.invalid">{{ customErrorLabel }}</small>
    </ng-container>
    <small class="text-500 block">{{ helpText }}</small>
  </div>
</app-input-view-switch>
