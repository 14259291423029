import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ButtonColor, DataTypeEnum, DynamicComponentBase, FilterItem, IAction, ModuleKeywords, PermissionActions } from '@shared/classes';
import { stringToColor } from '@shared/classes/helpers/string-to-color-helper';
import { BaseFormPopupComponent } from '@shared/components/misc/base-form-popup/base-form-popup.component';
import { TagsEditPopupFormComponent } from '@shared/components/misc/tags-edit-popup-form/tags-edit-popup-form.component';
import { AppDialogService } from '@shared/services';
import { TagDataService } from 'app/modules/data-structure-module/services/data/tag-data.service';
import { TagItemFormComponent } from 'app/modules/data-structure-module/tag/tag-item-form/tag-item-form.component';
import { AutoComplete } from 'primeng/autocomplete';
import { Observable, Subject, debounceTime, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-tag-search-input',
  templateUrl: './tag-search-input.component.html',
  styleUrl: './tag-search-input.component.scss',
  providers: [{ provide: DynamicComponentBase, useExisting: TagSearchInputComponent }],
})
export class TagSearchInputComponent extends DynamicComponentBase implements OnInit, AfterViewInit {
  dataType: DataTypeEnum = DataTypeEnum.TagSearch;
  @ViewChild('autoComplete') autoComplete!: AutoComplete;
  @Input() altMode = false;
  @Input() addButton = false;

  @Output() onPopupSubmit: EventEmitter<any> = new EventEmitter();
  editButton: IAction = {
    id: 1,
    buttonType: 'button',
    buttonStyle: 'text',
    command: this.openEditPopup.bind(this),
    icon: 'pi pi-pencil',

  };
  addTagButton: IAction = {
    label: 'Add Tag',
    id: 1,
    color: ButtonColor.Primary,
    buttonClass: 'w-full',
    icon: 'pi pi-plus',
    iconPos: 'left',
    command: this.openPopup.bind(this),
    permission: `${PermissionActions.Create}${ModuleKeywords.Tag}`,
  };
  searched = false;
  emitEvent = true;
  exist = false;
  constructor(
    private requestService: TagDataService,
    private appDialogService: AppDialogService,
  ) {
    super();
  }

  @Input() set controls(value: { tagStringsControl, tagCodesControl, emitEvent?}) {
    this.control = this.control ? this.control : new FormControl();
    this.emitEvent = value?.emitEvent == undefined ? this.emitEvent : value?.emitEvent;
    this.tagStringsControl = !value?.tagStringsControl ? new FormControl([]) : (Array.isArray(value?.tagStringsControl) ? new FormControl([...value?.tagStringsControl]) : value?.tagStringsControl);
    this.tagCodesControl = value?.tagCodesControl;
    if (this.tagStringsControl?.value) {
      this.items = this.tagStringsControl.value.map((x, i) => {
        if (this.tagCodesControl?.value?.[i]) {
          return { name: x, code: this.tagCodesControl?.value?.[i] }
        }
        return { name: x, code: x };
      });
      // this.subs.sink = this.tagStringsControl.valueChanges.subscribe(x => {
      //   if(this.control)
      //   this.control?.patchValue(x);
      // })
    }
  }

  tagStringsControl;
  tagCodesControl

  private searchText$ = new Subject<string>();
  searchItems$: Observable<any[]>;

  _items;
  set items(value) {
    if (this.tagCodesControl) {
      this.tagCodesControl.patchValue(value?.map(x => x.code), { emitEvent: this.emitEvent });
    }
    if (this.tagStringsControl) {
      const valueList = value?.map(x => x.name);
      this.tagStringsControl.patchValue([...valueList], { emitEvent: this.emitEvent });
      this.data = [...valueList]
    }
    if (this.control) {
      this.control.patchValue(value?.map(x => x.name), { emitEvent: this.emitEvent });
    }
    if (!this.emitEvent) {
      this.emitEvent = true;
    }
    this._items = value;
  }
  get items() {
    return this._items;
  }

  // _searchItems;
  // set searchItems(value) {
  //   this._searchItems = value;
  // }
  // get searchItems() {
  //   return this._searchItems;
  // }
  // constructor() {
  //   super();
  // }

  onFilterInputChange() {
    this.searchText$
      .pipe(
        debounceTime(500),
        switchMap((keywords) => {
          return this.requestService.search(
            { page: 0, size: 40 },
            {
              projectionFields: ['name', 'code', 'id'],
              filters: this.getFilters(keywords)
            }
          );
        })
      )
      .subscribe((res) => {
        setTimeout(() => {
          this.searchItems$ = of(res?.content ?? []);
        }, 200)
      });
  }
  search(keywords: string) {
    this.searchText$.next(keywords);
  }
  focuseSearch(event): void {
    const inputElement = event.target as HTMLInputElement;
    const searchKeyword = inputElement.value;

    // Directly search without additional delay or flag checks
    if (!this.autoComplete.loading) {
      this.autoComplete.search(event, searchKeyword, this.autoComplete);
    }
  }
  ngOnInit(): void {
    // if (this.control) {
    //   this.control.valueChanges.subscribe(x => {
    //     this.items = x.map((y, i) => { return { code: y + i, name: y } });
    //   });
    // }
  }

  ngAfterViewInit(): void {
    this.onFilterInputChange();
    if (this.autoComplete) {
      this.autoComplete.searchLocale = false;
      const inputElement = this.autoComplete.el.nativeElement.querySelector('input');
      inputElement.addEventListener('click', (event) => this.focuseSearch(event));
    }
  }
  setInputOptions() { }

  getFilters(keywords): FilterItem[] {
    const filter: FilterItem[] = keywords
      ? [
        { property: 'name', operation: 'CONTAINS', value: keywords },
      ]
      : [];
    if (this.items?.length) {
      filter.push({
        property: 'name', operation: 'NOT_IN', value: this.items?.map(x => {
          this.exist = x.name == keywords;
          return x.name
        })
      });
    }
    return filter;
  }

  onKeyUpEvent(event) {
    const inputElement = event.target as HTMLInputElement;
    const searchKeyword = inputElement.value;

    // Check if there are no suggestions
    this.searchItems$.subscribe(suggestions => {
      if (suggestions.length === 0) {
        this.openPopup(searchKeyword);
      }
    });
  }
  openPopup(searchKeyword: string) {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      'Add Tag',
      (data) => {
        if (data) {
          this.subs.sink = this.requestService
            .create(data?.createItems ? data : { createItems: data })
            .subscribe((res) => {
              if (res) {
                this.items = [...this.items, { name: res?.data?.name, code: res?.data?.code }];
              }
            });
        }
      },
      {
        data: {
          dynamicViewComponent: TagItemFormComponent,
          dataService: this.requestService,
          filters: [],
          selectedRows: [],
          patchData: false,
          options: { fromPopup: true },
          formData: { name: searchKeyword },
        },
      }
    );
  }

  manualPatchData(
    value,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
      emitModelToViewChange?: boolean;
      emitViewToModelChange?: boolean;
    }
  ) {
    if (this.fControl) {
      this.fControl.patchValue(value, options);
    }
    this.items = value?.map((x, i) => { return { code: x + i, name: x } });
  }

  openEditPopup() {
    this.appDialogService.showDialog(
      TagsEditPopupFormComponent,
      'Edit Tags',
      (data) => {
        if (data) {
          this.onPopupSubmit.emit(data);
        }
      },
      {
        data: {
          tagsStringControl: new FormControl(this.tagStringsControl?.value ?? null),
          tagsControl: new FormControl(this.tagCodesControl?.value ?? null)
        },
      }
    );
  }

  stringToColorFunc(string) {
    return stringToColor(string)
  }
}
