import { Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { IViewMode } from '@shared/classes';
import { BaseEditableDynamicComponent } from '../models/base-editable-dynamic-component';

@Component({
  selector: 'app-list-strings',
  templateUrl: './list-strings.component.html',
  styleUrls: ['./list-strings.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListStringsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ListStringsComponent),
      multi: true,
    },
  ],
})
export class ListStringsComponent extends BaseEditableDynamicComponent implements OnInit, OnDestroy {
  ngControl: NgControl | null = null; // assign the current formControl to have access to all options ex: errors, dirty, hasValidator ...etc
  @Input() hideLabel: Boolean = false;
  @Input() viewMode?: IViewMode = IViewMode.edit;
  list: string[] = [];
  constructor(private readonly injector: Injector) {
    super();
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null, { optional: true });
  }

  addTaskToNote(task: string): void {
    if (task.trim() === '') return;

    this.list.push(task);

    this.value = [...this.list];
    this.onChange(this.value);
  }

  removeTaskFromNote(index: number): void {
    this.list = [...this.list];
    this.list.splice(index, 1);

    this.value = [...this.list];
    this.onChange(this.value);
  }

  updateTaskOnNote(value, index: any): void {
    this.list[index] = value;

    this.value = [...this.list];
    this.onChange(this.value);
  }

  trackByFn(index: number, item: any): any {
    return index;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.value = [];
      this.list = [];
    } else {
      this.value = [...obj];
      this.list = [...this.value];
    }
  }
  get isControlRequired() {
    return this.ngControl?.control?.hasValidator(Validators.required);
  }
  validate(control: AbstractControl): ValidationErrors {
    const errors: ValidationErrors = {};
    // if (control.hasValidator(Validators.required)) {
    //   this.isControlRequired = true;
    // } else {
    //   this.isControlRequired = false;
    // }
    if (control.hasError('required')) {
      errors.required = true;
    }
    if (control.hasValidator(Validators.required) && Validators.required(control)) {
      errors.required = true;
    }

    // const minLengthValidator = control.validator && control.validator(new FormControl());
    // const minLengthValue = (minLengthValidator && minLengthValidator.minLength) ? minLengthValidator.minLength : null;

    //if (!!minLengthValue && Validators.minLength(minLengthValue)(control) ){
    if (control?.hasError('minlength')) {
      if (control?.value?.length < control.getError('minlength').requiredLength) {
        errors.minLength = {
          requiredLength: control.getError('minlength').requiredLength,
          actualLength: this.value.length,
        };
      }
    }

    if (control?.hasError('maxlength')) {
      if (control?.value?.length > control.getError('maxlength').requiredLength) {
        errors.maxLength = {
          requiredLength: control.getError('maxlength').requiredLength,
          actualLength: this.value.length,
        };
      }
    }

    return Object.keys(errors).length ? errors : null;
  }

  onDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.list, event.previousIndex, event.currentIndex);
  }
}
