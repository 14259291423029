/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface JobRunBody { 
    sfJob?: JobRunBody.SfJobEnum;
}
export namespace JobRunBody {
    export type SfJobEnum = 'POLICY_JOB' | 'CONTROL_JOB' | 'DISABLE_EXCEPTION_JOB' | 'ENABLE_EXCEPTION_JOB' | 'GAP_REPORT_JOB_JOB' | 'COMPLIANCE_REPORT_JOB' | 'RE_ASSESSMENT_PROJECTS_JOB' | 'RUN_ESCALATION_JOB' | 'CLEAN_UP_DOCUMENT_VERSIONS_JOB' | 'CLOSE_CAMPAIGNS_JOB' | 'RUN_SCHEDULER_REPORTS_JOB' | 'EXECUTE_COLLECTORS_JOB';
    export const SfJobEnum = {
        PolicyJob: 'POLICY_JOB' as SfJobEnum,
        ControlJob: 'CONTROL_JOB' as SfJobEnum,
        DisableExceptionJob: 'DISABLE_EXCEPTION_JOB' as SfJobEnum,
        EnableExceptionJob: 'ENABLE_EXCEPTION_JOB' as SfJobEnum,
        GapReportJobJob: 'GAP_REPORT_JOB_JOB' as SfJobEnum,
        ComplianceReportJob: 'COMPLIANCE_REPORT_JOB' as SfJobEnum,
        ReAssessmentProjectsJob: 'RE_ASSESSMENT_PROJECTS_JOB' as SfJobEnum,
        RunEscalationJob: 'RUN_ESCALATION_JOB' as SfJobEnum,
        CleanUpDocumentVersionsJob: 'CLEAN_UP_DOCUMENT_VERSIONS_JOB' as SfJobEnum,
        CloseCampaignsJob: 'CLOSE_CAMPAIGNS_JOB' as SfJobEnum,
        RunSchedulerReportsJob: 'RUN_SCHEDULER_REPORTS_JOB' as SfJobEnum,
        ExecuteCollectorsJob: 'EXECUTE_COLLECTORS_JOB' as SfJobEnum
    };
}


