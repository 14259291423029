<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    @if (stepperService) {
      @switch (stepperService.operation) {
        @case (bulkOperations.Status) {
          <div class="col-12 mb-4">
            <app-drop-down-input
              [label]="'Status'"
              [placeholder]="'Select Status'"
              [name]="'multi-dropdown'"
              [optionLabel]="'label'"
              [multi]="false"
              [items]="listOfStatuses"
              [optionValue]="'value'"
              [control]="formGroup?.controls?.status"
              [badgeView]="true"
              [viewMode]="'create'"
            ></app-drop-down-input>
          </div>
        }
        @case (bulkOperations.Watchers) {
          <div class="col-12 mb-4">
            <div class="flex flex-column gap-3 w-full">
              <app-user-and-group-selector
                [label]="''"
                [placeholder]="'Users'"
                [control]="accessControl"
                [optionValue]="undefined"
                [multi]="true"
                [viewMode]="'create'"
                (onChanges)="setWatcherUserListActions($event)"
                [floatLabel]="false"
                [itemTypes]="['GROUPS', 'RESPONSIBILITIES', 'USERS']"
              >
              </app-user-and-group-selector>
              <div class="flex flex-column w-full align-content-start gap-1">
                @for (user of accessControl?.value; track user) {
                  <div class="flex flex-row w-full justify-content-between align-items-center">
                    <div class="flex flex-row align-items-center gap-3">
                      <app-avatar-list [users]="user?.name"></app-avatar-list>
                      <!-- <p>{{ user?.name }}</p> -->
                    </div>
                    <div class="w-6rem" style="min-width: 6rem">
                      <app-button-list
                        [actionList]="userActionsMap[user?.name]?.actions ?? []"
                        [dropdownActionLabel]="userActionsMap[user?.name]?.accessLevel | titlecase"
                        [dropdownActionColor]="'secondary'"
                        [dropdownActionStyleClass]="'w-full text-left'"
                        [listType]="'dropdown'"
                      ></app-button-list>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }
        @case (bulkOperations.Access) {
          <div class="col-12 mb-4">
            <div class="flex flex-column gap-3 w-full">
              <app-user-and-group-selector
                [label]="''"
                [placeholder]="'Users'"
                [control]="accessControl"
                [optionValue]="undefined"
                [multi]="true"
                [viewMode]="'create'"
                (onChanges)="setUserListActions($event)"
                [floatLabel]="false"
                [itemTypes]="['GROUPS', 'RESPONSIBILITIES', 'USERS']"
              >
              </app-user-and-group-selector>
              <div class="flex flex-column w-full align-content-start gap-1">
                @for (user of accessControl?.value; track user) {
                  <div class="flex flex-row w-full justify-content-between align-items-center">
                    <div class="flex flex-row align-items-center gap-3">
                      <app-avatar-list [users]="user?.name"></app-avatar-list>
                      <!-- <p>{{ user?.name }}</p> -->
                    </div>
                    <div class="w-6rem" style="min-width: 6rem">
                      <app-button-list
                        [actionList]="userActionsMap[user?.name]?.actions ?? []"
                        [dropdownActionLabel]="userActionsMap[user?.name]?.accessLevel | titlecase"
                        [dropdownActionColor]="'secondary'"
                        [dropdownActionStyleClass]="'w-full text-left'"
                        [listType]="'dropdown'"
                      ></app-button-list>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }
        @case (bulkOperations.PermenantDelete) {
          <div class="col-12 mb-4">
            <p>Permenant Deleting Items:</p>
            @for (item of stepperService.items; track item) {
              <div class="flex gap-2">
                <app-code-navigation [data]="item.code"></app-code-navigation>
              </div>
            }
          </div>
        }
        @case (bulkOperations.Restore) {
          <div class="col-12 mb-4">
            <p>Restorig Items:</p>
            @for (item of stepperService.items; track item) {
              <div class="flex gap-2">
                <app-code-navigation [data]="item.code"></app-code-navigation>
              </div>
            }
          </div>
        }
        @case (bulkOperations.Delete) {
          <div class="col-12 mb-4">
            <p>Deleting Items:</p>
            @for (item of stepperService.items; track item) {
              <div class="flex gap-2">
                <app-code-navigation [data]="item.code"></app-code-navigation>
              </div>
            }
          </div>
        }
        @case (bulkOperations.Update) {
          @for (field of fields; track field; let i = $index) {
            @if (field?.options?.name && field?.options?.control) {
              <ng-container
                *ngTemplateOutlet="
                  checkBoxTemplate;
                  context: {
                    $implicit: field.options?.name,
                    control: field.options.control,
                    checkControl: stepperService.checkControls[i],
                  }
                "
              ></ng-container>
            }
            <div class="col-11">
              <ng-template appDynamicComponent [mode]="'create'" [dynamicComponentInfo]="field"></ng-template>
            </div>
          }
        }
        @case (bulkOperations.AddRelations) {
          <div class="col-6">
            <div class="w-12">
              <app-target-code-selector
                [excludeCodes]="excludedCodes"
                [label]="'Relation Type'"
                [placeholder]="'Enter Relation Type'"
                [control]="formGroup?.controls?.relationType"
                [viewMode]="'create'"
                [multi]="false"
                [optionLabel]="'name'"
                [optionValue]="'name'"
                [showStatusBadge]="false"
                [customProjectionFields]="['code', 'recordStatus', 'label', 'name', 'color', 'icon']"
                [badgeView]="true"
                [useCustomBadgeMode]="true"
                [targetTypes]="[tagType]"
                [inlineMode]="true"
                [listBoxMode]="false"
              >
              </app-target-code-selector>
            </div>
            <div class="w-12">
              <app-drop-down-input
                label="Target Type"
                [placeholder]="'Select Item'"
                [name]="'dropdown'"
                [optionLabel]="'label'"
                [items]="listOfTypes"
                [optionValue]="'value'"
                [control]="formGroup?.controls?.targetType"
                [badgeView]="true"
              ></app-drop-down-input>
            </div>
          </div>
          <div class="col-6">
            <app-text-editor
              [height]="'100px'"
              [advanced]="true"
              [name]="'textarea'"
              [label]="''"
              [placeholder]="'Enter Text...'"
              [control]="formGroup?.controls?.notes"
            ></app-text-editor>
          </div>
          @if (selectedType) {
            <div class="col-12 md:col-12">
              <app-target-code-selector
                [excludeCodes]="excludedCodes"
                [label]="'Target Element'"
                [placeholder]="'Search Items'"
                [control]="formGroup?.controls?.targetCode"
                [viewMode]="'create'"
                [multi]="true"
                [targetTypes]="[selectedType]"
                [listBoxMode]="true"
                [inlineMode]="true"
                [optionValue]="undefined"
                [dataKey]="'code'"
              >
              </app-target-code-selector>
            </div>
          }
        }
      }
    }
  </div>
  <ng-content></ng-content>
</form>

<ng-template #checkBoxTemplate let-name let-checkControl="checkControl" let-control="control">
  @if (formGroup) {
    <div class="col-1">
      <app-check-box
        [value]="true"
        [control]="checkControl"
        (onCheckboxChange)="formChecked(name, control, $event)"
      ></app-check-box>
    </div>
  }
</ng-template>
