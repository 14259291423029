/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponsePolicyEvent { 
    status?: number;
    data?: ApiResponsePolicyEvent.DataEnum;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponsePolicyEvent {
    export type DataEnum = 'TO_DRAFT' | 'TO_REVIEW' | 'ADD_EXCEPTION' | 'TO_PUBLISHED' | 'TO_RETIRE' | 'TO_WAITING_APPROVE' | 'APPROVE' | 'NO_FURTHER_COMMENT' | 'TO_END';
    export const DataEnum = {
        ToDraft: 'TO_DRAFT' as DataEnum,
        ToReview: 'TO_REVIEW' as DataEnum,
        AddException: 'ADD_EXCEPTION' as DataEnum,
        ToPublished: 'TO_PUBLISHED' as DataEnum,
        ToRetire: 'TO_RETIRE' as DataEnum,
        ToWaitingApprove: 'TO_WAITING_APPROVE' as DataEnum,
        Approve: 'APPROVE' as DataEnum,
        NoFurtherComment: 'NO_FURTHER_COMMENT' as DataEnum,
        ToEnd: 'TO_END' as DataEnum
    };
}


