import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';

@Component({
  selector: 'app-number-filter',
  templateUrl: './number-filter.component.html',
  styleUrl: './number-filter.component.scss'
})
export class NumberFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }
  initInput() {
    this.dropDownInput = {
      componentType: NumberInputComponent,
      options: {
        name: 'numbr',
        label: '',
        placeholder: '',
        control: new FormControl(null),
      },
    };
  }
}
