<div class="meter-container">
  <!-- Line -->
  <div class="line"></div>
  @if (data?.min != undefined) {
    <!-- MIN Pin -->
    <span class="tag pin-below min-tag" [ngStyle]="{ 'left.%': 0 }">
      <!-- <span class="pin"></span> -->
      <span class="label mt-6" [style.transform]="'translateX(' + alignLabel(data?.min) + 'px)'">
        @if (isDate(data.min)) {
          <app-date-view [data]="data.min?.toISOString()"></app-date-view>
        } @else {
          {{ data.min }}
        }
      </span>
      <i class="pi pi-arrow-up absolute icon" [style]="{ bottom: '14px', zIndex: '-1' }"></i>
    </span>
  }
  @if (data.avg != undefined) {
    <!-- AVG Pin -->
    <span class="tag pin-above avg-tag" [ngStyle]="{ 'left.%': avgPosition }">
      <!-- <span class="pin hidd"></span> -->
      <span
        class="label align-center mb-6"
        [style.transform]="
          'translateX(' +
          (avgPosition == 0 || avgPosition == 100 ? (avgPosition == 0 ? '' : '-') + alignLabel(data?.min) : '0') +
          'px)'
        "
      >
        @if (isDate(data.avg)) {
          <app-date-view [data]="data.avg?.toISOString()"></app-date-view>
        } @else {
          {{ data.avg }}
        }
      </span>
      <i class="pi pi-arrow-down absolute icon" [style]="{ top: '14px', zIndex: '-1' }"></i>
    </span>
  }
  @if (data?.max != undefined) {
    <!-- MAX Pin -->
    <span class="tag pin-below max-tag" [ngStyle]="{ 'left.%': 100 }">
      <!-- <span class="pin"></span> -->
      <span class="label mt-6" [style.transform]="'translateX(-' + alignLabel(data?.max) + 'px)'">
        @if (isDate(data.max)) {
          <app-date-view [data]="data.max?.toISOString()"></app-date-view>
        } @else {
          {{ data.max }}
        }
      </span>
      <i class="pi pi-arrow-up absolute icon" [style]="{ bottom: '14px', zIndex: '-1' }"></i>
    </span>
  }
</div>
