
import { Component, Input } from "@angular/core";
import { EditorComponent } from "@progress/kendo-angular-editor";
import { IAction } from "@shared/classes";
import { ImageInfo } from "./upload.component";
@Component({
  selector: "my-dialog",
  styles: [
    `
      my-upload {
        min-width: 175px;
        max-width: 275px;
      }

      label {
        width: 100px;
      }
    `,
  ],
  template: `
    <kendo-dialog
      [title]="'general.actions.insertImage' | translate "
      *ngIf="opened"
      (close)="close()"
      [minWidth]="250"
      [width]="450"
    >
      <div class="row example-wrapper">
        <div class="col-xs-8 col-sm-12 example-col">
          <div class="card">
            <div class="card-block">
              <form class="k-form-inline">
                <div class="k-form-field">
                  <label>{{'general.actions.image' | translate }}</label>
                  <my-upload                     
                  class="w-full"
                  style="max-width:none;"
                  (valueChange)="setImageInfo($event)"
                  > </my-upload>
                </div>
                @if(height){
                <div class="k-form-field">
                  <label [for]="heightInput">{{'general.actions.heightPx' | translate }}</label>
                  <kendo-numerictextbox
                    #heightInput
                    format="n0"
                    [(value)]="height"
                    [min]="0"
                  >
                  </kendo-numerictextbox>
                </div>
                }
                @if(width){
                <div class="k-form-field">
                  <label [for]="widthInput">{{'general.actions.widthPx' | translate }}</label>
                  <kendo-numerictextbox
                    #widthInput
                    format="n0"
                    [(value)]="width"
                    [min]="0"
                  >
                  </kendo-numerictextbox>
                </div>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
      <kendo-dialog-actions>
        <app-button [action]="closeAction"></app-button>
        @if(canInsert){
        <app-button [action]="insertAction"></app-button>
        }
      </kendo-dialog-actions>
    </kendo-dialog>
  `,
})
export class TextEditorDialogComponent {
  @Input() public editor: EditorComponent;

  public opened = false;
  public src: string;
  public height: number;
  public width: number;
  closeAction: IAction = {
    id: 1,
    label: 'Close',
    buttonType: 'button',
    // buttonClass: "mr-2",
    command: this.close.bind(this),
  };
  insertAction: IAction = {
    id: 2,
    label: 'Insert',
    buttonType: 'button',
    // buttonClass: "mr-2",
    command: this.uploadImage.bind(this),
  };
  public get canInsert() {
    return this.src;
  }

  public uploadImage(): void {
    if (this.canInsert) {
      // Invoking the insertImage command of the Editor.
      this.editor.exec("insertImage", this.imageInfo);

      // Closing the Dialog.
      this.close();
    }
  }

  public get imageInfo(): ImageInfo {
    return {
      src: this.src,
      height: this.height,
      width: this.width,
    };
  }

  public setImageInfo(value: ImageInfo) {
    if (value) {
      this.src = value.src;
      this.height = value.height;
      this.width = value.width;
    } else {
      this.resetData();
    }
  }

  public open(): void {
    this.opened = true;
  }

  public close(): void {
    this.opened = false;
    this.resetData();
  }

  public resetData(): void {
    this.src = null;
    this.width = null;
    this.height = null;
  }

  /*
        Mocked backend service.
        For further details, check
        https://angular.io/guide/http#writing-an-interceptor
   */
}
