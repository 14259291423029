<div class="card" style="margin-bottom: 0">
  <div class="flex flex-wrap justify-content-between gap-3">
    <div class="flex flex-wrap gap-3">
      <h4 class="mb-0">
        {{ 'modules.framework.controlObjective.linkControlObjectives' | translate: 'Link Control Objectives' }}
      </h4>
    </div>
    <div class="flex flex-row gap-2">
      @if (readyToLink?.length > 0 || readyToUnlink?.length > 0 || readyToAddAndLink?.length > 0) {
        <app-button [action]="linkAction"></app-button>
      }
      <app-button [action]="addAndLinkAction"></app-button>
    </div>
  </div>
  <!-- </ng-container> -->
  <!-- <ng-container page-actions>
  <app-flat-rule-handler *ngIf="viewMode == 'edit' && showRuleHandler"
    [actionMap]="actionsMap"
  [config]="{requestService:requestService,itemId:itemId}"></app-flat-rule-handler>
</ng-container> -->
  <div class="col flex flex-column align-items-start justify-content-start w-full">
    <!-- <app-code-navigation *ngFor="let case of allAuditCases" [data]="case?.code"></app-code-navigation> -->
    <div class="formgrid grid p-fluid w-full">
      <!-- @if (selectedControlObjectives.length || allControlObjectives.length) { -->
      <p-divider class="w-full" align="left">
        <b>
          {{
            'modules.framework.controlObjective.selectedControlObjectives' | translate: 'Selected Control Objectives'
          }}
        </b>
      </p-divider>
      <!-- } -->

      @if (selectedControlObjectives?.length || allControlObjectivesName?.length || allControlObjectivesDesc?.length) {
        <div class="col-12 md:col-12">
          <app-checkbox-list
            [key]="'selectedControlObjectives'"
            [list]="selectedControlObjectives"
            [cols]="cols"
            (onChange)="onObjectiveRemoved($event)"
          ></app-checkbox-list>
        </div>
      }

      <p-divider class="w-full" align="left">
        <b>
          {{
            'modules.framework.controlObjective.availableControlObjectives' | translate: 'Available Control Objectives'
          }}
        </b>
      </p-divider>
      <!-- <div class="mb-4 w-full text-center">
        @if (!(selectedControlObjectives.length || allControlObjectives.length) && !loading) {
          <app-text-view [data]="'No Control Objectives'"></app-text-view>
        }
      </div> -->
      <div class="col-12 md:col-12 mb-4">
        <div class="flex flex-column">
          <app-tab-menu
            class="w-full"
            [scrollable]="true"
            [model]="menuItems"
            [activeItem]="activeMenuItem"
            (activeItemChange)="activeMenuItemChange($event)"
          />
          @if (activeMenuItem?.id == '1') {
            <app-tab-menu
              class="w-full"
              [scrollable]="true"
              [model]="smartMenuItems"
              [activeItem]="activeSmartMenuItem"
              (activeItemChange)="activeSmartMenuItemChange($event)"
            />
            @if (activeSmartMenuItem?.id == '1') {
              <div class="flex flex-column gap-2">
                <app-checkbox-list
                  [key]="'allControlObjectivesName'"
                  [list]="allControlObjectivesName"
                  [cols]="cols"
                  [disableSelectAll]="true"
                  (onChange)="onObjectiveSelectedName($event)"
                ></app-checkbox-list>
                <p-paginator
                  (onPageChange)="onPageChangeName($event)"
                  [first]="paginatorName?.size * paginatorName.page"
                  [rows]="paginatorName.size"
                  [totalRecords]="totalNameElements"
                ></p-paginator>
              </div>
            } @else {
              <div class="flex flex-column gap-2">
                <app-checkbox-list
                  [key]="'allControlObjectivesDesc'"
                  [list]="allControlObjectivesDesc"
                  [cols]="cols"
                  [disableSelectAll]="true"
                  (onChange)="onObjectiveSelectedDesc($event)"
                ></app-checkbox-list>
                <p-paginator
                  (onPageChange)="onPageChangeDesc($event)"
                  [first]="paginatorDesc?.size * paginatorDesc.page"
                  [rows]="paginatorDesc.size"
                  [totalRecords]="totalDescElements"
                ></p-paginator>
              </div>
            }
          } @else if (activeMenuItem?.id == '2') {
            <app-target-code-selector
              [placeholder]="
                'modules.framework.controlObjective.searchControlObjectives' | translate: 'Search Control Objectives'
              "
              [control]="allControlObjectivesControl"
              [viewMode]="'create'"
              [multi]="true"
              [optionLabel]="'name'"
              [optionValue]="undefined"
              [dataKey]="'code'"
              [tableMode]="true"
              [excludeCodes]="exlcudedObjectives"
              [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
              [targetTypes]="['CONTROL_OBJECTIVE']"
              [forcedRefresh]="true"
              class="mt-3"
            >
            </app-target-code-selector>
            <app-button [action]="addToSelectedAction"></app-button>
          }
        </div>
      </div>
    </div>
    <!-- {{ readyToLink | json }}
  {{ readyToUnlink | json }} -->
  </div>
</div>
