<div class="p-2 notification-tray" style="width: 450px; height: auto; min-height: 60vh">
  <div class="flex align-items-center justify-content-between mb-3">
    <a
      [routerLink]="'activity-feed/notification/my-notification/list'"
      class="text-900 font-medium text-xl hover:text-primary-400"
      >{{ 'general.notificationTray.notifications' | translate }} <i class="pi pi-external-link"></i>
    </a>
    <div class="flex gap-2">
      <div class="align-self-center">
        <app-switch-input
          [styleClass]="'mb-0'"
          [labelWidthFit]="true"
          [label]="'general.notificationTray.' + (displayUnseen ? 'showNew' : 'showAll') | translate"
          [labelLeft]="true"
          [name]="'display_unseen'"
          [control]="displayUnseenControl"
          (onChanges)="onChangeShowAll($event)"
          [viewMode]="'edit'"
        >
        </app-switch-input>
      </div>
      <app-button [action]="seeAllAction"></app-button>
      <app-button [action]="goToPreferences"></app-button>
    </div>
    <!-- <div>
    <button type="button" class="p-button-text p-button-secondary" pButton icon="pi pi-ellipsis-v" label="" (click)="menu.toggle($event)"></button>
  </div> -->
  </div>
  <p-tabView>
    <p-tabPanel
      [header]="'general.notificationTray.direct' | translate"
      leftIcon="pi pi-inbox"
      [tooltip]="'general.notificationTray.directNotificationsRelatedToYou' | translate"
    >
      <div pFocusTrap style="max-height: 50vh; overflow: auto; display: grid">
        @for (group of directDateGroupedNotifications; track group; let last = $last) {
          <p class="mb-0 mt-1 text-500 font-medium">
            {{ group.day == todayClipped ? 'Today' : (getLastDate(group.day) | timeago) }}
          </p>
          <app-notifications-list [notifications]="$any(group.notifications)"></app-notifications-list>
          @if (!last) {
            <p-divider styleClass="w-29rem"></p-divider>
          }
        }
        @if (loading) {
          <div class="mt-3">
            <ul class="m-0 p-0 list-none">
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        }
        <div class="w-full mt-3">
          @if (this.notificationPage?.number + 1 < this.notificationPage?.totalPages) {
            <app-button [action]="loadMoreAction"></app-button>
          }
          @if (!loading && !(this.notificationPage?.number + 1 < this.notificationPage?.totalPages)) {
            <p class="w-full text-center text-primary-400">
              {{ 'general.notificationTray.reachedLastNotification' | translate }}
            </p>
          }
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel
      [header]="'general.notificationTray.watching' | translate"
      leftIcon="pi pi-eye"
      [tooltip]="'general.notificationTray.notificationsFromYourSubscription' | translate"
    >
      <div pFocusTrap style="max-height: 50vh; overflow: auto">
        @for (group of watchingDateGroupedNotifications; track group; let last = $last) {
          <p class="mb-0 mt-1 text-500 font-medium">
            {{ group.day == todayClipped ? 'Today' : (group.day | timeago) }}
          </p>
          <app-notifications-list [notifications]="$any(group.notifications)"></app-notifications-list>
          @if (!last) {
            <p-divider styleClass="w-29rem"></p-divider>
          }
        }
        @if (loading) {
          <div class="mt-3">
            <ul class="m-0 p-0 list-none">
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        }
        <div class="w-full mt-3">
          @if (this.notificationPage?.number + 1 < this.notificationPage?.totalPages) {
            <app-button [action]="loadMoreAction"></app-button>
          }
          @if (!loading && !(this.notificationPage?.number + 1 < this.notificationPage?.totalPages)) {
            <p class="w-full text-center text-primary-400">
              {{ 'general.notificationTray.reachedLastNotification' | translate }}
            </p>
          }
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel
      [header]="'general.notificationTray.grouped' | translate"
      leftIcon="pi pi-sitemap"
      [tooltip]="'general.notificationTray.notificationsGroupedByDivisions' | translate"
    >
      <div pFocusTrap style="max-height: 50vh; overflow: auto">
        @for (group of groupedNotifications | keyvalue; track group; let last = $last) {
          <app-badge-item [status]="mapModuleToType(group.key)" [text]="mapModuleToType(group.key)"></app-badge-item>
          <app-notifications-list [notifications]="$any(group.value)"></app-notifications-list>
          @if (!last) {
            <p-divider styleClass="w-29rem"></p-divider>
          }
        }
        @if (loading) {
          <div class="mt-3">
            <ul class="m-0 p-0 list-none">
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
              <li>
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        }
        <div class="w-full mt-3">
          @if (this.notificationPage?.number + 1 < this.notificationPage?.totalPages) {
            <app-button [action]="loadMoreAction"></app-button>
          }
          @if (!loading && !(this.notificationPage?.number + 1 < this.notificationPage?.totalPages)) {
            <p class="w-full text-center text-primary-400">
              {{ 'general.notificationTray.reachedLastNotification' | translate }}
            </p>
          }
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
