<!-- <div *ngIf="fieldViewMode != 'view' && !viewModeOnly" class="col-12 py-0">
<span class="p-float-label">
  <p-autoComplete
    [(ngModel)]="selectedFactor"
    [ngModelOptions]="{ standalone: true }"
    [showEmptyMessage]="true"
    [suggestions]="filteredItems"
    (completeMethod)="filterItems($event)"
    (onSelect)="onSelectItem($event)"
    field="label"
    [dropdown]="true"
    [styleClass]="'w-full'"
  ></p-autoComplete>
  <label >Impact Factors</label>
</span>
<div class="flex flex-col align-items-end justify-content-start gap-2 pt-2">
  <div class="formgrid grid flex-1 p-fluid">
    <div [class]="'col'">
      <app-impact-factor-with-value-selector
        [items]="riskMeth?.impactFactors"
        [control]="testControl"
        [viewMode]="'edit'"
        >
      </app-impact-factor-with-value-selector>
    </div>
  </div>
  <div class="flex flex-none mb-3 p-2 pr-0">
    <app-button [action]="addFormButtonAction" [commandData]="testControl.value" ></app-button>
  </div>
</div>

</div> -->
<div class="col-12">
  <app-basic-input
    label="Risk Name"
    [placeholder]="'Enter Name'"
    [control]="formGroup?.controls?.name"
    [viewMode]="fieldViewMode"
    [readOnly]="readOnlyName"
  >
  </app-basic-input>
</div>
@if (optionsFields?.length > 0) {
  <div class="col-12 mb-4">
    <app-form-repeater
      [title]="''"
      [label]="'Impact Factors'"
      [viewMode]="fieldViewMode"
      [cols]="optionsFields"
      [control]="formGroup?.controls?.factors"
      [addOrderFieldToOptions]="false"
      [showAddForm]="true"
      [showReorder]="false"
      [triggerRowEditIndex]="triggerRowEditIndex"
      [showAddFormAsPopup]="true"
    ></app-form-repeater>
  </div>
}
<div class="col-12 md:col-6">
  <!-- <app-single-child-tree-select-input [items]="items"
  [label]="'Risk Impact'"
  [control]="formGroup?.controls?.inheritedRiskImpact"
[viewMode]="fieldViewMode"></app-single-child-tree-select-input> -->
  <app-drop-down-input
    label="Inherited Risk Impact"
    [placeholder]="'Select Inherited Risk Impact'"
    [name]="'in-risk-imp'"
    [optionLabel]="'name'"
    [items]="impactList"
    [control]="riskImpactObjectControl"
    [viewMode]="fieldViewMode"
    [dataKey]="'code'"
    [showStatusBadge]="false"
  ></app-drop-down-input>
</div>
<div class="col-12 md:col-6">
  <app-drop-down-input
    label="Inherited Likelihood"
    [placeholder]="'Select Inherited Likelihood'"
    [name]="'in-risk-imp'"
    [optionLabel]="'name'"
    [items]="likelihoodList"
    [control]="formGroup?.controls?.inheritedLikelihoodDto"
    [viewMode]="fieldViewMode"
    [dataKey]="'code'"
    [showStatusBadge]="false"
  >
    <ng-template let-item #selectedItemTemplate>
      <span class="">
        {{
          item.label +
            ' ( ' +
            ('general.actions.from' | translate) +
            ' ' +
            item.occuresFrom +
            ' ' +
            ('general.actions.to' | translate) +
            ' ' +
            item.occuresTo +
            (item.occurrenceDuration
              ? ' ' + ('general.actions.times per' | translate) + ' ' + (item.occurrenceDuration | humanize)
              : '') +
            ' )'
        }}
      </span>
    </ng-template>
    <ng-template let-item #itemTemplate>
      <span class="">
        {{
          item.label +
            ' ( ' +
            ('general.actions.from' | translate) +
            ' ' +
            item.occuresFrom +
            ' ' +
            ('general.actions.to' | translate) +
            ' ' +
            item.occuresTo +
            (item.occurrenceDuration
              ? ' ' + ('general.actions.times per' | translate) + ' ' + (item.occurrenceDuration | humanize)
              : '') +
            ' )'
        }}
      </span>
    </ng-template>
  </app-drop-down-input>
</div>
<!-- <div class="col-12 md:col-4">
<app-drop-down-input
  [label]="'Response Strategy'"
  [placeholder]="'Select Item'"
  [name]="'type'"
  [optionLabel]="'label'"
  [items]="strategyList"
  [optionValue]="'value'"
  [control]="formGroup?.controls?.strategy"
  [viewMode]="fieldViewMode"
  [badgeView]="true"
></app-drop-down-input>
</div> -->
<div class="col-12 md:col-6">
  <!-- <app-basic-input label="Inherited Risk"
  [placeholder]="'Inherited Risk'"
  [control]="formGroup?.controls?.inheritedRisk"
[viewMode]="fieldViewMode"></app-basic-input> -->
  <app-risk-meter-bar
    label="Inherited Risk"
    [riskMeth]="riskMeth"
    [value]="formGroup?.controls?.inheritedRisk?.value"
    [viewMode]="fieldViewMode"
  ></app-risk-meter-bar>
</div>
<div class="col-12 md:col-6">
  <app-drop-down-input
    [label]="'Inherited Risk Acceptance'"
    [placeholder]="'Select Item'"
    [name]="'inheritedRiskAcceptanceList'"
    [optionLabel]="'label'"
    [items]="inheritedRiskAcceptanceList"
    [optionValue]="'value'"
    [control]="formGroup?.controls?.inheritedRiskAcceptance"
    [viewMode]="'view'"
    [badgeView]="true"
  ></app-drop-down-input>
</div>
