import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, IAction, PolicyDto } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-comments-form',
  templateUrl: './comments-form.component.html',
  styleUrls: ['./comments-form.component.scss'],
})
export class CommentsFormComponent extends BaseForm<PolicyDto> implements OnInit {
  @Input() autoFocus: boolean = false;
  @Input() submitButtonAction: IAction = {
    id: 1,
    label: 'Post',
    buttonType: 'button',
    command: this.onSubmitForm.bind(this),
    icon: 'pi pi-comment',
    status$: this.formValid$,
    loading$: this.loadingState$,
    // buttonStyle: 'outlined'
  };
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, null);
  }

  ngOnInit(): void { }

  getData() {
    return {
      ...this.formGroup.getRawValue(),
    };
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      comment: new FormControl(null, Validators.required),
    });
  }

  onSubmitForm() {
    this.formSubmit.emit(this.getData());

    this.formGroup.reset({
      comment: '',
    });
  }
}
