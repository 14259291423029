import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  DataTypeEnum,
  IAction,
  IDynamicComponent,
  IViewMode,
  ModuleKeywords,
  RiskMethodology,
  RiskMethodologyDto,
  RiskMethodologyImpactValue,
  RiskMethodologyLikelihoodValue,
  RiskMethodologyThresholdValue,
  getEnumOptions,
} from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { AppDialogService, IConfirmationConfig, IConfirmationPosition } from '@shared/services/app-dialog.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { RiskMethodologyImpactFactorDataService } from 'app/modules/risk/services/data/risk-methodology-impact-factor-data.service';
import { RiskMethodologyImpactValueDataService } from 'app/modules/risk/services/data/risk-methodology-impact-value-data.service';
import { RiskMethodologyLikelihoodValueDataService } from 'app/modules/risk/services/data/risk-methodology-likelihood-value-data.service';
import { RiskMethodologyThresholdValueDataService } from 'app/modules/risk/services/data/risk-methodology-threshold-value-data.service';
import { findIndex, maxBy, minBy } from 'lodash-es';
import { catchError, forkJoin, of } from 'rxjs';
import { RiskMethodologyDataService } from '../../../services/data/risk-methodology-data.service';
import { ImpactFactorOptionsFormPopupComponent } from './impact-factor-options-form-popup/impact-factor-options-form-popup.component';

@Component({
  selector: 'app-risk-methodology-form',
  templateUrl: './risk-methodology-form.component.html',
  styleUrls: ['./risk-methodology-form.component.scss'],
})
export class RiskMethodologyFormComponent extends BaseForm<RiskMethodologyDto> implements OnInit, AfterViewInit {
  impactFactorOptionsFormPopupComponent = ImpactFactorOptionsFormPopupComponent;
  acceptableRisk: number = 100;
  emitPaused = false;
  options = {
    floor: 0,
    ceil: 100,
  };

  @Input() submitButtonActionPopup: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmitConfirm.bind(this),
    icon: 'pi pi-save',
    passEvent: true,
  };

  addItemButtonActionPopup: IAction = {
    id: 1,
    label: 'Add Item',
    buttonType: 'button',
    command: this.onAddThreshold.bind(this),
    buttonClass: '',
    icon: 'pi pi-plus',
    passEvent: true,
  };

  activeMethodology: RiskMethodology;

  likelihoodValuesFields: IDynamicComponent[] = [];
  impactValuesFields: IDynamicComponent[] = [];
  impactFactorsFields: IDynamicComponent[] = [];
  IVList: RiskMethodologyImpactValue[];
  LVList: RiskMethodologyImpactValue[];
  IFList: RiskMethodologyImpactValue[];
  TVList: RiskMethodologyImpactValue[];

  public thresholdList: RiskMethodologyThresholdValue[];

  public minThreshold;
  public maxThreshold;

  constructor(
    public viewModeService: ViewModeService,
    public appDialogService: AppDialogService,
    public impactFactorService: RiskMethodologyImpactFactorDataService,
    public impactValueService: RiskMethodologyImpactValueDataService,
    public likelihoodValueService: RiskMethodologyLikelihoodValueDataService,
    public thresholdValueService: RiskMethodologyThresholdValueDataService,
    private riskMethodologyDataService: RiskMethodologyDataService
  ) {
    super(viewModeService, ModuleKeywords.RiskMethodology);
    this.initFormRepeaterFields();
  }

  ngOnInit(): void {
    // this.riskMethodologyDataService.fetchActiveRiskMethodology().subscribe({
    //   next: (res) => {
    //     this.setData(this.activeMethodology);
    //   },
    //   error: (error) => {

    //   },
    //   complete: () => {},
    // });

    this.formGroup.valueChanges.subscribe((active) => {
      this.reloadValues({ ...active });
    });
  }

  private reloadValues(active: RiskMethodology) {
    this.activeMethodology = active;
    this.thresholdList = this.activeMethodology?.thresholdValuesDto;
    this.calculateMinMax();
  }

  calculateMinMax() {
    this.maxThreshold =
      maxBy(this.activeMethodology?.impactValuesDto, 'value')?.value *
      maxBy(this.activeMethodology?.likelihoodValuesDto, 'value')?.value || 0;

    this.minThreshold =
      minBy(this.activeMethodology?.impactValuesDto, 'value')?.value *
      minBy(this.activeMethodology?.likelihoodValuesDto, 'value')?.value || 0;

    this.options = {
      floor: this.minThreshold,
      ceil: this.maxThreshold,
    };
  }

  getData() {
    const retData = {
      ...this.formGroup.getRawValue(),
      acceptableRisk: this.acceptableRisk,
      thresholdValues: this.thresholdList ? [...(this.thresholdList.map(x => x.code))] : [],
    };

    // let currentTime = new Date().getTime();
    let riskMeth: RiskMethodology = retData;
    riskMeth.impactFactors = riskMeth?.impactFactorsDto?.map((item, index) => item.code
      // code: item.code ? item.code : `IFA-${currentTime + index}`,
    );
    riskMeth.likelihoodValues = riskMeth?.likelihoodValuesDto?.map((item, index) => item.code
      // code: item.code ? item.code : `LHV-${currentTime + index}`,
    );
    riskMeth.impactValues = riskMeth?.impactValuesDto?.map((item, index) => item.code
      // code: item.code ? item.code : `IMV-${currentTime + index}`, // (currentTime+index),//`IMV-${currentTime+index}`,
    );
    if (retData.thresholdValuesDto) {
      delete retData.thresholdValuesDto;
    }
    if (retData.impactFactorsDto) {
      delete retData.impactFactorsDto;
    }
    if (retData.likelihoodValuesDto) {
      delete retData.likelihoodValuesDto;
    }
    if (retData.impactValuesDto) {
      delete retData.impactValuesDto;
    }
    return this.viewModeService.viewMode == 'create' ?
      this.getDataKeyValueFormat(riskMeth)
      : this.getChangedFormValues(riskMeth).updateItems;
  }

  setData(data: any) {
    let currentTimeStamp = new Date().getTime();
    data?.impactValuesDto?.forEach((element, index) => {
      element.code = element.code /*?? `IMV-${currentTimeStamp + index}`*/;
    });
    this.IFList = data?.impactFactorsDto;
    this.IVList = data?.impactValuesDto;
    this.LVList = data?.likelihoodValuesDto;
    this.TVList = data?.thresholdValuesDto;
    this.formGroup.patchValue({ ...data }, { emitEvent: false });
    this.data = data;
    this.reloadValues(data);
    this.acceptableRisk = this.activeMethodology?.acceptableRisk;
  }


  ngAfterViewInit(): void {
    this._setupSubscriptions();
    this.setupFormSubscribers();
  }

  setupFormSubscribers() {
    this.handleValueChanges(
      this.formGroup,
      'likelihoodValuesDto',
      this.LVList,
      this.getDataKeyValueFormatFromPrev.bind(this),
      this.getDataKeyValueFormat.bind(this),
      {
        patchSingleUpdate: this.likelihoodValueService.patchSingleUpdate.bind(this.likelihoodValueService),
        create: this.likelihoodValueService.create.bind(this.likelihoodValueService),
        updateValues: this.updateLV.bind(this),
        getUpdatedValues: () => this.LVList,
      }
    );

    this.handleValueChanges(
      this.formGroup,
      'impactValuesDto',
      this.IVList,
      this.getDataKeyValueFormatFromPrev.bind(this),
      this.getDataKeyValueFormat.bind(this),
      {
        patchSingleUpdate: this.impactValueService.patchSingleUpdate.bind(this.impactValueService),
        create: this.impactValueService.create.bind(this.impactValueService),
        updateValues: this.updateIV.bind(this),
        getUpdatedValues: () => this.IVList,
      }
    );

    this.handleValueChanges(
      this.formGroup,
      'impactFactorsDto',
      this.IFList,
      this.getDataKeyValueFormatFromPrev.bind(this),
      this.getDataKeyValueFormat.bind(this),
      {
        patchSingleUpdate: this.impactFactorService.patchSingleUpdate.bind(this.impactFactorService),
        create: this.impactFactorService.create.bind(this.impactFactorService),
        updateValues: this.updateIF.bind(this),
        getUpdatedValues: () => this.IFList,
      }
    );

    // this.handleValueChanges(
    //   this.formGroup,
    //   'thresholdValuesDto',
    //   this.TVList,
    //   this.getDataKeyValueFormatFromPrev.bind(this),
    //   this.getDataKeyValueFormat.bind(this),
    //   {
    //     patchSingleUpdate: this.thresholdValueService.patchSingleUpdate.bind(this.thresholdValueService),
    //     create: this.thresholdValueService.create.bind(this.thresholdValueService),
    //     updateValues: this.updateTV.bind(this),
    //     getUpdatedValues: () => this.TVList,
    //   }
    // );
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      approver: new FormControl(null, Validators.required),
      description: new FormControl(null),
      likelihoodValuesDto: new FormControl(null),
      impactValuesDto: new FormControl(null),
      impactFactorsDto: new FormControl([]),
      thresholdValuesDto: new FormControl(null),
      acceptableRisk: new FormControl(null),
    });


  }

  handleValueChanges(
    formGroup,
    controlName,
    oldValues,
    getDataKeyValueFormatFromPrev,
    getDataKeyValueFormat,
    serviceMethods
  ) {
    formGroup.controls[controlName].valueChanges.subscribe(async (newValues) => {
      if (this.emitPaused) return;
      const oldVals = serviceMethods.getUpdatedValues();
      console.log('values changed');
      if (newValues?.length) {
        const forkList = [];

        newValues.forEach((element) => {
          let existedVal = null;

          if (element.code && oldVals?.length) {
            existedVal = oldVals.find((y) => y.code === element.code);

            if (existedVal) {
              const req = getDataKeyValueFormatFromPrev(element, existedVal);
              if (req?.length) {
                forkList.push(
                  serviceMethods.patchSingleUpdate(
                    req,
                    element.code
                  )
                );
              }
            }
          } else if (!element.code) {
            forkList.push(
              serviceMethods.create(
                getDataKeyValueFormat({ ...element, code: undefined })
              )
            );
          }
        });

        if (forkList.length) {
          forkJoin(
            forkList.map((obs) =>
              obs.pipe(
                catchError((err) => {
                  console.error('Error in processing request:', err);
                  return of(null); // Handle error and continue
                })
              )
            )
          ).subscribe({
            next: (responses) => {
              if (responses?.length) {
                responses.forEach((data) => {
                  if (data) {
                    serviceMethods.updateValues(data.data);
                  } else {
                    formGroup.controls[controlName].patchValue(
                      serviceMethods.getUpdatedValues(),
                      { emitEvent: false }
                    );
                  }
                });
              }
            },
          });
        }
      }
    });
  }




  updateLV(newVal) {
    const LVList = this.formGroup.controls.likelihoodValuesDto.value ?? [];
    const map = {}

    LVList.forEach(element => {
      if (element.code) {
        map[element.code] = element;
      }
    });
    if (newVal.code)
      map[newVal.code] = newVal;
    this.LVList = map ? Object.values(map) : [];
    this.emitPaused = true;
    this.formGroup.controls.likelihoodValuesDto.patchValue(this.LVList);
    setTimeout(() => { this.emitPaused = false; }, 100);
  }

  updateIV(newVal) {
    const IVList = this.formGroup.controls.impactValuesDto.value ?? [];
    const map = {}

    IVList.forEach(element => {
      if (element.code) {
        map[element.code] = element;
      }
    });
    if (newVal.code)
      map[newVal.code] = newVal;
    this.IVList = map ? Object.values(map) : [];
    this.emitPaused = true;
    this.formGroup.controls.impactValuesDto.patchValue(this.IVList);
    setTimeout(() => { this.emitPaused = false; }, 100);
  }

  updateIF(newVal) {
    const IFList = [...this.formGroup.controls.impactFactorsDto.value ?? []];
    const map = {}

    IFList.forEach(element => {
      if (element.code) {
        map[element.code] = element;
      }
    });
    if (newVal.code)
      map[newVal.code] = newVal;
    this.IFList = map ? Object.values(map) : [];
    this.emitPaused = true;
    this.formGroup.controls.impactFactorsDto.patchValue(this.IFList);
    setTimeout(() => { this.emitPaused = false; }, 100);
  }

  updateTV(newVal) {
    const TVList = this.formGroup.controls.thresholdValuesDto.value ?? [];
    const map = {}

    TVList.forEach(element => {
      if (element.code) {
        map[element.code] = element;
      }
    });
    if (newVal.code)
      map[newVal.code] = newVal;
    this.TVList = map ? Object.values(map) : [];
    this.emitPaused = true;
    this.formGroup.controls.thresholdValuesDto.patchValue(this.TVList);
    setTimeout(() => { this.emitPaused = false; }, 100);
  }

  initFormRepeaterFields() {
    this.likelihoodValuesFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Code',
          name: 'code',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          hideInFormRepeater: true,
          formatGetValue: (control) => {
            let currentTimeStamp = new Date().getTime();
            return control?.value /*?? `LHV-${currentTimeStamp}`]*/;
          },
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Name',
          name: 'name',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: 'Value',
          name: 'value',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Number,
          showLabelInViewMode: false,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
            },
          },
        },
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: 'From',
          name: 'occuresFrom',
          control: new FormControl(null),
          dataType: DataTypeEnum.Number,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
            },
          },
          showLabelInViewMode: false,
        },
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: 'To',
          name: 'occuresTo',
          control: new FormControl(null),
          dataType: DataTypeEnum.Number,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
            },
          },
          showLabelInViewMode: false,
        },
      },
      {
        componentType: DropDownInputComponent,
        options: {
          label: 'Duration',
          name: 'occurrenceDuration',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              multi: false,
              showClear: true,
              optionLabel: 'label',
              optionValue: 'value',
              items: getEnumOptions(RiskMethodologyLikelihoodValue.OccurrenceDurationEnum),
              badgeView: true,
              appendTo: 'body',
            },
          },
          dataType: DataTypeEnum.Badge,
          showLabelInViewMode: false,
        },
      },
    ];

    this.impactValuesFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Code',
          name: 'code',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          hideInFormRepeater: true,
          formatGetValue: (control) => {
            let currentTimeStamp = new Date().getTime();
            return control?.value /*?? `IMV-${currentTimeStamp}`*/;
          },
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Name',
          name: 'name',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: 'Value',
          name: 'value',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Number,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
            },
          },
          showLabelInViewMode: false,
        },
      },
    ];
    this.impactFactorsFields = [
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Code',
          name: 'code',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          hideInFormRepeater: true,
          formatGetValue: (control) => {
            let currentTimeStamp = new Date().getTime();
            return control?.value /*?? `IFA-${currentTimeStamp}`*/;
          },
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Name',
          name: 'name',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Field Options',
          name: 'options',
          control: new FormControl([]),
          dataType: DataTypeEnum.Text,
          inputOptions: {
            dynamicTypeOptionsInput: {
              fieldType: null,
              fieldTypeControl: null,
            },
            // linkedControlName:'type',
          },
          showLabelInViewMode: false,
          hideInFormRepeater: true,
        },
      },
      {
        componentType: TextEditorComponent,
        options: {
          label: 'Description',
          name: 'description',
          control: new FormControl(null),
          dataType: DataTypeEnum.LongWithHoverText,
          showLabelInViewMode: false,
          hideInFormRepeater: true,
        },
      },
    ];
  }

  onSubmitConfirm(event: any) {
    let config: IConfirmationConfig = new IConfirmationConfig();

    config.position = IConfirmationPosition.top;

    config.header = `Are you sure about editing the current Risk Methodology`;
    config.message = 'This Action Will Reset The Current Registered Risks.';

    this.appDialogService.confirm(
      {
        accept: () => {
          this.onSubmitForm();
        },
      },
      config
    );
  }

  onThresholdChange() {
    this.formGroup.controls.thresholdValuesDto.patchValue(this.thresholdList);
  }

  onAddThreshold() {
    let threshold = <RiskMethodologyThresholdValue>{};
    threshold.color = '#999999';
    threshold.value = 1;

    if (this.thresholdList == undefined || this.thresholdList.length == 0) {
      threshold.value = this.maxThreshold;
      this.thresholdList = [threshold];
    } else {
      this.thresholdList.push(threshold);
    }

    this.activeMethodology.thresholdValuesDto = this.thresholdList;
    this.formGroup.controls.thresholdValuesDto.patchValue(this.thresholdList);
  }

  onRemoveThresholdItem(item) {
    let index = findIndex(this.thresholdList, item);
    this.thresholdList.splice(index, 1);
    this.calculateMinMax();
    this.formGroup.controls.thresholdValuesDto.patchValue(this.thresholdList);
  }
  get statusBasedViewMode(): IViewMode {
    return !this.data || this.data?.status == 'DRAFT' ? this.fieldViewMode : 'view';
  }
}
