import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, DataTypeEnum } from '@shared/classes';
import { IMinMaxAvg } from '@shared/classes/view/IMinMaxAvg';

@Component({
  selector: 'app-min-max-avg-meter-view',
  templateUrl: './min-max-avg-meter-view.component.html',
  styleUrl: './min-max-avg-meter-view.component.scss',
})
export class MinMaxAvgMeterViewComponent extends BaseViewItem<IMinMaxAvg> implements OnInit {
  @Input() dataType: DataTypeEnum = DataTypeEnum.Text;
  constructor() {
    super();
  }

  get avgPosition(): number {
    let minValue: number;
    let maxValue: number;
    let avgValue: number;

    if (this.isDate(this.data.min) && this.isDate(this.data.max) && this.isDate(this.data.avg)) {
      minValue = (this.data.min as Date).getTime();
      maxValue = (this.data.max as Date).getTime();
      avgValue = (this.data.avg as Date).getTime();
    } else {
      minValue = this.data.min as number;
      maxValue = this.data.max as number;
      avgValue = this.data.avg as number;
    }

    if (maxValue === minValue) {
      return 0;
    }

    return ((avgValue - minValue) / (maxValue - minValue)) * 100;
  }

  isDate(value: any): value is Date {
    return value instanceof Date;
  }
  ngOnInit(): void { }

  alignLabel(label): string {
    if (!label) return '0';
    return ((label ? label.toString().length : 0) * 2) + '';
  }
}
