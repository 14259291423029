import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum } from '@shared/classes';
import { DynamicComponentBase } from '@shared/classes/view/DynamicComponentBase';

@Component({
  selector: 'app-switch-input',
  templateUrl: './switch-input.component.html',
  styleUrls: ['./switch-input.component.scss'],
  // providers: [{ provide: DynamicComponentBase, useExisting: SwitchInputComponent }]
})
export class SwitchInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Badge;
  @Input() styleClass: string = '';
  @Input() labelLeft: boolean = true;
  @Input() labelWidthFit: boolean = false;
  @Input() labelStyleClass: string = '';
  @Input() trueValue: any = true;
  @Input() falseValue: any = false;
  constructor() {
    super();
  }

  ngOnInit(): void {}

  setInputOptions() {
    this.falseValue = this.inputOptions?.checkBox?.falseValue ?? this.falseValue;
    this.trueValue = this.inputOptions?.checkBox?.trueValue ?? this.trueValue;
  }
}
