import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuditRequestDto, DataTypeEnum, IFieldDefinition, TargetTypeEnum } from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { AttachmentInputComponent } from '@shared/components/ui/input-fields/attachment-input/attachment-input.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DateInputComponent } from '@shared/components/ui/input-fields/date-input/date-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { SuggestionInputComponent } from '@shared/components/ui/input-fields/suggestion-input/suggestion-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class AuditRequestMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Subject',
      key: 'subject',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Subject of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Audit Case Result',
      key: 'auditCaseResult',
      dataType: DataTypeEnum.CodeLink,
      required: false,
      showInTable: false,
      description: 'Case Result related to the record',
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.AuditRequest,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Due Date',
      key: 'dueDate',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Due Date of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      formField: {
        componentType: DateInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Due Date To Response',
      key: 'dueDaysToResponse',
      dataType: DataTypeEnum.DateLong,
      required: false,
      showInTable: true,
      description: 'Due Date To Response of the record',
      tableDefinition: {
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      useInBulk: true,
      formField: {
        componentType: SuggestionInputComponent,
        options: {
          label: 'Due Date To Response',
          name: 'dueDateToResponse',
          placeholder: '2022-11-22',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: [
                { label: 'Yearly', value: 365 },
                { label: 'Quarterly', value: 90 },
                { label: 'Monthly', value: 30 },
                { label: 'Weekly', value: 7 },
                { label: 'Daily', value: 1 },
              ],
              optionLabel: 'name'
            },
            numberInput: {
              min: 0,
              maxFractionDigits: 0,

            },
          },
        },
      },
    },
    {
      name: 'Response By',
      key: 'responseBy',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      description: 'Response By of the record',
      tableDefinition: {
        filter: {
          type: 'userSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
    },
    {
      name: 'Requested By',
      key: 'requestedBy',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      description: 'Requested By of the record',
      tableDefinition: {
        filter: {
          type: 'userSelector',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
    },
    {
      name: 'Attachments',
      key: 'attachments',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: false,
      description: 'Cases linked to the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: AttachmentInputComponent,
        options: {
          label: 'Attachments',
          name: 'attachments',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            attachmentInput: {
              multiple: true,
              uploadLocation: 'CODE',
              bucketId: '',
              mode: 'basic',
              accept: '',
              maxFileSize: 0,
              fileLimit: 0
            },
          },
        },
      },
    },
    {
      name: 'Assigned To',
      key: 'assignedTo',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Assigned To of the record',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: 'Assigned To',
          name: 'assignedTo',
          placeholder: 'Search...',
          control: new FormControl(null),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: 'name'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Audit Request Status',
      key: 'auditRequestStatus',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      // permissions: AppPermissions.ReadGroup,
      description: 'Status of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AuditRequestDto.AuditRequestStatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
