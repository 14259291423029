import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BaseResponse, PolicyBody, PolicyBodyDto, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PolicyBodyDataService extends BaseRequestControllerService<PolicyBodyDto> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/policyBody', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'owner',
      'name',
      'validFrom',
      'validTo',
      'description',
      'policyBody',
      'externalReferences',
      'exceptionDuration',
      'allowExceptions',
      'reviewers',
      'approvers',
      'approvedBy',
      'policyStatus',
      'reviewedBy',
      'policy',
      'versionNumber',
      'label',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
    ]);
  }

  navigateToQuestionListPage() {
    this.router.navigateByUrl(`framework/attestation/list`);
  }
  navigateToListPage() {
    this.router.navigateByUrl(`framework/policy/list`);
  }
  public getNoFurtherComment(policyIdOrCode, mode: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/${policyIdOrCode}/no-further-comment/${mode}`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, options) as Observable<BaseResponse<any>>;
  }
  public getApprove(policyIdOrCode, mode: any, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/${policyIdOrCode}/approve/${mode}`;
    return this.dataService.getData<BaseResponse<PolicyBody>>(newLocal, options) as Observable<BaseResponse<any>>;
  }

  public applyTemplate(policyBodyCode, templateCode, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    const newLocal = this.url + `/${policyBodyCode}/apply-template/${templateCode}`;
    return this.dataService.postData<BaseResponse<PolicyBody>>(newLocal, null, options) as Observable<BaseResponse<any>>;
  }

  relinkControls(body, bodyCode, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.postData<BaseResponse<any>>(
      this.url + '/' + bodyCode + '/relink-controls',
      body,
      options
    ) as Observable<BaseResponse<any>>;
  }
}
