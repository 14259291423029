import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { VirtualScrollerModule } from 'primeng/virtualscroller';

// Application Components
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppComponent } from './app.component';
import { AppConfigComponent } from './app.config.component';
import { AppFooterComponent } from './app.footer.component';
import { AppInlineMenuComponent } from './app.inlinemenu.component';
import { AppMainComponent } from './app.main.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppRightMenuComponent } from './app.rightmenu.component';
import { AppTopbarComponent } from './app.topbar.component';

// Demo pages
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';

// Application services
import { AppBreadcrumbService } from './app.breadcrumb.service';
import { ConfigService } from './app.config.service';
import { MenuService } from './app.menu.service';

import { HeaderInterceptor } from '@core/interceptors/header.interceptor';
import { EditorModule } from '@progress/kendo-angular-editor';

// import { RTL } from '@progress/kendo-angular-l10n';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationService, MessageService, TreeDragDropService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { setAppInjector } from './app-injector';
import { AuthModule } from './core/auth/auth.module';
import { CoreModule } from './core/core.module';
import { EventsModule } from './modules/entity-module/events/events.module';
import { ObjectivesModule } from './modules/entity-module/objectives/objectives.module';
import { IssueTypesModule } from './modules/issues-module/issue-types/issue-types.module';
import { IssuesModule } from './modules/issues-module/issues/issues.module';
import { RemediationPlanModule } from './modules/issues-module/remediation-plan/remediation-plan.module';
import { RiskMethodologyModule } from './modules/risk/risk-methodology/risk-methodology.module';
import { AppWelcomeComponent } from './pages/app.welcome.component';
import { DashboardComponent } from './pages/dashboard.component';
import { EmptyDemoComponent } from './pages/emptydemo.component';
import { FormDemoComponent } from './pages/formdemo.component';
import { SharedModule } from './shared/shared.module';

// FullCalendarModule.registerPlugins([
//     dayGridPlugin,
//     timeGridPlugin,
//     interactionPlugin
// ]);

@NgModule({
  imports: [
    CoreModule,
    AuthModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    VirtualScrollerModule,
    NgxPermissionsModule.forRoot(),
    SharedModule,
    IssueTypesModule,
    IssuesModule,
    RemediationPlanModule,
    RiskMethodologyModule,
    EventsModule,
    ObjectivesModule,
    EditorModule,
  ],
  declarations: [
    AppComponent,
    AppBreadcrumbComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppConfigComponent,
    AppRightMenuComponent,
    AppInlineMenuComponent,
    AppTopbarComponent,
    AppFooterComponent,
    EmptyDemoComponent,
    FormDemoComponent,
    AppLoginComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppWelcomeComponent,
    AppAccessdeniedComponent,
    DashboardComponent,
  ],
  providers: [
    MenuService,
    AppBreadcrumbService,
    ConfigService,
    MessageService,
    ConfirmationService,
    DialogService,
    TreeDragDropService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    // { provide: RTL, useValue: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
