import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DynamicFormComponentDirective } from '@shared/directives/dynamic-form-component.directive';
import { DynamicViewComponentDirective } from '@shared/directives/dynamic-view-component.directive';
import { GridsterModule } from 'angular-gridster2';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TimeagoModule } from 'ngx-timeago';
import { ChartModule } from 'primeng/chart';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CommentsFormComponent } from './components/components/comments/comments-form/comments-form.component';
import { CommentsComponent } from './components/components/comments/comments.component';
import { PeriodicalWidgetComponent } from './components/components/dashboard-items/custom-widgets/periodical-widget/periodical-widget.component';
import { DashletComponent } from './components/components/dashboard-items/dashlet/dashlet.component';
import { WidgetComponent } from './components/components/dashboard-items/widget/widget.component';
import { FlatRuleHandlerComponent } from './components/components/flat-rule-handler/flat-rule-handler.component';
import { ListStringsComponent } from './components/components/list-strings/list-strings.component';
import { RelationItemFormComponent } from './components/components/relations/relations/relation-item-form/relation-item-form.component';
import { RelationItemPopupComponent } from './components/components/relations/relations/relation-popup/relation-item.component';
import { RelationTabComponent } from './components/components/relations/relations/relationTab/relationTab.component';
import { RelationsComponent } from './components/components/relations/relations/relations.component';
import { AuditTrailComponent } from './components/components/side-bar-audit-trail/audit-trail/audit-trail.component';
import { SideBarAuditTrailComponent } from './components/components/side-bar-audit-trail/side-bar-audit-trail.component';
import { SideBarCommentsComponent } from './components/components/side-bar-comments/side-bar-comments.component';
import { HistoryPanelComponent } from './components/components/side-bar-history/history-panel/history-panel.component';
import { HistoryTabsComponent } from './components/components/side-bar-history/history-tabs/history-tabs.component';
import { SideBarHistoryComponent } from './components/components/side-bar-history/side-bar-history.component';
import { DynamicFormExampleComponent } from './components/examples/dynamic-form-example/dynamic-form-example.component';
import { FormExampleComponent } from './components/examples/form-example/form-example.component';
import { TableExampleComponent } from './components/examples/table-example/table-example.component';
import { BaseFormPopupComponent } from './components/misc/base-form-popup/base-form-popup.component';
import { CustomCardViewComponent } from './components/misc/custom-card-view/custom-card-view.component';
import { DynamicTypeOptionsFormPopupComponent } from './components/misc/dynamic-type-options-form-popup/dynamic-type-options-form-popup.component';
import { ExportDataFormComponent } from './components/misc/export-data-form/export-data-form.component';
import { ExportSelectedDataFormComponent } from './components/misc/export-selected-data-form/export-selected-data-form.component';
import { ImporterColumnBindPopupComponent } from './components/misc/importer-column-bind-popup/importer-column-bind-popup.component';
import { ImporterColumnDefinitionOverlayComponent } from './components/misc/importer-column-bind-popup/importer-column-definition-overlay/importer-column-definition-overlay.component';
import { LockedItemComponent } from './components/misc/locked-item/locked-item.component';
import { TreeModeSwitchComponent } from './components/misc/tree-mode-switch/tree-mode-switch.component';
import { ViewModeSwitchComponent } from './components/misc/view-mode-switch/view-mode-switch.component';
import { CheckItemsSelectorComponent } from './components/selectors/check-items-selector/check-items-selector.component';
import { ControlObjectiveSelectorComponent } from './components/selectors/control-objective-selector/control-objective-selector.component';
import { ControlSelectorComponent } from './components/selectors/control-selector/control-selector.component';
import { DynamicFieldTypeSelectorComponent } from './components/selectors/dynamic-field-type-selector/dynamic-field-type-selector.component';
import { EntityCategoryTreeSelectorComponent } from './components/selectors/entity-category-tree-selector/entity-category-tree-selector.component';
import { EntityOrgChartSelectorComponent } from './components/selectors/entity-org-chart-selector/entity-org-chart-selector.component';
import { EntitySelectorByLevelComponent } from './components/selectors/entity-selector-by-level/entity-selector-by-level.component';
import { EntitySelectorComponent } from './components/selectors/entity-selector/entity-selector.component';
import { EntityTreeSelectorComponent } from './components/selectors/entity-tree-selector/entity-tree-selector.component';
import { EntityTypeSelectorComponent } from './components/selectors/entity-type-selector/entity-type-selector.component';
import { FolderSelectorComponent } from './components/selectors/folder-selector/folder-selector.component';
import { GroupSelectorComponent } from './components/selectors/group-selector/group-selector.component';
import { IconSelectorComponent } from './components/selectors/icon-selector/icon-selector.component';
import { IssueSelectorComponent } from './components/selectors/issue-selector/issue-selector.component';
import { IssueTypeSelectorComponent } from './components/selectors/issue-type-selector/issue-type-selector.component';
import { PolicySelectorComponent } from './components/selectors/policy-selector/policy-selector.component';
import { QuestionnaireSelectorComponent } from './components/selectors/questionnaire-selector/questionnaire-selector.component';
import { EntityFilterPopupComponent } from './components/selectors/risk-entities-selector/entity-filter-popup/entity-filter-popup.component';
import { RiskEntitiesSelectorComponent } from './components/selectors/risk-entities-selector/risk-entities-selector.component';
import { RiskProjectSelectorComponent } from './components/selectors/risk-project-selector/risk-project-selector.component';
import { RiskStatementCategorySelectorComponent } from './components/selectors/risk-statement-category-selector/risk-statement-category-selector.component';
import { RiskStatementSelectorComponent } from './components/selectors/risk-statement-selector/risk-statement-selector.component';
import { RoleSelectorComponent } from './components/selectors/role-selector/role-selector.component';
import { TargetCodeSelectorComponent } from './components/selectors/target-code-selector/target-code-selector.component';
import { UserSelectorComponent } from './components/selectors/user-selector/user-selector.component';
import { DateFilterComponent } from './components/table-filters/date-filter/date-filter.component';
import { DefaultFilterComponent } from './components/table-filters/default-filter/default-filter.component';
import { EnumFilterComponent } from './components/table-filters/enum-filter/enum-filter.component';
import { FilterSwitchComponent } from './components/table-filters/filter-switch/filter-switch.component';
import { SelectorFilterComponent } from './components/table-filters/selector-filter/selector-filter.component';
import { AvatarOverlayListComponent } from './components/ui/avatar-list-overlay/avatar-overlay-list.component';
import { AvatarListComponent } from './components/ui/avatar-list/avatar-list.component';
import { BadgeItemComponent } from './components/ui/badge-item/badge-item.component';
import { ButtonListComponent } from './components/ui/button-list/button-list.component';
import { ButtonComponent } from './components/ui/button/button.component';
import { FormRepeaterComponent } from './components/ui/form-repeater/form-repeater.component';
import { AttachmentInputComponent } from './components/ui/input-fields/attachment-input/attachment-input.component';
import { BasicInputComponent } from './components/ui/input-fields/basic-input/basic-input.component';
import { CheckBoxComponent } from './components/ui/input-fields/check-box/check-box.component';
import { ColorInputComponent } from './components/ui/input-fields/color-input/color-input.component';
import { IpInputComponent } from './components/ui/input-fields/customised-fields/ip-input/ip-input.component';
import { IP6InputComponent } from './components/ui/input-fields/customised-fields/ip6-input/ip6-input.component';
import { MacInputComponent } from './components/ui/input-fields/customised-fields/mac-input/mac-input.component';
import { DateInputComponent } from './components/ui/input-fields/date-input/date-input.component';
import { DropDownInputComponent } from './components/ui/input-fields/drop-down-input/drop-down-input.component';
import { DynamicFieldListArrayInputComponent } from './components/ui/input-fields/dynamic-field-list-array-input/dynamic-field-list-array-input.component';
import { DynamicFieldListInputComponent } from './components/ui/input-fields/dynamic-field-list-input/dynamic-field-list-input.component';
import { DynamicFieldTypeOptionsComponent } from './components/ui/input-fields/dynamic-field-type-options/dynamic-field-type-options.component';
import { MaskedInputComponent } from './components/ui/input-fields/masked-input/masked-input.component';
import { MCQComponent } from './components/ui/input-fields/mcq/mcq.component';
import { NumberInputComponent } from './components/ui/input-fields/number-input/number-input.component';
import { PSliderInputComponent } from './components/ui/input-fields/p-slider-input/p-slider-input.component';
import { RadioGroupComponent } from './components/ui/input-fields/radio-group/radio-group.component';
import { RatingInputComponent } from './components/ui/input-fields/rating-input/rating-input.component';
import { SliderInputComponent } from './components/ui/input-fields/slider-input/slider-input.component';
import { SwitchInputComponent } from './components/ui/input-fields/switch-input/switch-input.component';
import { TextEditorComponent } from './components/ui/input-fields/text-editor/text-editor.component';
import { TextInputComponent } from './components/ui/input-fields/text-input/text-input.component';
import { InputViewSwitchComponent } from './components/ui/input-view-switch/input-view-switch.component';
import { AvatarResponsibilityListComponent } from './components/ui/responsibility-avatar-list/responsibility-avatar-list.component';
import { ChipViewComponent } from './components/ui/view-fields/Chip-view/Chip-view.component';
import { BadgeViewComponent } from './components/ui/view-fields/badge-view/badge-view.component';
import { CodeWithLockStatusViewComponent } from './components/ui/view-fields/code-with-lock-status-view/code-with-lock-status-view.component';
import { ColorViewComponent } from './components/ui/view-fields/color-view/color-view.component';
import { CountDownViewComponent } from './components/ui/view-fields/count-down-view/count-down-view.component';
import { DateViewComponent } from './components/ui/view-fields/date-view/date-view.component';
import { HtmlTextViewComponent } from './components/ui/view-fields/html-text-view/html-text-view.component';
import { HtmlTextShortenViewComponent } from './components/ui/view-fields/htmlTextShortenWithHoverViewComponent/htmlTextShortenWithHoverViewComponent.component';
import { HyperTextViewComponent } from './components/ui/view-fields/hyper-text-view/hyper-text-view.component';
import { NumberViewComponent } from './components/ui/view-fields/number-view/number-view.component';
import { ProgressbarViewComponent } from './components/ui/view-fields/progressbar/progressbar-view.component';
import { SliderViewComponent } from './components/ui/view-fields/slider-view/slider-view.component';
import { StackedProgressBarViewComponent } from './components/ui/view-fields/stacked-progress-bar-view/stacked-progress-bar-view.component';
import { TextViewComponent } from './components/ui/view-fields/text-view/text-view.component';
import { UserListViewComponent } from './components/ui/view-fields/user-list-view/user-list-view.component';
import { ViewSwitchComponent } from './components/ui/view-fields/view-switch/view-switch.component';
import { DynamicComponentDirective } from './directives/dynamic-component.directive';
import { PrimengComponentsModule } from './modules/primeng-components.module';
import { BasePageItemComponent } from './pages/base-page-item/base-page-item.component';
import { BasePageComponent } from './pages/base-page/base-page.component';
import { ImportDataPageComponent } from './pages/import-data-page/import-data-page.component';
import { tableComponent } from './pages/table/table.component';
import { HtmlToPlaintextPipePipe } from './pipes/HtmlToPlaintextPipe.pipe';
import { GetButtonClassPipe } from './pipes/get-button-class.pipe';
import { GetPercentageColorPipe } from './pipes/get-percentage-color.pipe';
import { HideOrDisplayActionPipe } from './pipes/hide-or-display-action.pipe';
import { ObjToKeysPipe } from './pipes/obj-to-keys.pipe';
import { ParseToFixedNumberPipe } from './pipes/parse-to-fixed-number.pipe';
import { ShortenPipe } from './pipes/shorten.pipe';
import { SplitStringPipe } from './pipes/split-string.pipe';
import { ToDateObjPipe } from './pipes/to-date-obj.pipe';
import { ToFixedDigitsPipe } from './pipes/to-fixed-digits.pipe';
// import { IconPickerModule } from "ngx-icon-picker";
import { AddWatchersFormComponent } from './components/components/notifications/add-watchers-form/add-watchers-form.component';
import { NotificationItemComponent } from './components/components/notifications/notification-item/notification-item.component';
import { NotificationsListComponent } from './components/components/notifications/notifications-list/notifications-list.component';
import { NotificationsTrayComponent } from './components/components/notifications/notifications-tray/notifications-tray.component';
import { WatchingTrayComponent } from './components/components/notifications/watching-tray/watching-tray.component';
import { DropDownBadgeComponent } from './components/misc/drop-down-badge/drop-down-badge.component';
import { FieldPopupFormComponent } from './components/selectors/fields-table-selector/field-popup-form/field-popup-form.component';
import { FieldsTableSelectorComponent } from './components/selectors/fields-table-selector/fields-table-selector.component';
import { RecordCodeFilterComponent } from './components/table-filters/record-code-filter/record-code-filter.component';
import { AutoCompleteInputComponent } from './components/ui/input-fields/auto-complete-input/auto-complete-input.component';
import { DynamicFieldFormListInputComponent } from './components/ui/input-fields/dynamic-field-form-list-input/dynamic-field-form-list-input.component';
import { SuggestionInputComponent } from './components/ui/input-fields/suggestion-input/suggestion-input.component';
import { CodeNavigationComponent } from './components/ui/view-fields/code-navigation/code-navigation.component';
import { GetEnumIconPipe } from './pipes/get-enum-icon.pipe';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditorModule } from '@progress/kendo-angular-editor';
import { CheckItemFormComponent } from 'app/modules/org-framework/check-item/check-item-form/check-item-form.component';
import { NgxColorsModule } from 'ngx-colors';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxEchartsModule } from 'ngx-echarts';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { MeterGroupModule } from 'primeng/metergroup';
import { SideBarStateMachineAuditComponent } from './components/components/app-side-bar-state-machine-audit/side-bar-comments.component';
import { AssessmentFieldPopupComponent } from './components/components/assessment-components/assessment-field-popup/assessment-field-popup.component';
import { AssessmentFieldComponent } from './components/components/assessment-components/assessment-field/assessment-field.component';
import { BulkOperationStepperComponent } from './components/components/bulk-operation/bulk-operation-stepper/bulk-operation-stepper.component';
import { BulkOperationChoiceStepFormComponent } from './components/components/bulk-operation/steps-forms/bulk-operation-choice-step-form/bulk-operation-choice-step-form.component';
import { BulkOperationConfirmationStepFormComponent } from './components/components/bulk-operation/steps-forms/bulk-operation-confirmation-step-form/bulk-operation-confirmation-step-form.component';
import { BulkOperationDetailsStepFormComponent } from './components/components/bulk-operation/steps-forms/bulk-operation-details-step-form/bulk-operation-details-step-form.component';
import { BulkOperationResultsStepFormComponent } from './components/components/bulk-operation/steps-forms/bulk-operation-results-step-form/bulk-operation-results-step-form.component';
import { BulkOperationChoiceStepComponent } from './components/components/bulk-operation/steps/bulk-operation-choice-step/bulk-operation-choice-step.component';
import { BulkOperationConfirmationStepComponent } from './components/components/bulk-operation/steps/bulk-operation-confirmation-step/bulk-operation-confirmation-step.component';
import { BulkOperationDetailsStepComponent } from './components/components/bulk-operation/steps/bulk-operation-details-step/bulk-operation-details-step.component';
import { BulkOperationResultsStepComponent } from './components/components/bulk-operation/steps/bulk-operation-results-step/bulk-operation-results-step.component';
import { ConfigItemFormComponent } from './components/components/config-item-form/config-item-form.component';
import { ControlObjectiveAddLinkPopupComponent } from './components/components/control-objective-components/control-objective-add-link-popup/control-objective-add-link-popup.component';
import { ControlObjectiveMatcherComponent } from './components/components/control-objective-components/control-objective-matcher/control-objective-matcher.component';
import { ControlObjectivePopupConfirmationComponent } from './components/components/control-objective-components/control-objective-popup-confirmation/control-objective-popup-confirmation.component';
import { DashletDoughnutChartComponent } from './components/components/dashboard-items/dashlet-view/dashlet-doughnut-chart/dashlet-doughnut-chart.component';
import { DashletNotificationComponent } from './components/components/dashboard-items/dashlet-view/dashlet-notification/dashlet-notification.component';
import { DashletRiskMethodologyComponent } from './components/components/dashboard-items/dashlet-view/dashlet-risk-methodology/dashlet-risk-methodology.component';
import { DashletTableComponent } from './components/components/dashboard-items/dashlet-view/dashlet-table/dashlet-table.component';
import { DashletTextComponent } from './components/components/dashboard-items/dashlet-view/dashlet-text/dashlet-text.component';
import { DashletTimelineComponent } from './components/components/dashboard-items/dashlet-view/dashlet-timeline/dashlet-timeline.component';
import { DashletViewSwitchComponent } from './components/components/dashboard-items/dashlet-view/dashlet-view-switch/dashlet-view-switch.component';
import { StatisticsTableComponent } from './components/components/dashboard-items/dashlet-view/widget-chart/statistics-table/statistics-table.component';
import { WidgetChartComponent } from './components/components/dashboard-items/dashlet-view/widget-chart/widget-chart.component';
import { WidgetCountComponent } from './components/components/dashboard-items/dashlet-view/widget-count/widget-count.component';
import { WidgetNotificationsComponent } from './components/components/dashboard-items/dashlet-view/widget-custom/components/widget-notifications/widget-notifications.component';
import { WidgetRiskMapComponent } from './components/components/dashboard-items/dashlet-view/widget-custom/components/widget-risk-map/widget-risk-map.component';
import { WidgetTimelineComponent } from './components/components/dashboard-items/dashlet-view/widget-custom/components/widget-timeline/widget-timeline.component';
import { WidgetCustomComponent } from './components/components/dashboard-items/dashlet-view/widget-custom/widget-custom.component';
import { WidgetDynamicFormComponent } from './components/components/dashboard-items/dashlet-view/widget-dynamic-form/widget-dynamic-form.component';
import { WidgetLinksComponent } from './components/components/dashboard-items/dashlet-view/widget-links/widget-links.component';
import { WidgetStatisticChartComponent } from './components/components/dashboard-items/dashlet-view/widget-statistic-chart/widget-statistic-chart.component';
import { WidgetTableComponent } from './components/components/dashboard-items/dashlet-view/widget-table/widget-table.component';
import { WidgetSwitchComponent } from './components/components/dashboard-items/widget-switch/widget-switch.component';
import { DynamicFormViewComponent } from './components/components/dynamic-form-view/dynamic-form-view.component';
import { GlobalSearchButtonComponent } from './components/components/global-search/global-search-button/global-search-button.component';
import { GlobalSearchPopupComponent } from './components/components/global-search/global-search-popup/global-search-popup.component';
import { ItemShareFormComponent } from './components/components/item-share/item-share-form/item-share-form.component';
import { ItemShareTrayComponent } from './components/components/item-share/item-share-tray/item-share-tray.component';
import { AddWatchersPopupFormComponent } from './components/components/notifications/add-watchers-popup-form/add-watchers-popup-form.component';
import { NotificationMiniItemComponent } from './components/components/notifications/notification-mini-item/notification-mini-item.component';
import { OrgChartComponent } from './components/components/org-chart/org-chart.component';
import { RiskMeterBarComponent } from './components/components/risk-meter-bar/risk-meter-bar.component';
import { InheritedRiskValueFieldPopupComponent } from './components/components/risks/inherited-risk-value-field-popup/inherited-risk-value-field-popup.component';
import { InheritedRiskValueFieldComponent } from './components/components/risks/inherited-risk-value-field/inherited-risk-value-field.component';
import { InheritedRiskValueFormComponent } from './components/components/risks/inherited-risk-value-form/inherited-risk-value-form.component';
import { SearchSelectComponent } from './components/components/search-select/search-select.component';
import { SettingsButtonListComponent } from './components/components/settings-button-list/settings-button-list.component';
import { AttachmentsTrailComponent } from './components/components/side-bar-attachments/attachments-trail/attachments-trail.component';
import { SideBarAttachmentsComponent } from './components/components/side-bar-attachments/side-bar-attachments.component';
import { UserPreferenceItemFormComponent } from './components/components/user-preference/user-preference-item-form/user-preference-item-form.component';
import { UserPreferenceItemComponent } from './components/components/user-preference/user-preference-item/user-preference-item.component';
import { ExportPackageFormComponent } from './components/misc/export-package-form/export-package-form.component';
import { ImportPackageFormComponent } from './components/misc/import-package-form/import-package-form.component';
import { ImporterColumnDefinitionValidatorInfoOverlayComponent } from './components/misc/importer-column-bind-popup/importer-column-definition-validator-info-overlay/importer-column-definition-validator-info-overlay.component';
import { ModuleCardViewComponent } from './components/misc/module-card-view/module-card-view.component';
import { ModulePermissionsCardViewComponent } from './components/misc/module-permissions-card-view/module-permissions-card-view.component';
import { FilterBuilderComponent } from './components/misc/query-builder/filter-builder/filter-builder.component';
import { SortRowInputComponent } from './components/misc/query-builder/sort-row-input/sort-row-input.component';
import { QuestionnaireAnswersFormComponent } from './components/misc/questionnaire-answers-form/questionnaire-answers-form.component';
import { SkeletonLoaderPlaceholderComponent } from './components/misc/skeleton-loader-placeholder/skeleton-loader-placeholder.component';
import { TagsEditPopupFormComponent } from './components/misc/tags-edit-popup-form/tags-edit-popup-form.component';
import { DefaultAccessControlSelectorComponent } from './components/selectors/default-access-control-selector/default-access-control-selector.component';
import { DefaultAccessSelectorComponent } from './components/selectors/default-access-selector/default-access-selector.component';
import { FolderBucketFormComponent } from './components/selectors/folder-bucket-tree-selector/folder-bucket-form/folder-bucket-form.component';
import { FolderBucketTreeSelectorComponent } from './components/selectors/folder-bucket-tree-selector/folder-bucket-tree-selector.component';
import { GlobalValueListSuggestionsSelectorComponent } from './components/selectors/global-value-list-suggestions-selector/global-value-list-suggestions-selector.component';
import { GvlSelectorComponent } from './components/selectors/gvl-selector/gvl-selector.component';
import { ImpactFactorWithValueSelectorComponent } from './components/selectors/impact-factor-with-value-selector/impact-factor-with-value-selector.component';
import { RolePicklistSelectorComponent } from './components/selectors/role-picklist-selector/role-picklist-selector.component';
import { TableCodeSelectorComponent } from './components/selectors/table-code-selector/table-code-selector.component';
import { TargetCodeTreeSelectorComponent } from './components/selectors/target-code-tree-selector/target-code-tree-selector.component';
import { UserAndGroupPicklistSelectorComponent } from './components/selectors/user-and-group-picklist-selector/user-and-group-picklist-selector.component';
import { UserAndGroupSelectorComponent } from './components/selectors/user-and-group-selector/user-and-group-selector.component';
import { UserAndGroupTreeSelectorComponent } from './components/selectors/user-and-group-tree-selector/user-and-group-tree-selector.component';
import { UserPicklistSelectorComponent } from './components/selectors/user-picklist-selector/user-picklist-selector.component';
import { BooleanFilterComponent } from './components/table-filters/boolean-filter/boolean-filter.component';
import { RelationFormFilterControlComponent } from './components/table-filters/relation-form-filter/relation-form-filter-control/relation-form-filter-control.component';
import { RelationFormFilterComponent } from './components/table-filters/relation-form-filter/relation-form-filter.component';
import { TableRowGroupSwitchComponent } from './components/table-filters/table-row-group-switch/table-row-group-switch.component';
import { TagsSelectorFilterComponent } from './components/table-filters/tags-selector-filter/tags-selector-filter.component';
import { UserAndGroupFilterComponent } from './components/table-filters/user-and-group-filter/user-and-group-filter.component';
import { UserSelectorFilterComponent } from './components/table-filters/user-selector-filter/user-selector-filter.component';
import { AttachmentItemListViewComponent } from './components/ui/attachment-item-list-view/attachment-item-list-view.component';
import { AttachmentItemViewComponent } from './components/ui/attachment-item-view/attachment-item-view.component';
import { AuditTrailCardComponent } from './components/ui/audit-trail-card/audit-trail-card.component';
import { AvatarInfoCardItemComponent } from './components/ui/avatar-info-card-tray/avatar-info-card-item/avatar-info-card-item.component';
import { AvatarInfoCardListComponent } from './components/ui/avatar-info-card-tray/avatar-info-card-list/avatar-info-card-list.component';
import { AvatarInfoCardTrayComponent } from './components/ui/avatar-info-card-tray/avatar-info-card-tray.component';
import { CircleBadgeItemComponent } from './components/ui/circle-badge-item/circle-badge-item.component';
import { AudioViewerComponent } from './components/ui/file-viewer/audio-viewer/audio-viewer.component';
import { FileViewerPopupComponent } from './components/ui/file-viewer/file-viewer-popup/file-viewer-popup.component';
import { FileViewerSwitchComponent } from './components/ui/file-viewer/file-viewer-switch/file-viewer-switch.component';
import { ImageViewerComponent } from './components/ui/file-viewer/image-viewer/image-viewer.component';
import { PdfViewerComponent } from './components/ui/file-viewer/pdf-viewer/pdf-viewer.component';
import { TextViewerComponent } from './components/ui/file-viewer/text-viewer/text-viewer.component';
import { VideoViewerComponent } from './components/ui/file-viewer/video-viewer/video-viewer.component';
import { InfoCardComponent } from './components/ui/info-card/info-card.component';
import { CheckboxListComponent } from './components/ui/input-fields/checkbox-list/checkbox-list.component';
import { ChipsInputComponent } from './components/ui/input-fields/chips-input/chips-input.component';
import { Ipv4MaskInputComponent } from './components/ui/input-fields/customised-fields/ipv4-mask-input/ipv4-mask-input.component';
import { DynamicFieldListInputCustomComponent } from './components/ui/input-fields/dynamic-field-list-input-custom/dynamic-field-list-input-custom.component';
import { ImageInputComponent } from './components/ui/input-fields/image-input/image-input.component';
import { IpMacInputComponent } from './components/ui/input-fields/ip-mac-input/ip-mac-input.component';
import { KendoEditorComponent } from './components/ui/input-fields/kendo-editor/kendo-editor.component';
import { MonacoEditorInputComponent } from './components/ui/input-fields/monaco-editor-input/monaco-editor-input.component';
import { PalettePickerInputComponent } from './components/ui/input-fields/palette-picker-input/palette-picker-input.component';
import { PickListInputComponent } from './components/ui/input-fields/pick-list-input/pick-list-input.component';
import { PlannedProgressBarInputComponent } from './components/ui/input-fields/planned-progress-bar-input/planned-progress-bar-input.component';
import { ResetSwitchInputComponent } from './components/ui/input-fields/reset-switch-input/reset-switch-input.component';
import { SingleChildTreeSelectInputComponent } from './components/ui/input-fields/single-child-tree-select-input/single-child-tree-select-input.component';
// import { SunEditorComponent } from './components/ui/input-fields/sun-editor/sun-editor.component';
import { TagSearchInputComponent } from './components/ui/input-fields/tag-search-input/tag-search-input.component';
import { TimeInputComponent } from './components/ui/input-fields/time-input/time-input.component';
import { ToggleInputComponent } from './components/ui/input-fields/toggle-input/toggle-input.component';
import { AuditCaseInputItemFormControlComponent } from './components/ui/input-fields/wrapped-components/audit-case-input-item/audit-case-input-item-form-control/audit-case-input-item-form-control.component';
import { AuditCaseInputItemFormComponent } from './components/ui/input-fields/wrapped-components/audit-case-input-item/audit-case-input-item-form/audit-case-input-item-form.component';
import { MailToInputFormControlComponent } from './components/ui/input-fields/wrapped-components/mail-to/mail-to-input-form-control/mail-to-input-form-control.component';
import { MailToInputComponent } from './components/ui/input-fields/wrapped-components/mail-to/mail-to-input/mail-to-input.component';
import { RunAsInputFormControlComponent } from './components/ui/input-fields/wrapped-components/run-as-input/run-as-input-form-control/run-as-input-form-control.component';
import { RunAsInputComponent } from './components/ui/input-fields/wrapped-components/run-as-input/run-as-input/run-as-input.component';
import { WrappedNumberInputComponent } from './components/ui/input-fields/wrapped-components/wrapped-number-input/wrapped-number-input.component';
import { LazyFormRepeaterComponent } from './components/ui/lazy-form-repeater/lazy-form-repeater.component';
import { LoadingCardComponent } from './components/ui/loading-card/loading-card.component';
import { MultiNetworkInputComponent } from './components/ui/multi-network-input/multi-network-input.component';
import { ResponsibilityOneAllModeComponent } from './components/ui/responsibility-one-all-mode/responsibility-one-all-mode.component';
import { TabMenuComponent } from './components/ui/tab-menu/tab-menu.component';
import { TimelineViewComponent } from './components/ui/timeline-view/timeline-view.component';
import { AclViewComponent } from './components/ui/view-fields/acl-view/acl-view.component';
import { AvatarInfoTrayViewComponent } from './components/ui/view-fields/avatar-info-tray-view/avatar-info-tray-view.component';
import { CurrencyViewComponent } from './components/ui/view-fields/currency-view/currency-view.component';
import { DynamicObjectViewerComponent } from './components/ui/view-fields/dynamic-object-viewer/dynamic-object-viewer.component';
import { DynamicsViewComponent } from './components/ui/view-fields/dynamics-view/dynamics-view.component';
import { EventActionViewComponent } from './components/ui/view-fields/event-action-view/event-action-view.component';
import { GvlItemListViewComponent } from './components/ui/view-fields/gvl-item-list-view/gvl-item-list-view.component';
import { GvlItemViewComponent } from './components/ui/view-fields/gvl-item-view/gvl-item-view.component';
import { HumanizedTextViewComponent } from './components/ui/view-fields/humanized-text-view/humanized-text-view.component';
import { ImageViewComponent } from './components/ui/view-fields/image-view/image-view.component';
import { KnobProgressViewComponent } from './components/ui/view-fields/knob-progress-view/knob-progress-view.component';
import { LinkViewComponent } from './components/ui/view-fields/link-view/link-view.component';
import { MeterGroupViewComponent } from './components/ui/view-fields/meter-group-view/meter-group-view.component';
import { MinMaxAvgMeterViewComponent } from './components/ui/view-fields/min-max-avg-meter-view/min-max-avg-meter-view.component';
import { PaletteViewComponent } from './components/ui/view-fields/palette-view/palette-view.component';
import { PlannedProgressBarViewComponent } from './components/ui/view-fields/planned-progress-bar-view/planned-progress-bar-view.component';
import { PrincipleListViewComponent } from './components/ui/view-fields/principle-list-view/principle-list-view.component';
import { RelationNicemodeViewComponent } from './components/ui/view-fields/relation-nicemode-view/relation-nicemode-view.component';
import { RelationTypeCellComponent } from './components/ui/view-fields/relation-type-cell/relation-type-cell.component';
import { RiskItemCellComponent } from './components/ui/view-fields/risk-item-cell/risk-item-cell.component';
import { RiskMeterViewComponent } from './components/ui/view-fields/risk-meter-view/risk-meter-view.component';
// import { SunEditorViewComponent } from './components/ui/view-fields/sun-editor-view/sun-editor-view.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { UploadModule, UploadsModule } from '@progress/kendo-angular-upload';
import { FieldItemTypeFormComponent } from 'app/modules/data-structure-module/fields/field-item-form-for-type/field-item-form.component';
import { FieldTranslationsButtonComponent } from './components/components/side-bar-languages/field-translations-button/field-translations-button.component';
import { FieldTranslationsComponent } from './components/components/side-bar-languages/field-translations/field-translations.component';
import { LanguagesPopupFormComponent } from './components/components/side-bar-languages/languages-popup-form/languages-popup-form.component';
import { LanguagesTrailComponent } from './components/components/side-bar-languages/languages-trail/languages-trail.component';
import { SideBarLanguagesComponent } from './components/components/side-bar-languages/side-bar-languages.component';
import { LanguageSelectorComponent } from './components/selectors/language-selector/language-selector.component';
import { ColorFilterComponent } from './components/table-filters/color-filter/color-filter.component';
import { NumberFilterComponent } from './components/table-filters/number-filter/number-filter.component';
import { TextEditorDialogComponent } from './components/ui/input-fields/kendo-editor/dialog.component';
import { TextEditorUploadComponent } from './components/ui/input-fields/kendo-editor/upload.component';
import { TimelineContainerComponent } from './components/ui/timeline-container/timeline-container.component';
import { GenericProgressBarViewComponent } from './components/ui/view-fields/generic-progress-bar-view/generic-progress-bar-view.component';
import { LanguageCodeViewComponent } from './components/ui/view-fields/language-code-view/language-code-view.component';
import { TagSearchViewComponent } from './components/ui/view-fields/tag-search-view/tag-search-view.component';
import { TargetCodeListBoxViewComponent } from './components/ui/view-fields/target-code-list-box-view/target-code-list-box-view.component';
import { TimeViewComponent } from './components/ui/view-fields/time-view/time-view.component';
import { appMaxCharacterValidator } from './directives/max-character-validator.directive';
import { AppPathRedirectComponent } from './pages/app-path-redirect/app-path-redirect.component';
import { GlobalSearchPageComponent } from './pages/global-search-page/global-search-page.component';
import { CustomPagePopupComponent } from './pages/table/custom-page-popup/custom-page-popup.component';
import { UnlinkingBasePageComponent } from './pages/unlinking-base-page/unlinking-base-page.component';
import { HighlightPipe } from './pipes/HighlightPipe';
import { DaysTimeAgoPipe } from './pipes/days-time-ago.pipe';
import { HumanizeFileSizePipe } from './pipes/humanize-file-size.pipe';
import { HumanizePipe } from './pipes/humanize.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { TranslateFieldPipe } from './pipes/translate-field.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { BaseFormPopupAltComponent } from './components/misc/base-form-popup-alt/base-form-popup-alt.component';

const SHARED_COMPONENTS = [
  BasePageComponent,
  tableComponent,
  ObjToKeysPipe,
  ShortenPipe,
  BasicInputComponent,
  MCQComponent,
  CheckBoxComponent,
  TextInputComponent,
  TextEditorComponent,
  BadgeViewComponent,
  DateViewComponent,
  HtmlTextViewComponent,
  ViewSwitchComponent,
  SwitchInputComponent,
  RadioGroupComponent,
  NumberInputComponent,
  DateInputComponent,
  DropDownInputComponent,
  FormExampleComponent,
  ListStringsComponent,
  BadgeItemComponent,
  NumberViewComponent,
  TextViewComponent,
  ParseToFixedNumberPipe,
  ButtonComponent,
  RelationsComponent,
  GetButtonClassPipe,
  HideOrDisplayActionPipe,
  ButtonListComponent,
  TableExampleComponent,
  DynamicComponentDirective,
  ColorInputComponent,
  SliderViewComponent,
  SliderInputComponent,
  UserSelectorComponent,
  GroupSelectorComponent,
  DynamicFormExampleComponent,
  SelectorFilterComponent,
  DefaultFilterComponent,
  FilterSwitchComponent,
  EnumFilterComponent,
  AvatarListComponent,
  AvatarOverlayListComponent,
  AvatarResponsibilityListComponent,
  ResponsibilityOneAllModeComponent,
  UserListViewComponent,
  EntitySelectorComponent,
  ExportDataFormComponent,
  EntityTypeSelectorComponent,
  EntityTreeSelectorComponent,
  EntityCategoryTreeSelectorComponent,
  FormRepeaterComponent,
  EntitySelectorByLevelComponent,
  RelationTabComponent,
  RelationItemFormComponent,
  RelationItemPopupComponent,
  BasePageItemComponent,
  HtmlTextShortenViewComponent,
  ChipViewComponent,
  ColorViewComponent,
  HtmlToPlaintextPipePipe,
  HighlightPipe,
  ExportSelectedDataFormComponent,
  SplitStringPipe,
  InputViewSwitchComponent,
  RiskStatementSelectorComponent,
  RiskStatementCategorySelectorComponent,
  RiskProjectSelectorComponent,
  QuestionnaireSelectorComponent,
  RiskEntitiesSelectorComponent,
  EntityFilterPopupComponent,
  ToDateObjPipe,
  // CitationSelectorComponent,
  // CitationTypeSelectorComponent,
  // AuthoritySelectorComponent,
  CommentsComponent,
  SideBarCommentsComponent,
  SideBarAuditTrailComponent,
  AuditTrailComponent,
  CommentsFormComponent,
  HyperTextViewComponent,
  RoleSelectorComponent,
  BaseFormPopupComponent,
  DynamicViewComponentDirective,
  appMaxCharacterValidator,
  DynamicFormComponentDirective,
  DynamicFieldListInputComponent,
  DynamicFieldListInputCustomComponent,
  CustomCardViewComponent,
  EntityOrgChartSelectorComponent,
  IconSelectorComponent,
  ControlObjectiveSelectorComponent,
  PolicySelectorComponent,
  SideBarHistoryComponent,
  HistoryPanelComponent,
  HistoryTabsComponent,
  FlatRuleHandlerComponent,
  CheckItemsSelectorComponent,
  IssueSelectorComponent,
  IssueTypeSelectorComponent,
  ImporterColumnBindPopupComponent,
  GetPercentageColorPipe,
  IssueTypeSelectorComponent,
  ProgressbarViewComponent,
  ImporterColumnDefinitionOverlayComponent,
  TargetCodeSelectorComponent,
  PSliderInputComponent,
  StackedProgressBarViewComponent,
  ToFixedDigitsPipe,
  MaskedInputComponent,
  RatingInputComponent,
  DynamicFieldTypeSelectorComponent,
  AttachmentInputComponent,
  DynamicFieldTypeOptionsComponent,
  IP6InputComponent,
  IpInputComponent,
  MacInputComponent,
  DynamicTypeOptionsFormPopupComponent,
  FolderSelectorComponent,
  ImportDataPageComponent,
  CountDownViewComponent,
  LockedItemComponent,
  DateFilterComponent,
  DynamicFieldListArrayInputComponent,
  ViewModeSwitchComponent,
  TreeModeSwitchComponent,
  CodeWithLockStatusViewComponent,
  DashletComponent,
  WidgetComponent,
  PeriodicalWidgetComponent,
  ControlSelectorComponent,
  CodeNavigationComponent,
  GetEnumIconPipe,
  NotificationsTrayComponent,
  NotificationItemComponent,
  NotificationsListComponent,
  RecordCodeFilterComponent,
  DropDownBadgeComponent,
  WatchingTrayComponent,
  AddWatchersFormComponent,
  FieldsTableSelectorComponent,
  FieldPopupFormComponent,
  AutoCompleteInputComponent,
  SuggestionInputComponent,
  DynamicFieldFormListInputComponent,
  AvatarInfoCardTrayComponent,
  AvatarInfoCardItemComponent,
  AvatarInfoCardListComponent,
  AvatarInfoTrayViewComponent,
  KnobProgressViewComponent,
  SingleChildTreeSelectInputComponent,
  SearchSelectComponent,
  ImpactFactorWithValueSelectorComponent,
  InheritedRiskValueFormComponent,
  InheritedRiskValueFieldComponent,
  InheritedRiskValueFieldPopupComponent,
  RiskMeterBarComponent,
  RiskMeterViewComponent,
  HumanizePipe,
  DaysTimeAgoPipe,
  ImageInputComponent,
  SideBarStateMachineAuditComponent,
  ImageViewComponent,
  AddWatchersPopupFormComponent,
  TargetCodeTreeSelectorComponent,
  AssessmentFieldPopupComponent,
  AssessmentFieldComponent,
  OrgChartComponent,
  RiskItemCellComponent,
  ImporterColumnDefinitionValidatorInfoOverlayComponent,
  RelationTypeCellComponent,
  BooleanFilterComponent,
  ItemShareTrayComponent,
  ItemShareFormComponent,
  UserAndGroupSelectorComponent,
  UserPicklistSelectorComponent,
  UserSelectorFilterComponent,
  NumberFilterComponent,
  ColorFilterComponent,
  RolePicklistSelectorComponent,
  UserAndGroupTreeSelectorComponent,
  ImportPackageFormComponent,
  ExportPackageFormComponent,
  ModuleCardViewComponent,
  GlobalValueListSuggestionsSelectorComponent,
  TableRowGroupSwitchComponent,
  PluralizePipe,
  AppPathRedirectComponent,
  DefaultAccessSelectorComponent,
  DefaultAccessControlSelectorComponent,
  ModulePermissionsCardViewComponent,
  DashletViewSwitchComponent,
  DashletTableComponent,
  DashletDoughnutChartComponent,
  DashletTextComponent,
  PickListInputComponent,
  AclViewComponent,
  UserAndGroupFilterComponent,
  DynamicsViewComponent,
  DashletRiskMethodologyComponent,
  CircleBadgeItemComponent,
  UserAndGroupPicklistSelectorComponent,
  SkeletonLoaderPlaceholderComponent,
  FolderBucketTreeSelectorComponent,
  IpMacInputComponent,
  SideBarAttachmentsComponent,
  AttachmentsTrailComponent,
  AttachmentItemViewComponent,
  FolderBucketFormComponent,
  DashletNotificationComponent,
  NotificationMiniItemComponent,
  Ipv4MaskInputComponent,
  AttachmentItemListViewComponent,
  HumanizedTextViewComponent,
  MultiNetworkInputComponent,
  InfoCardComponent,
  TimelineViewComponent,
  AuditTrailCardComponent,
  DashletTimelineComponent,
  LoadingCardComponent,
  PrincipleListViewComponent,
  GvlSelectorComponent,
  RelationNicemodeViewComponent,
  GvlItemViewComponent,
  GvlItemListViewComponent,
  MonacoEditorInputComponent,
  CheckboxListComponent,
  HumanizeFileSizePipe,
  WrappedNumberInputComponent,
  TimeInputComponent,
  TimeViewComponent,
  PlannedProgressBarViewComponent,
  PlannedProgressBarInputComponent,
  CurrencyViewComponent,
  AuditCaseInputItemFormComponent,
  AuditCaseInputItemFormControlComponent,
  QuestionnaireAnswersFormComponent,
  // SunEditorComponent,
  BulkOperationChoiceStepFormComponent,
  BulkOperationDetailsStepFormComponent,
  BulkOperationConfirmationStepFormComponent,
  // SunEditorViewComponent,
  BulkOperationResultsStepFormComponent,
  ResetSwitchInputComponent,
  BulkOperationStepperComponent,
  BulkOperationChoiceStepComponent,
  BulkOperationDetailsStepComponent,
  BulkOperationConfirmationStepComponent,
  BulkOperationResultsStepComponent,
  FilterBuilderComponent,
  MeterGroupViewComponent,
  MinMaxAvgMeterViewComponent,
  UserPreferenceItemComponent,
  UserPreferenceItemFormComponent,
  SortRowInputComponent,
  WidgetTableComponent,
  WidgetSwitchComponent,
  WidgetDynamicFormComponent,
  WidgetLinksComponent,
  WidgetCountComponent,
  LinkViewComponent,
  WidgetChartComponent,
  PalettePickerInputComponent,
  PaletteViewComponent,
  DynamicObjectViewerComponent,
  ToggleInputComponent,
  EventActionViewComponent,
  CheckItemFormComponent,
  ControlObjectiveMatcherComponent,
  ControlObjectiveAddLinkPopupComponent,
  ControlObjectivePopupConfirmationComponent,
  UnlinkingBasePageComponent,
  StatisticsTableComponent,
  TagSearchInputComponent,
  DynamicFormViewComponent,
  GlobalSearchButtonComponent,
  GlobalSearchPopupComponent,
  TagSearchViewComponent,
  TableCodeSelectorComponent,
  WidgetStatisticChartComponent,
  TargetCodeListBoxViewComponent,
  WidgetCustomComponent,
  CustomPagePopupComponent,
  WidgetRiskMapComponent,
  WidgetNotificationsComponent,
  WidgetTimelineComponent,
  TagsSelectorFilterComponent,
  TagsEditPopupFormComponent,
  GlobalSearchPageComponent,
  TabMenuComponent,
  LazyFormRepeaterComponent,
  RunAsInputComponent,
  RunAsInputFormControlComponent,
  MailToInputComponent,
  MailToInputFormControlComponent,
  ChipsInputComponent,
  RelationFormFilterComponent,
  RelationFormFilterControlComponent,
  ConfigItemFormComponent,
  SettingsButtonListComponent,
  TranslatePipe,
  FileViewerSwitchComponent,
  ImageViewerComponent,
  PdfViewerComponent,
  VideoViewerComponent,
  AudioViewerComponent,
  TextViewerComponent,
  FileViewerPopupComponent,
  KendoEditorComponent,
  GenericProgressBarViewComponent,
  SideBarLanguagesComponent,
  LanguagesTrailComponent,
  LanguagesPopupFormComponent,
  FieldTranslationsComponent,
  FieldTranslationsButtonComponent,
  LanguageSelectorComponent,
  LanguageCodeViewComponent,
  TranslateFieldPipe,
  TextEditorDialogComponent,
  TextEditorUploadComponent,
  TimelineContainerComponent,
  FieldItemTypeFormComponent,
];
const SHARED_MODULES = [];
const IMPORTED_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  PrimengComponentsModule,
  NgxPermissionsModule,
  ProgressSpinnerModule,
  TimeagoModule.forRoot(),
  MonacoEditorModule.forRoot(),
  GridsterModule,
  MeterGroupModule,
  ChartModule,
  NgxColorsModule,
  LabelModule,
  InputsModule,
  UploadModule,
  UploadsModule,
  DialogsModule,
  //   IconPickerModule,
  ImageCropperModule,
  //   CKEditorModule,
  InfiniteScrollModule,
  DragDropModule,
  NgxEchartsModule.forRoot({
    /**
     * This will import all modules from echarts.
     * If you only need custom modules,
     * please refer to [Custom Build] section.
     */
    echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
  }),
  NgxDocViewerModule,
  EditorModule,
];
@NgModule({
  declarations: [...SHARED_COMPONENTS, BaseFormPopupAltComponent],
  imports: [...IMPORTED_MODULES],
  exports: [...SHARED_MODULES, ...IMPORTED_MODULES, ...SHARED_COMPONENTS],
  //   entryComponents: [
  //     RelationItemPopupComponent,
  //     EntityFilterPopupComponent
  //   ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
  static forChild(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
