<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
  [keepValueCallback]="setFieldResetable.bind(this)"
  [resetFieldCheckVisable]="resetFieldCheckVisable"
  [resetableChecked]="resetable"
>
  <app-target-code-selector
    [label]="'modules.dataManagement.language.fields.langCode.label' | translate: 'Language Code' : langFile"
    [placeholder]="
      'modules.dataManagement.language.fields.langCode.placeholder' | translate: 'Enter Language Code' : langFile
    "
    [control]="control"
    [viewMode]="viewMode"
    [multi]="false"
    [optionLabel]="'name'"
    [optionValue]="optionValue"
    [targetTypes]="['LANGUAGE']"
    [customProjectionFields]="['code', 'name', 'recordStatus', 'langCode', 'direction']"
  >
    <ng-template let-item #selectedItemTemplate1>
      <app-language-code-view [data]="item"></app-language-code-view>
    </ng-template>
    <ng-template let-item #itemTemplate1>
      <div class="flex flex-row gap-1 align-items-center">
        <app-badge-item
          class="mr-2"
          [styleClass]="'text-xs'"
          [status]="item?.recordStatus"
          [text]="item?.code"
        ></app-badge-item>
        <span [class]="'currency-flag currency-flag-' + localesMap?.[item?.langCode]"></span>
        {{ item?.name }}
      </div>
    </ng-template>
  </app-target-code-selector>
</app-input-view-switch>
