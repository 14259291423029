import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiResponseExemptionEvent, ExemptionDto } from '@shared/classes';
import { BaseRequestControllerWithRuleService } from '@shared/services/api/custom-api-services/base-request-controller-with-rule.service';
import { DataService } from '@shared/services/api/data.service';

@Injectable({
  providedIn: 'root',
})
export class ExemptionDataService extends BaseRequestControllerWithRuleService<
  ExemptionDto,
  ApiResponseExemptionEvent.DataEnum
> {
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(dataService, environment.framework, '/exemptions', [
      'id',
      'code',
      'tags',
      'tagsCodes',
      'name',
      'status',
      'owner',
      'approver',
      'authorityDocument',
      'lockedBy',
      'lockedForEdit',
      'lockedUntil',
      'userAccessLevel',
      'recordStatus',
      'translations',
      'originalLanguage',
      'creatorName',
      'lastModifierName',
      'creationDate',
      'lastModificationDate',
      'description'
    ]);
  }

  navigateToListPage() {
    this.router.navigateByUrl(`framework/authority/exemption/list`);
  }
}
