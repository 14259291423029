export enum BulkOperation {
  Update = 'UPDATE',
  Delete = 'DELETE',
  AddRelations = 'ADD_RELATIONS',
  PermenantDelete = 'PERMENANT_DELETE',
  Restore = 'RESTORE',
  Access = 'ACCESS',
  Status = 'STATUS',
  Watchers = 'WATCHERS'
}
