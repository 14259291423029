// export enum ModuleKeywords { //@TODONewModule: when you add new module make sure to define these
//     EntityLevels = 'EntityLevels',
//     EntityGroup = 'EntityGroup',
//     EntityType = 'EntityType',
//     Entity = 'Entity',
//     Question = 'Question',
//     Questionnaire = 'Questionnaire',
//     QuestionnaireValue = 'QuestionnaireValue',
//     MyQuestionnaireValue = 'MyQuestionnaireValue',
//     RiskItem = 'RiskItem',
//     RiskRegister = 'RiskRegister',
//     RiskProject = 'RiskProject',
//     RiskAssessmentRequest = 'RiskAssessmentRequest',
//     RiskStatement = 'RiskStatement',
//     RiskStatementCategory = 'RiskStatementCategory',
//     ImpactFactor = 'ImpactFactor',
//     RiskMethodology = 'RiskMethodology',
//     Control = 'Control',
//     ControlObjective = 'ControlObjective',
//     Policy = 'Policy',
//     Exception = 'Exception',
//     AuthorityDocument = 'AuthorityDocument',
//     CitationType = 'CitationType',
//     Citation = 'Citation',
//     Task = 'Task',
//     IssueTypes='IssueTypes',
//     Issues='Issues',
//     RemediationPlan='RemediationPlan',
//     User = 'User',
//     Role = 'Role',
//     Group = 'Group',
//     Service = 'Service',
//     Finding = 'Finding',
//     Assets = 'Assets',
//     Events = 'Events',
//     Geographies = 'Geographies',
//     Objectives = 'Objectives',
//     Processes = 'Processes',
//     Properties = 'Properties',
//     Responsibilities = 'Responsibilities',
//     Organizations = 'Organizations',
//     Acknowledgment = 'Acknowledgment',
//     AcknowledgmentRequest = 'AcknowledgmentRequest',
//     Notifications = 'Notifications',
//     MyNotifications = 'MyNotifications',
//     Fields = 'Fields',
//     Tags = 'Tags',
//     RelationType = 'RelationTypes',
//     PolicyBody = 'PolicyBody',
//     Attestations = 'Attestations',
//     StaticValues = 'StaticValues',
// }
namespace ModuleKeyword {
  export type ModuleKeywordsEnum =
    | 'QUESTION'
    | 'QUESTIONNAIRE'
    | 'QUESTIONNAIRE_VALUE'
    | 'MY_QUESTIONNAIRE_VALUE'
    | 'RISK_ITEM'
    | 'RISK_PROJECT'
    | 'RISK_STATEMENT'
    | 'RISK_STATEMENT_CATEGORY'
    | 'EVENT'
    | 'GEOGRAPHY'
    | 'RESPONSIBILITY'
    | 'ASSET'
    | 'PROCESS'
    | 'OBJECTIVE'
    | 'ENTITY_TYPE'
    | 'POLICY'
    | 'CONTROL'
    | 'CITATION_TYPE'
    | 'CITATION'
    | 'ACKNOWLEDGMENT_REQUEST'
    | 'ACKNOWLEDGMENT'
    | 'TASK'
    | 'MY_TASK'
    | 'ATTESTATION'
    | 'EXCEPTION'
    | 'CHECK_ITEM'
    | 'ISSUE'
    | 'ISSUE_TYPE'
    | 'REMEDIATION'
    | 'NOTIFICATION'
    | 'MY_NOTIFICATION'
    | 'RISK_METHODOLOGY'
    | 'SUBSCRIPTION'
    | 'POLICY_BODY'
    | 'FIELD_TEMPLATE'
    | 'RISK_ASSESSMENT_REQUEST'
    | 'DASHBOARD_LAYOUT'
    | 'CRITERIA_FILTER'
    | 'FIELD_SET'
    | 'FORM_COMPONENT'
    | 'RELATION_TYPE'
    | 'ORGANIZATION'
    | 'ENTITY_GROUP'
    | 'EXPORT_PACKAGE'
    | 'IMPORT_PACKAGE'
    | 'EXEMPTION'
    | 'SETTING'
    | 'COMMENT'
    | 'RELATION'
    | 'RELATION_TYPE_ITEM'
    | 'POLICY_BODY_SECTION'
    | 'GLOBAL_VALUE_LIST'
    | 'COMPLIANCE_REPORT'
    | 'COMPLIANCE_REPORT_RESULT'
    | 'USER'
    | 'ROLE'
    | 'GROUP'
    | 'FINDING'
    | 'RISK_REGISTER'
    | 'STATIC_VALUE'
    | 'MY_RISK_ASSESSMENT_REQUEST'
    | 'EMAIL_TEMPLATE'
    | 'TASK_TEMPLATE'
    | 'MY_ACKNOWLEDGMENT'
    | 'AUDIT_TRAIL'
    | 'DOCUMENT'
    | 'AUDIT_CASE'
    | 'AUDIT_CASE_RESULT'
    | 'AUDIT_UNIVERSE'
    | 'AUDIT_PROJECT'
    | 'AUDIT_PROGRAM'
    | 'AUDIT_PROGRAM_TYPE'
    | 'AUDIT_CASE_TEMPLATE'
    | 'AUDIT_PROGRAM_REPORT'
    | 'AUDIT_PROJECT_REPORT'
    | 'AUDIT_REQUEST'
    | 'EXPORT_GLOBAL_PACKAGE'
    | 'IMPORT_GLOBAL_PACKAGE'
    | 'RISK_VULNERABILITY'
    | 'RISK_THREAT'
    | 'FILTER'
    | 'CONTENT_VIEW'
    | 'DASHBOARD'
    | 'REPORT'
    | 'PUBLISHED_POLICY'
    | 'STANDARD'
    | 'PUBLISHED_STANDARD'
    | 'GUIDELINE'
    | 'PUBLISHED_GUIDELINE'
    | 'CONTROL_GROUP'
    | 'CONTROL_OBJECTIVE'
    | 'AUTHORITY_DOCUMENT'
    | 'CONTROL_CATEGORY'
    | 'CONTROL_PROCEDURE'
    | 'STATISTIC_COLLECTOR'
    | 'GENERATED_STATISTIC'
    | 'GENERATED_REPORT'
    | 'REPORT_CONFIGURATION_TEMPLATE'
    | 'TAG'
    | 'AUDIT_EXPENSE'
    | 'TRANSPARENCY_CASE'
    | 'TRANSPARENCY_CASE_CATEGORY'
    | 'REPORT_SCHEDULER'
    | 'METRIC'
    | 'METRIC_VALUE'
    | 'METRIC_CATEGORY'
    | 'LANGUAGE'
    | 'CONTROLS_GAP_REPORT'
    | 'CONTROLS_GAP_REPORT_RESULT'
    | 'CAMPAIGN'
    | 'QUESTIONNAIRE_REQUEST'
    | 'WORKSPACE'
    | 'QUESTIONNAIRE_TYPE'
    | 'DRAFT_RISK'
    | 'RISK_METHODOLOGY_THRESHOLD_VALUE'
    | 'RISK_METHODOLOGY_LIKELIHOOD_VALUE'
    | 'RISK_METHODOLOGY_IMPACT_VALUE'
    | 'RISK_METHODOLOGY_IMPACT_FACTOR'
    | 'AUDIT_OBJECTIVE '
    | 'REMEDIATION_ACTION '
    | 'EVIDENCE'
    | 'POLICY_BODY_SECTION_TEMPLATE'
    | 'POLICY_BODY_TEMPLATE';
  // | 'FIELD'
  // | 'CITATION_COMPLIANCE_REPORT_RESULT' //should be deleted from target type enum
  // | 'CITATION_COMPLIANCE_REPORT' //should be deleted from target type enum
  // | 'DOCUMENT_VERSION'
  // | 'BUCKET'
  // | 'USER_PREFERENCE'
  // | 'LANGUAGE'
  // | 'GENERATED_REPORT'
  // | 'PUBLISHED_POLICIES'
  // | 'REPORT_SCHEDULER'
  export const ModuleKeywordsEnum = {
    Question: 'QUESTION' as ModuleKeywordsEnum,
    Questionnaire: 'QUESTIONNAIRE' as ModuleKeywordsEnum,
    QuestionnaireValue: 'QUESTIONNAIRE_VALUE' as ModuleKeywordsEnum,
    QuestionnaireType: 'QUESTIONNAIRE_TYPE' as ModuleKeywordsEnum,
    MyQuestionnaireValue: 'MY_QUESTIONNAIRE_VALUE' as ModuleKeywordsEnum,
    RiskItem: 'RISK_ITEM' as ModuleKeywordsEnum,
    RiskProject: 'RISK_PROJECT' as ModuleKeywordsEnum,
    RiskStatement: 'RISK_STATEMENT' as ModuleKeywordsEnum,
    RiskStatementCategory: 'RISK_STATEMENT_CATEGORY' as ModuleKeywordsEnum,
    Event: 'EVENT' as ModuleKeywordsEnum,
    Geography: 'GEOGRAPHY' as ModuleKeywordsEnum,
    Responsibility: 'RESPONSIBILITY' as ModuleKeywordsEnum,
    Asset: 'ASSET' as ModuleKeywordsEnum,
    Process: 'PROCESS' as ModuleKeywordsEnum,
    Objective: 'OBJECTIVE' as ModuleKeywordsEnum,
    EntityType: 'ENTITY_TYPE' as ModuleKeywordsEnum,
    Policy: 'POLICY' as ModuleKeywordsEnum,
    PublishedPolicy: 'PUBLISHED_POLICY' as ModuleKeywordsEnum,
    Control: 'CONTROL' as ModuleKeywordsEnum,
    ControlGroup: 'CONTROL_GROUP' as ModuleKeywordsEnum,
    ControlObjective: 'CONTROL_OBJECTIVE' as ModuleKeywordsEnum,
    CitationType: 'CITATION_TYPE' as ModuleKeywordsEnum,
    Citation: 'CITATION' as ModuleKeywordsEnum,
    AcknowledgmentRequest: 'ACKNOWLEDGMENT_REQUEST' as ModuleKeywordsEnum,
    MyAcknowledgment: 'MY_ACKNOWLEDGMENT' as ModuleKeywordsEnum,
    Acknowledgment: 'ACKNOWLEDGMENT' as ModuleKeywordsEnum,
    Task: 'TASK' as ModuleKeywordsEnum,
    MyTask: 'MY_TASK' as ModuleKeywordsEnum,
    Attestation: 'ATTESTATION' as ModuleKeywordsEnum,
    Exception: 'EXCEPTION' as ModuleKeywordsEnum,
    CheckItem: 'CHECK_ITEM' as ModuleKeywordsEnum,
    Issue: 'ISSUE' as ModuleKeywordsEnum,
    IssueType: 'ISSUE_TYPE' as ModuleKeywordsEnum,
    Remediation: 'REMEDIATION' as ModuleKeywordsEnum,
    Notification: 'NOTIFICATION' as ModuleKeywordsEnum,
    MyNotification: 'MY_NOTIFICATION' as ModuleKeywordsEnum,
    RiskMethodology: 'RISK_METHODOLOGY' as ModuleKeywordsEnum,
    Subscription: 'SUBSCRIPTION' as ModuleKeywordsEnum,
    PolicyBody: 'POLICY_BODY' as ModuleKeywordsEnum,
    PolicyBodyTemplate: 'POLICY_BODY_TEMPLATE' as ModuleKeywordsEnum,
    FieldTemplate: 'FIELD_TEMPLATE' as ModuleKeywordsEnum,
    RiskAssessmentRequest: 'RISK_ASSESSMENT_REQUEST' as ModuleKeywordsEnum,
    DashboardLayout: 'DASHBOARD_LAYOUT' as ModuleKeywordsEnum,
    CriteriaFilter: 'CRITERIA_FILTER' as ModuleKeywordsEnum,
    FieldSet: 'FIELD_SET' as ModuleKeywordsEnum,
    FormComponent: 'FORM_COMPONENT' as ModuleKeywordsEnum,
    RelationType: 'RELATION_TYPE' as ModuleKeywordsEnum,
    Organization: 'ORGANIZATION' as ModuleKeywordsEnum,
    ExportPackage: 'EXPORT_PACKAGE' as ModuleKeywordsEnum,
    ImportPackage: 'IMPORT_PACKAGE' as ModuleKeywordsEnum,
    Exemption: 'EXEMPTION' as ModuleKeywordsEnum,
    Setting: 'SETTING' as ModuleKeywordsEnum,
    Comment: 'COMMENT' as ModuleKeywordsEnum,
    Relation: 'RELATION' as ModuleKeywordsEnum,
    RelationTypeItem: 'RELATION_TYPE_ITEM' as ModuleKeywordsEnum,
    PolicyBodySection: 'POLICY_BODY_SECTION' as ModuleKeywordsEnum,
    PolicyBodySectionTemplate: 'POLICY_BODY_SECTION_TEMPLATE' as ModuleKeywordsEnum,
    GlobalValueList: 'GLOBAL_VALUE_LIST' as ModuleKeywordsEnum,
    ComplianceReport: 'COMPLIANCE_REPORT' as ModuleKeywordsEnum,
    ComplianceReportResult: 'COMPLIANCE_REPORT_RESULT' as ModuleKeywordsEnum,
    User: 'USER' as ModuleKeywordsEnum,
    Role: 'ROLE' as ModuleKeywordsEnum,
    Group: 'GROUP' as ModuleKeywordsEnum,
    Finding: 'FINDING' as ModuleKeywordsEnum,
    RiskRegister: 'RISK_REGISTER' as ModuleKeywordsEnum,
    StaticValue: 'STATIC_VALUE' as ModuleKeywordsEnum,
    MyRiskAssessmentRequest: 'MY_RISK_ASSESSMENT_REQUEST' as ModuleKeywordsEnum,
    EmailTemplate: 'EMAIL_TEMPLATE' as ModuleKeywordsEnum,
    TaskTemplate: 'TASK_TEMPLATE' as ModuleKeywordsEnum,
    AuditTrail: 'AUDIT_TRAIL' as ModuleKeywordsEnum,
    Document: 'DOCUMENT' as ModuleKeywordsEnum,
    AuditCase: 'AUDIT_CASE' as ModuleKeywordsEnum,
    AuditProgram: 'AUDIT_PROGRAM' as ModuleKeywordsEnum,
    AuditProgramType: 'AUDIT_PROGRAM_TYPE' as ModuleKeywordsEnum,
    AuditCaseResult: 'AUDIT_CASE_RESULT' as ModuleKeywordsEnum,
    AuditProject: 'AUDIT_PROJECT' as ModuleKeywordsEnum,
    AuditUniverse: 'AUDIT_UNIVERSE' as ModuleKeywordsEnum,
    AuditCaseTemplate: 'AUDIT_CASE_TEMPLATE' as ModuleKeywordsEnum,
    AuditProgramReport: 'AUDIT_PROGRAM_REPORT' as ModuleKeywordsEnum,
    AuditProjectReport: 'AUDIT_PROJECT_REPORT' as ModuleKeywordsEnum,
    AuditRequest: 'AUDIT_REQUEST' as ModuleKeywordsEnum,
    AuditExpense: 'AUDIT_EXPENSE' as ModuleKeywordsEnum,
    AuthorityDocument: 'AUTHORITY_DOCUMENT' as ModuleKeywordsEnum,
    ExportGlobalPackage: 'EXPORT_GLOBAL_PACKAGE' as ModuleKeywordsEnum,
    ImportGlobalPackage: 'IMPORT_GLOBAL_PACKAGE' as ModuleKeywordsEnum,
    RiskVulnerability: 'RISK_VULNERABILITY' as ModuleKeywordsEnum,
    RiskThreat: 'RISK_THREAT' as ModuleKeywordsEnum,
    Filter: 'FILTER' as ModuleKeywordsEnum,
    ContentView: 'CONTENT_VIEW' as ModuleKeywordsEnum,
    Dashboard: 'DASHBOARD' as ModuleKeywordsEnum,
    Report: 'REPORT' as ModuleKeywordsEnum,
    TransparencyCase: 'TRANSPARENCY_CASE' as ModuleKeywordsEnum,
    TransparencyCaseCategory: 'TRANSPARENCY_CASE_CATEGORY' as ModuleKeywordsEnum,
    Standard: 'STANDARD' as ModuleKeywordsEnum,
    PublishedStandard: 'PUBLISHED_STANDARD' as ModuleKeywordsEnum,
    Guideline: 'GUIDELINE' as ModuleKeywordsEnum,
    PublishedGuideline: 'PUBLISHED_GUIDELINE' as ModuleKeywordsEnum,
    ControlCategory: 'CONTROL_CATEGORY' as ModuleKeywordsEnum,
    ControlProcedure: 'CONTROL_PROCEDURE' as ModuleKeywordsEnum,
    StatisticCollector: 'STATISTIC_COLLECTOR' as ModuleKeywordsEnum,
    GeneratedStatistic: 'GENERATED_STATISTIC' as ModuleKeywordsEnum,
    GeneratedReport: 'GENERATED_REPORT' as ModuleKeywordsEnum,
    ReportConfigurationTemplate: 'REPORT_CONFIGURATION_TEMPLATE' as ModuleKeywordsEnum,
    Tag: 'TAG' as ModuleKeywordsEnum,
    ReportScheduler: 'REPORT_SCHEDULER' as ModuleKeywordsEnum,
    Metric: 'METRIC' as ModuleKeywordsEnum,
    MetricValue: 'METRIC_VALUE' as ModuleKeywordsEnum,
    MetricCategory: 'METRIC_CATEGORY' as ModuleKeywordsEnum,
    Language: 'LANGUAGE' as ModuleKeywordsEnum,
    ControlsGapReport: 'CONTROLS_GAP_REPORT' as ModuleKeywordsEnum,
    ControlsGapReportResult: 'CONTROLS_GAP_REPORT_RESULT' as ModuleKeywordsEnum,
    Campaign: 'CAMPAIGN' as ModuleKeywordsEnum,
    QuestionnaireRequest: 'QUESTIONNAIRE_REQUEST' as ModuleKeywordsEnum,
    Workspace: 'WORKSPACE' as ModuleKeywordsEnum,
    RiskMethodologyImpactFactor: 'RISK_METHODOLOGY_IMPACT_FACTOR' as ModuleKeywordsEnum,
    RiskMethodologyImpactValue: 'RISK_METHODOLOGY_IMPACT_VALUE' as ModuleKeywordsEnum,
    RiskMethodologyLikelihoodValue: 'RISK_METHODOLOGY_LIKELIHOOD_VALUE' as ModuleKeywordsEnum,
    RiskMethodologyThresholdValue: 'RISK_METHODOLOGY_THRESHOLD_VALUE' as ModuleKeywordsEnum,
    DraftRisk: 'DRAFT_RISK' as ModuleKeywordsEnum,
    AuditObjective: 'AUDIT_OBJECTIVE' as ModuleKeywordsEnum,
    RemediationAction: 'REMEDIATION_ACTION' as ModuleKeywordsEnum,
    Evidence: 'EVIDENCE' as ModuleKeywordsEnum

    // Field: 'FIELD' as ModuleKeywordsEnum,
  };
  // export type ModuleKeywordsEnum = 'EntityLevels' | 'EntityGroup' | 'EntityType' | 'Entity' | 'Question' | 'Questionnaire' | 'QuestionnaireValue' | 'MyQuestionnaireValue' | 'RiskItem' | 'RiskRegister' | 'RiskProject' | 'RiskAssessmentRequest' | 'RiskStatement' | 'RiskStatementCategory' | 'ImpactFactor' | 'RiskMethodology' | 'Control' | 'ControlObjective' | 'Policy' | 'Exception' | 'AuthorityDocument' | 'CitationType' | 'Citation' | 'Task' | 'MyTask' | 'IssueTypes' | 'Issues' | 'RemediationPlan' | 'User' | 'Role' | 'Group' | 'Service' | 'Finding' | 'Assets' | 'Events' | 'Geographies' | 'Objectives' | 'Processes' | 'Properties' | 'Responsibilities' | 'Organizations' | 'Acknowledgment' | 'AcknowledgmentRequest' | 'Notifications' | 'MyNotifications' | 'Fields' | 'Tags' | 'RelationTypes' | 'PolicyBody' | 'Attestations' | 'StaticValues' | 'Exemption' | 'GlobalValueList' | 'PolicyBodySection' | 'ComplianceReport' | 'ComplianceReportResult';
  // export const ModuleKeywordsEnum = {
  //     EntityLevels: 'EntityLevels' as ModuleKeywordsEnum,
  //     EntityGroup: 'EntityGroup' as ModuleKeywordsEnum,
  //     EntityType: 'EntityType' as ModuleKeywordsEnum,
  //     Entity: 'Entity' as ModuleKeywordsEnum,
  //     Question: 'Question' as ModuleKeywordsEnum,
  //     Questionnaire: 'Questionnaire' as ModuleKeywordsEnum,
  //     QuestionnaireValue: 'QuestionnaireValue' as ModuleKeywordsEnum,
  //     MyQuestionnaireValue: 'MyQuestionnaireValue' as ModuleKeywordsEnum,
  //     RiskItem: 'RiskItem' as ModuleKeywordsEnum,
  //     RiskRegister: 'RiskRegister' as ModuleKeywordsEnum,
  //     RiskProject: 'RiskProject' as ModuleKeywordsEnum,
  //     RiskAssessmentRequest: 'RiskAssessmentRequest' as ModuleKeywordsEnum,
  //     RiskStatement: 'RiskStatement' as ModuleKeywordsEnum,
  //     RiskStatementCategory: 'RiskStatementCategory' as ModuleKeywordsEnum,
  //     ImpactFactor: 'ImpactFactor' as ModuleKeywordsEnum,
  //     RiskMethodology: 'RiskMethodology' as ModuleKeywordsEnum,
  //     Control: 'Control' as ModuleKeywordsEnum,
  //     ControlObjective: 'ControlObjective' as ModuleKeywordsEnum,
  //     Policy: 'Policy' as ModuleKeywordsEnum,
  //     Exception: 'Exception' as ModuleKeywordsEnum,
  //     AuthorityDocument: 'AuthorityDocument' as ModuleKeywordsEnum,
  //     CitationType: 'CitationType' as ModuleKeywordsEnum,
  //     Citation: 'Citation' as ModuleKeywordsEnum,
  //     Task: 'Task' as ModuleKeywordsEnum,
  //     MyTask: 'MyTask' as ModuleKeywordsEnum,
  //     IssueTypes: 'IssueTypes' as ModuleKeywordsEnum,
  //     Issues: 'Issues' as ModuleKeywordsEnum,
  //     RemediationPlan: 'RemediationPlan' as ModuleKeywordsEnum,
  //     User: 'User' as ModuleKeywordsEnum,
  //     Role: 'Role' as ModuleKeywordsEnum,
  //     Group: 'Group' as ModuleKeywordsEnum,
  //     Service: 'Service' as ModuleKeywordsEnum,
  //     Finding: 'Finding' as ModuleKeywordsEnum,
  //     Assets: 'Assets' as ModuleKeywordsEnum,
  //     Events: 'Events' as ModuleKeywordsEnum,
  //     Geographies: 'Geographies' as ModuleKeywordsEnum,
  //     Objectives: 'Objectives' as ModuleKeywordsEnum,
  //     Processes: 'Processes' as ModuleKeywordsEnum,
  //     Properties: 'Properties' as ModuleKeywordsEnum,
  //     Responsibilities: 'Responsibilities' as ModuleKeywordsEnum,
  //     Organizations: 'Organizations' as ModuleKeywordsEnum,
  //     Acknowledgment: 'Acknowledgment' as ModuleKeywordsEnum,
  //     AcknowledgmentRequest: 'AcknowledgmentRequest' as ModuleKeywordsEnum,
  //     Notifications: 'Notifications' as ModuleKeywordsEnum,
  //     MyNotifications: 'MyNotifications' as ModuleKeywordsEnum,
  //     Fields: 'Fields' as ModuleKeywordsEnum,
  //     Tags: 'Tags' as ModuleKeywordsEnum,
  //     RelationTypes: 'RelationTypes' as ModuleKeywordsEnum,
  //     RelationType: 'RelationTypes' as ModuleKeywordsEnum,
  //     PolicyBody: 'PolicyBody' as ModuleKeywordsEnum,
  //     PolicyBodySection: 'PolicyBodySection' as ModuleKeywordsEnum,
  //     Attestations: 'Attestations' as ModuleKeywordsEnum,
  //     StaticValues: 'StaticValues' as ModuleKeywordsEnum,
  //     Exemption: 'Exemption' as ModuleKeywordsEnum,
  //     GlobalValueList: 'GlobalValueList' as ModuleKeywordsEnum,
  //     ComplianceReport: 'ComplianceReport' as ModuleKeywordsEnum,
  //     ComplianceReportResult: 'ComplianceReportResult' as ModuleKeywordsEnum,
  // };
}
const ModuleKeywords = { ...ModuleKeyword.ModuleKeywordsEnum };
// Define the final type
type ModuleKeywordsType = (typeof ModuleKeywords)[keyof typeof ModuleKeywords];

export type ModuleKeywords = ModuleKeywordsType;

export { ModuleKeywords };

