import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, DataTypeEnum, PermissionActions, TypesWithName, getEnumOptions, isNullObj } from '@shared/classes';
import { BulkOperation } from '@shared/classes/view/BulkOperation';
import { RelationsService, ViewModeService } from '@shared/services';
import { BulkOperationService } from '@shared/services/bulk-operation-service/bulk-operation.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-bulk-operation-confirmation-step-form',
  templateUrl: './bulk-operation-confirmation-step-form.component.html',
  styleUrls: ['./bulk-operation-confirmation-step-form.component.scss'],
})
export class BulkOperationConfirmationStepFormComponent extends BaseForm<any> implements OnInit {
  @Input() stepperService: BulkOperationService;
  bulkOperations = BulkOperation;
  items = [];
  dataTypes = DataTypeEnum;

  //Add Relations
  tagType;
  excludedCodes: string[] = [];
  listOfTypes = [];
  selectedType;

  constructor(
    public viewModeService: ViewModeService,
    private permissionService: NgxPermissionsService,
    private relationServiec: RelationsService
  ) {
    super(viewModeService, null);
  }

  ngOnInit(): void {
    if (this.stepperService.operation == this.bulkOperations.Status) {
      this.initializeStatusOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.Watchers) {
      this.initializeWatchersOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.Access) {
      this.initializeAccessOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.Restore ||
      this.stepperService.operation == this.bulkOperations.Delete ||
      this.stepperService.operation == this.bulkOperations.PermenantDelete
    ) {
      this.initializeDeleteOperation();
      return;
    }
    if (this.stepperService.operation == this.bulkOperations.AddRelations) {
      this.initializeAddRelationsOperation();
      return;
    }
    this.initializeUpdateOperation();
  }

  initializeStatusOperation() {
    this.formGroup = new FormGroup([]);
    this.items = this.stepperService.items.map((x) => {
      return {
        code: x.code,
        oldStatus: x.recordStatus,
        newStatus: this.stepperService.stepperItem.status,
      };
    });
  }

  initializeAddRelationsOperation() {
    this.formGroup = new FormGroup({
      targetType: new FormControl(null, Validators.required),
      notes: new FormControl(null),
      targetCode: new FormControl(null, Validators.required),
      relationType: new FormControl(null, Validators.required),
    });
    this.excludedCodes = this.stepperService.items.map((x) => x.code + '');

    this.formGroup?.controls?.targetType.valueChanges.subscribe((res) => {
      this.selectedType = res ?? null;
      this.formGroup?.patchValue({
        targetCode: null,
      });
    });
    const perms = this.permissionService.getPermissions();

    const allowedTypeList = [];
    getEnumOptions(TypesWithName).forEach((element) => {
      if (!isNullObj(perms?.[`${PermissionActions.Read}${element.value}`])) {
        allowedTypeList.push(element);
      }
    });
    this.relationServiec
      .searchRelationTypes(
        { all: true },
        {},
        { showLoading: true, showMsg: false, extras: { cacheKey: 'allRelationTypes' } }
      )
      .subscribe((res) => {
        this.tagType = res;
      });
    this.listOfTypes = [...allowedTypeList];
    this.formGroup.patchValue({ ...this.stepperService.stepperItem });
  }

  initializeDeleteOperation() {
    this.formGroup = new FormGroup([]);
  }

  initializeWatchersOperation() {
    this.formGroup = new FormGroup([]);
    this.items = this.stepperService.stepperItem.access;
  }

  initializeAccessOperation() {
    this.formGroup = new FormGroup([]);
    this.items = this.stepperService.stepperItem.access;
  }

  initializeUpdateOperation() {
    this.formGroup = new FormGroup(
      this.stepperService.mappingService.recordFields
        ?.filter((x, idx) => x.useInBulk)
        ?.filter((x, idx) => this.stepperService.checkControls[idx].value)
        .reduce((acc, x) => {
          acc[x?.name] = x?.formField?.options?.control;
          return acc;
        }, {})
    );
    this.items = this.stepperService.items.map((x) => {
      return {
        code: x.code,
        oldFields: this.stepperService.mappingService.recordFields
          ?.filter((f, idx) => f.useInBulk)
          .filter(
            (f, idx) =>
              this.stepperService.checkControls[idx].value && this.stepperService.stepperItem[f.key] !== undefined
          )
          .map((y) => {
            return {
              ...y.formField,
              options: {
                ...y?.formField?.options,
                label: y.name,
                name: y.key,
                showLabelInViewMode: true,
                control: new FormControl(x[y.key]),
              },
            };
          }),
        newFields: this.stepperService.mappingService.recordFields
          ?.filter((f, idx) => f.useInBulk)
          .filter(
            (f, idx) =>
              this.stepperService.checkControls[idx].value && this.stepperService.stepperItem[f.key] !== undefined
          )
          .map((y) => {
            return {
              ...y.formField,
              options: {
                ...y?.formField?.options,
                label: y.name,
                name: y.key,
                control: new FormControl(this.stepperService.stepperItem[y?.key]),
              },
            };
          }),
      };
    });
  }
  getData() {
    return this.getChangedFormValues(this.data);
  }

  setData(data: any) {
    this.data = data;
  }

  initFormStructure(): void { }
}
