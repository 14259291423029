/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StateMachineLog } from './stateMachineLog';
import { AccessRecord } from './accessRecord';
import { RiskMethodologyLikelihoodValue } from './riskMethodologyLikelihoodValue';
import { RiskMethodologyImpactValue } from './riskMethodologyImpactValue';
import { UserOperationPrinciple } from './userOperationPrinciple';
import { RiskItemImpactFactors } from './riskItemImpactFactors';


export interface RiskItem { 
    creatorName?: string;
    creationDate?: string;
    lastModifierName?: string;
    lastModificationDate?: string;
    id?: string;
    searchScore?: number;
    recordStatus?: RiskItem.RecordStatusEnum;
    uuid?: string;
    description?: string;
    code: string;
    label?: string;
    tags?: Array<string>;
    tagsCodes?: Array<string>;
    parent?: string;
    parents?: Array<string>;
    module?: RiskItem.ModuleEnum;
    systemGenerated?: boolean;
    originalLanguage?: string;
    translations?: { [key: string]: any; };
    systemLocked?: boolean;
    softDeleteDate?: string;
    events?: Array<StateMachineLog>;
    name?: string;
    questionnaireValueCode?: string;
    type?: RiskItem.TypeEnum;
    owner?: UserOperationPrinciple;
    riskNotes?: string;
    comments?: string;
    status?: RiskItem.StatusEnum;
    strategy?: RiskItem.StrategyEnum;
    approver?: UserOperationPrinciple;
    riskStatement?: string;
    riskAssessmentId?: string;
    inheritedRiskImpact?: string;
    inheritedRiskAcceptance?: RiskItem.InheritedRiskAcceptanceEnum;
    residualRiskAcceptance?: RiskItem.ResidualRiskAcceptanceEnum;
    residualImpact?: string;
    controls?: Array<string>;
    inheritedLikelihood?: string;
    residualLikelihood?: string;
    inheritedClassification?: string;
    residualClassification?: string;
    inheritedRisk?: number;
    residualRisk?: number;
    category?: string;
    project?: string;
    entityId?: string;
    riskAssessment?: string;
    riskThreats?: Array<string>;
    riskVulnerabilities?: Array<string>;
    impactFactors?: Array<RiskItemImpactFactors>;
    methodology?: string;
    oldRiskItem?: string;
    state?: RiskItem.StateEnum;
    activeRiskAssessmentRequest?: string;
    codePrefix?: string;
    inheritedRiskImpactDto?: RiskMethodologyImpactValue;
    inheritedLikelihoodDto?: RiskMethodologyLikelihoodValue;
    residualLikelihoodDto?: RiskMethodologyLikelihoodValue;
    residualImpactDto?: RiskMethodologyImpactValue;
    lockedUntil?: string;
    lockedForEdit?: boolean;
    lockedBy?: string;
    userAccessLevel?: AccessRecord;
}
export namespace RiskItem {
    export type RecordStatusEnum = 'DELETED' | 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
    export const RecordStatusEnum = {
        Deleted: 'DELETED' as RecordStatusEnum,
        Locked: 'LOCKED' as RecordStatusEnum,
        Active: 'ACTIVE' as RecordStatusEnum,
        InActive: 'IN_ACTIVE' as RecordStatusEnum
    };
    export type ModuleEnum = 'ALL' | 'DOCUMENT_APP' | 'ACKNOWLEDGMENT_APP' | 'ADMIN_APP' | 'CONFIG_APP' | 'DATA_STRUCTURE_APP' | 'BRANDING_APP' | 'ENTITY_APP' | 'FRAMEWORK_APP' | 'ISSUES_APP' | 'QUESTION_APP' | 'RELATION_APP' | 'RISK_APP' | 'USER_APP' | 'USER_OPERATIONS_APP' | 'AUDIT_APP' | 'HUB_APP' | 'TRANSPARENCY_APP' | 'PERFORMANCE_APP' | 'BCM_APP';
    export const ModuleEnum = {
        All: 'ALL' as ModuleEnum,
        DocumentApp: 'DOCUMENT_APP' as ModuleEnum,
        AcknowledgmentApp: 'ACKNOWLEDGMENT_APP' as ModuleEnum,
        AdminApp: 'ADMIN_APP' as ModuleEnum,
        ConfigApp: 'CONFIG_APP' as ModuleEnum,
        DataStructureApp: 'DATA_STRUCTURE_APP' as ModuleEnum,
        BrandingApp: 'BRANDING_APP' as ModuleEnum,
        EntityApp: 'ENTITY_APP' as ModuleEnum,
        FrameworkApp: 'FRAMEWORK_APP' as ModuleEnum,
        IssuesApp: 'ISSUES_APP' as ModuleEnum,
        QuestionApp: 'QUESTION_APP' as ModuleEnum,
        RelationApp: 'RELATION_APP' as ModuleEnum,
        RiskApp: 'RISK_APP' as ModuleEnum,
        UserApp: 'USER_APP' as ModuleEnum,
        UserOperationsApp: 'USER_OPERATIONS_APP' as ModuleEnum,
        AuditApp: 'AUDIT_APP' as ModuleEnum,
        HubApp: 'HUB_APP' as ModuleEnum,
        TransparencyApp: 'TRANSPARENCY_APP' as ModuleEnum,
        PerformanceApp: 'PERFORMANCE_APP' as ModuleEnum,
        BcmApp: 'BCM_APP' as ModuleEnum
    };
    export type TypeEnum = 'RISK_PROJECT' | 'MANUAL';
    export const TypeEnum = {
        RiskProject: 'RISK_PROJECT' as TypeEnum,
        Manual: 'MANUAL' as TypeEnum
    };
    export type StatusEnum = 'DRAFT' | 'SUBMITTED' | 'REJECTED' | 'RETIRED' | 'APPROVED' | 'REASSESS' | 'RESPONSE' | 'REVIEW';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Submitted: 'SUBMITTED' as StatusEnum,
        Rejected: 'REJECTED' as StatusEnum,
        Retired: 'RETIRED' as StatusEnum,
        Approved: 'APPROVED' as StatusEnum,
        Reassess: 'REASSESS' as StatusEnum,
        Response: 'RESPONSE' as StatusEnum,
        Review: 'REVIEW' as StatusEnum
    };
    export type StrategyEnum = 'MITIGATE' | 'TRANSFER' | 'ACCEPT' | 'AVOID';
    export const StrategyEnum = {
        Mitigate: 'MITIGATE' as StrategyEnum,
        Transfer: 'TRANSFER' as StrategyEnum,
        Accept: 'ACCEPT' as StrategyEnum,
        Avoid: 'AVOID' as StrategyEnum
    };
    export type InheritedRiskAcceptanceEnum = 'ACCEPTABLE' | 'NOT_ACCEPTABLE';
    export const InheritedRiskAcceptanceEnum = {
        Acceptable: 'ACCEPTABLE' as InheritedRiskAcceptanceEnum,
        NotAcceptable: 'NOT_ACCEPTABLE' as InheritedRiskAcceptanceEnum
    };
    export type ResidualRiskAcceptanceEnum = 'ACCEPTABLE' | 'NOT_ACCEPTABLE';
    export const ResidualRiskAcceptanceEnum = {
        Acceptable: 'ACCEPTABLE' as ResidualRiskAcceptanceEnum,
        NotAcceptable: 'NOT_ACCEPTABLE' as ResidualRiskAcceptanceEnum
    };
    export type StateEnum = 'DRAFT' | 'SUBMITTED' | 'REJECTED' | 'RETIRED' | 'APPROVED' | 'REASSESS' | 'RESPONSE' | 'REVIEW';
    export const StateEnum = {
        Draft: 'DRAFT' as StateEnum,
        Submitted: 'SUBMITTED' as StateEnum,
        Rejected: 'REJECTED' as StateEnum,
        Retired: 'RETIRED' as StateEnum,
        Approved: 'APPROVED' as StateEnum,
        Reassess: 'REASSESS' as StateEnum,
        Response: 'RESPONSE' as StateEnum,
        Review: 'REVIEW' as StateEnum
    };
}


