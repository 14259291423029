@if (isLoading) {
  <p-skeleton width="70%" height="35px" />
} @else if (item) {
  <div class="flex flex-row gap-1 align-items-center">
    <app-badge-item
      class="mr-2"
      [styleClass]="'text-xs'"
      [status]="item?.recordStatus"
      [text]="item?.code"
    ></app-badge-item>
    <span [class]="'currency-flag currency-flag-' + localesMap?.[item?.langCode]"></span>
    {{ item?.name }}
  </div>
}
