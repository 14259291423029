import { Component, Input, OnInit } from '@angular/core';
import { AppPermissions, RiskItem, UnsubscribeOnDestroyAdapter } from '@shared/classes';
import { RiskItemDataService } from 'app/modules/risk/services/data/risk-item-data.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { of } from 'rxjs';

@Component({
  selector: 'app-widget-risk-map',
  templateUrl: './widget-risk-map.component.html',
  styleUrl: './widget-risk-map.component.scss',
})
export class WidgetRiskMapComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() title: string = '';
  riskDataF = () => this.riskData;
  appPermissions = AppPermissions;
  @Input() filterName: string;
  @Input() filterValue: string;
  constructor(
    private riskRegisterDataService: RiskItemDataService,
    private permissionService: NgxPermissionsService
  ) {
    super();
  }
  ngOnInit(): void { }
  onInitEvent() {
    this.fetchRiskItems();
  }

  riskKeys = ['residualLikelihoodDto', 'residualImpactDto', 'code', 'name'];
  riskData;
  fetchRiskItems() {
    this.permissionService.hasPermission(this.appPermissions.ReadRiskRegister).then((isAllowed) => {
      this.subs.sink = (
        isAllowed
          ? this.riskRegisterDataService.search<any>(
            {
              all: true,
            },
            {
              projectionFields: this.riskKeys,
              filters: this.filterName ? [{ property: 'status', operation: 'EQUAL', value: RiskItem.StatusEnum.Approved }, { property: this.filterName, operation: 'EQUAL', value: this.filterValue }] : [{ property: 'status', operation: 'EQUAL', value: RiskItem.StatusEnum.Approved }],
            }
          )
          : of({ content: [], totalElements: 0 } as any)
      ).subscribe({
        next: (res: any) => {
          this.riskData = res;
        },
        error: (error) => { },
        complete: () => { },
      });
    });
  }
}
