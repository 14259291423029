import { EChartsOption } from 'echarts';
import { maxBy } from 'lodash-es';
import { ChartViewConfig } from '../model';
export function setChartNameAndData(
  chartOptions: EChartsOption,
  chartType: ChartViewConfig.ChartTypeEnum | 'TREE' | 'GAUGE',
  name?: string,
  data?: { value: number; name: string }[]
) {
  let optionsCopy = { ...chartOptions };
  try {
    if (!optionsCopy?.series) {
      return optionsCopy;
    }
    optionsCopy.series[0].name = name;
    if (chartType == 'VERTICAL_BAR') {
      optionsCopy.series[0].data = data.map((x, i) => {
        return { ...x };
      });
      optionsCopy.xAxis[0].data = data.map((x, i) => {
        return x.name;
      });
      // chartOptions.series = data.map((x,i)=>{return{
      //     name: x.name,
      //     type: 'bar',
      //     stack: 'stack',
      //     barWidth: '60%',
      //     data: [...(i > 0 ? new Array(i).fill(null) : []),x.value],
      // }})
    } else if (chartType == 'HORIZONTAL_BAR') {
      optionsCopy.series[0].data = data.map((x, i) => {
        return { ...x };
      });
      optionsCopy.yAxis[0].data = data.map((x, i) => {
        return x.name;
      });
    } else if (chartType == 'LINE') {
      optionsCopy.series[0].data = data.map((x, i) => {
        return { ...x };
      });
      (optionsCopy.xAxis as any).data = data.map((x, i) => {
        return x.name;
      });
    } else if (chartType == 'AREA') {
      optionsCopy.series[0].data = data.map((x, i) => {
        return { ...x };
      });
      (optionsCopy.angleAxis as any).data = data.map((x, i) => {
        return x.name;
      });
    } else if (chartType == 'RADAR') {
      const maxVal = maxBy(data, 'value').value + 1;
      optionsCopy.series[0].data = [
        {
          value: data.map((x, i) => {
            return x.value;
          }),
          name: name,
        },
      ];
      (optionsCopy.radar as any).indicator = data.map((x, i) => {
        return { name: x.name, max: maxVal };
      });
    } else if (chartType == 'TREE_MAP') {
      optionsCopy.series[0].data = data.map((x, i) => {
        return { ...x };
      });
      // optionsCopy.yAxis[0].data = data.map((x, i) => { return x.name });
    } else if (chartType == 'BUBBLE') {
      //@TODO: children format original data
    } else if (chartType == 'SUN_BURST') {
      //@TODO: children format original data
    } else if (chartType == 'SCATTERED') {
      optionsCopy.series[0].data = data.map((x, i) => {
        return { ...x };
      });
      (optionsCopy.xAxis as any).data = data.map((x, i) => {
        return x.name;
      });
    } else if (chartType == 'SEMI_CIRCLE_DOUGHNUT') {
      optionsCopy.series[0].data = data;
    } else if (chartType == 'FUNNEL') {
      const dataSum = data?.reduce((sum, item) => sum + item.value, 0);
      optionsCopy.series[0].data = data.map((x, i) => {
        return { name: x.name, value: x.value ? Math.round((x.value * 100) / dataSum) : 0 };
      });
    }
    // else if (chartType == 'PYRAMID') {
    //     const dataSum = data?.reduce((sum, item) => sum + item.value, 0);
    //     optionsCopy.series[0].data = data.map((x, i) => { return { name: x.name, value: x.value ? Math.round((x.value * 100) / dataSum) : 0 } });
    // }
    else if (chartType == 'SANKEY') {
      // optionsCopy.series[0].data = data.map((x, i) => { return { ...x } });
      // optionsCopy.yAxis[0].data = data.map((x, i) => { return x.name });
      optionsCopy.series = (data as any).series;
    }
    else if (chartType == 'TREE') {
      // optionsCopy.series[0].data = data.map((x, i) => { return { ...x } });
      // optionsCopy.yAxis[0].data = data.map((x, i) => { return x.name });
      optionsCopy.series = (data as any).series;
    }
    else if (chartType == 'GAUGE') {
      // optionsCopy.series[0].data = data.map((x, i) => { return { ...x } });
      // optionsCopy.yAxis[0].data = data.map((x, i) => { return x.name });
      optionsCopy.series = (data as any).series;
    }
    else if (chartType == 'TIME_SCALE') {
      optionsCopy.series = (data as any).series;
      optionsCopy.yAxis = (data as any).yAxis;
      optionsCopy.xAxis = (data as any).xAxis;
    } else {
      optionsCopy.series[0].data = data;
    }
  } catch (e) { }
  return optionsCopy;
}
export const DoughnutOptions: EChartsOption = {
  // color: ["#c1c1c1", "#000000"],
  backgroundColor: 'transparent',
  animationDurationUpdate: (idx) => idx * 600,
  tooltip: {
    trigger: 'item',
  },
  legend: {
    top: '1%',
    left: 'center',
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      padAngle: 1,
      itemStyle: {
        borderRadius: 10,
      },
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 20,
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        //   { value: 3, name: 'Active' },
        //   { value: 1, name: 'Locked' },
        //   { value: 580, name: 'Email' },
        //   { value: 484, name: 'Union Ads' },
        //   { value: 300, name: 'Video Ads' }
      ],
    },
  ],
};
export const PieOptions: EChartsOption = {
  backgroundColor: 'transparent',
  animationDurationUpdate: (idx) => idx * 600,
  // title: {
  //     text: '',
  //     subtext: '',
  //     left: 'center'
  // },
  tooltip: {
    trigger: 'item',
    appendToBody: true,
  },
  legend: {
    // orient: 'vertical',
    top: '1%',
    left: 'center',
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius: '50%',
      data: [
        // { value: 1048, name: 'Search Engine' },
        // { value: 735, name: 'Direct' },
        // { value: 580, name: 'Email' },
        // { value: 484, name: 'Union Ads' },
        // { value: 300, name: 'Video Ads' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
  ],
};
export const VerticalBarOptions: EChartsOption = {
  // color: ["#c1c1c1", "#000000"],
  backgroundColor: 'transparent',
  legend: {
    top: '1%',
    left: 'center',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      data: [],
      axisTick: {
        alignWithLabel: true,
      },
    },
  ],
  yAxis: [
    {
      type: 'value',
    },
  ],
  series: [
    {
      name: 'Direct',
      type: 'bar',
      barWidth: '60%',
      data: [],
    },
  ],
};
export const HorizontalBarOptions: EChartsOption = {
  // color: ["#c1c1c1", "#000000"],
  backgroundColor: 'transparent',
  legend: {
    top: '1%',
    left: 'center',
  },
  tooltip: {
    trigger: 'item',
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'value',
    },
  ],
  yAxis: [
    {
      type: 'category',
      data: [],
      axisTick: {
        alignWithLabel: true,
      },
    },
  ],
  series: [
    {
      name: 'Direct',
      type: 'bar',
      barWidth: '60%',
      data: [],
    },
  ],
};
export const SankeyOptions: EChartsOption = {
  backgroundColor: 'transparent',
  animationDuration: 1500,
  animationDurationUpdate: 1500,
  universalTransition: true,
  series: [
    {
      type: 'sankey',
      animationDuration: 1500,
      animationDurationUpdate: 1500,
      universalTransition: true,
      data: [],
      links: [],
      lineStyle: {
        color: 'source',
        curveness: 0.5,
      },
      // itemStyle: {
      //   color: '#1f77b4',
      //   borderColor: '#1f77b4'
      // },
      label: {
        //   color: 'rgba(0,0,0,0.7)',
        fontFamily: 'Arial',
        fontSize: 10,
      },
    },
  ],
  tooltip: {
    trigger: 'item',
  },
};
export const TreeOptions: EChartsOption = {
  backgroundColor: 'transparent',
  animationDuration: 750,
  animationDurationUpdate: 750,
  universalTransition: true,
  series: [
    {
      type: 'tree',
      animationDuration: 750,
      animationDurationUpdate: 750,
      universalTransition: true,
      data: [],
    },
  ],
  tooltip: {
    trigger: 'item',
  },
};
export const GaugeOptions: EChartsOption = {
  backgroundColor: 'transparent',
  animationDuration: 750,
  animationDurationUpdate: 750,
  universalTransition: true,
  series: [
    {
      type: 'gauge',
      animationDuration: 750,
      animationDurationUpdate: 750,
      universalTransition: true,
      data: [],
    },
  ],
  tooltip: {
    trigger: 'item',
  },
};
export const LineOptions: EChartsOption = {
  backgroundColor: 'transparent',
  tooltip: {
    trigger: 'item',
  },
  legend: {},
  xAxis: {
    type: 'category',
    data: [],
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      data: [],
      type: 'line',
      smooth: true,
    },
  ],
};
export const PolarOptions: EChartsOption = {
  backgroundColor: 'transparent',
  polar: {
    radius: [30, '80%'],
  },
  radiusAxis: {
    max: 4,
  },
  angleAxis: {
    type: 'category',
    data: [],
    startAngle: 75,
  },
  tooltip: {},
  legend: {},
  series: [
    {
      type: 'bar',
      data: [],
      coordinateSystem: 'polar',
      label: {
        show: true,
        position: 'middle',
        formatter: '{b}: {c}',
      },
    },
  ],
};
export const RadarOption: EChartsOption = {
  backgroundColor: 'transparent',
  legend: {},
  tooltip: {
    trigger: 'item',
  },
  radar: {
    // shape: 'circle',
    indicator: [],
    center: ['50%', '58%'],
    radius: '60%',
  },
  series: [
    {
      name: '',
      type: 'radar',
      data: [
        {
          value: [],
          name: '',
        },
      ],
    },
  ],
};
export const BasicTreeMapOption: EChartsOption = {
  backgroundColor: 'transparent',
  legend: {
    top: '1%',
    left: 'center',
  },
  tooltip: {
    trigger: 'item',
  },
  series: [
    {
      type: 'treemap',
      data: [],
    },
  ],
};
export const SunBurstOption: EChartsOption = {
  backgroundColor: 'transparent',
  tooltip: {
    trigger: 'item',
  },
  legend: {
    top: '1%',
    left: 'center',
  },
  series: {
    type: 'sunburst',
    emphasis: {
      focus: 'ancestor',
    },
    data: [],
    radius: [0, '90%'],
    label: {
      rotate: 'radial',
    },
  },
};
export const ScatterOptions: EChartsOption = {
  backgroundColor: 'transparent',
  tooltip: {
    trigger: 'item',
  },
  legend: {
    top: '1%',
    left: 'center',
  },
  xAxis: {
    type: 'category',
    data: [],
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      data: [],
      type: 'line',
      lineStyle: {
        width: 0,
      },
      symbolSize: 10,
    },
  ],
};
export const SemiCircleDoughnutOption: EChartsOption = {
  backgroundColor: 'transparent',
  tooltip: {
    trigger: 'item',
  },
  legend: {
    top: '1%',
    left: 'center',
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius: ['40%', '70%'],
      center: ['50%', '70%'],
      // adjust the start and end angle
      startAngle: 180,
      endAngle: 360,
      data: [],
    },
  ],
};
export const FunnelOption: EChartsOption = {
  backgroundColor: 'transparent',
  // title: {
  //   text: 'Funnel'
  // },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}%',
  },
  // toolbox: {
  //   feature: {
  //     dataView: { readOnly: false },
  //     restore: {},
  //     saveAsImage: {},
  //   }
  // },
  legend: {},
  series: [
    {
      name: '',
      type: 'funnel',
      left: '10%',
      top: 60,
      bottom: 60,
      width: '80%',
      min: 0,
      max: 100,
      minSize: '0%',
      maxSize: '100%',
      sort: 'descending',
      gap: 2,
      label: {
        show: true,
        //   position: 'inside'
      },
      labelLine: {
        length: 10,
        lineStyle: {
          width: 1,
          type: 'solid',
        },
      },
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 0,
      },
      emphasis: {
        label: {
          fontSize: 20,
        },
      },
      data: [],
    },
  ],
};
export const PyramidOption: EChartsOption = {
  backgroundColor: 'transparent',
  // title: {
  //   text: 'Funnel'
  // },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c}%',
  },
  // toolbox: {
  //   feature: {
  //     dataView: { readOnly: false },
  //     restore: {},
  //     saveAsImage: {},
  //   }
  // },
  legend: {},
  series: [
    {
      name: '',
      type: 'funnel',
      left: '10%',
      top: 60,
      bottom: 60,
      width: '80%',
      min: 0,
      max: 100,
      minSize: '0%',
      maxSize: '100%',
      sort: 'ascending',
      gap: 2,
      label: {
        show: true,
        //   position: 'inside'
      },
      labelLine: {
        length: 10,
        lineStyle: {
          width: 1,
          type: 'solid',
        },
      },
      itemStyle: {
        borderColor: '#fff',
        borderWidth: 0,
      },
      emphasis: {
        label: {
          fontSize: 20,
        },
      },
      data: [],
    },
  ],
};
export const TimescaleOption: EChartsOption = {
  backgroundColor: 'transparent',
  tooltip: {
    trigger: 'axis',
    position: function (pt) {
      return [pt[0], '10%'];
    },
  },
  // title: {
  //   left: 'center',
  //   text: 'Large Area Chart'
  // },
  toolbox: {
    feature: {
      dataZoom: {
        yAxisIndex: 'none',
      },
      restore: {},
      saveAsImage: {},
    },
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: 'value',
    boundaryGap: [0, '100%'],
  },
  dataZoom: [
    {
      type: 'inside',
      start: 0,
      end: 100,
    },
    {
      start: 0,
      end: 100,
    },
  ],
  series: [
    {
      name: '',
      type: 'line',
      symbol: 'none',
      sampling: 'lttb',
      itemStyle: {
        //   color: 'rgb(255, 70, 131)'
      },
      areaStyle: {
        //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        //     {
        //       offset: 0,
        //       color: 'rgb(255, 158, 68)'
        //     },
        //     {
        //       offset: 1,
        //       color: 'rgb(255, 70, 131)'
        //     }
        //   ])
      },
      data: [],
    },
  ],
};
export const EChartTypeMapper: { [key in ChartViewConfig.ChartTypeEnum | 'TREE' | 'GAUGE']: EChartsOption } = {
  VERTICAL_BAR: VerticalBarOptions,
  HORIZONTAL_BAR: HorizontalBarOptions,
  PIE: PieOptions,
  DOUGHNUT: DoughnutOptions,
  LINE: LineOptions,
  AREA: PolarOptions,
  RADAR: RadarOption,
  TREE_MAP: BasicTreeMapOption,
  SUN_BURST: SunBurstOption, //n
  SCATTERED: ScatterOptions, //n
  BUBBLE: undefined, //skip
  TIME_SCALE: TimescaleOption, //skip
  ROSE: undefined, //skip
  SEMI_CIRCLE_DOUGHNUT: SemiCircleDoughnutOption,
  FUNNEL: FunnelOption,
  HEATMAP: undefined, //skip
  SANKEY: SankeyOptions,
  TREE: TreeOptions,
  GAUGE: GaugeOptions,
  // TIME_SCALE: undefined,
  BAR_RACE: undefined,
  LINE_RACE: undefined,
};
export const NonLinearCharts: { [key in ChartViewConfig.ChartTypeEnum]: boolean } = {
  VERTICAL_BAR: false,
  HORIZONTAL_BAR: false,
  PIE: false,
  DOUGHNUT: false,
  LINE: false,
  AREA: false,
  RADAR: false,
  TREE_MAP: false,
  SUN_BURST: true,
  SCATTERED: false,
  BUBBLE: true,
  ROSE: true,
  SEMI_CIRCLE_DOUGHNUT: false,
  FUNNEL: false,
  HEATMAP: true,
  SANKEY: true,
  TIME_SCALE: true,
  BAR_RACE: true,
  LINE_RACE: true,
};
