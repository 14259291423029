<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <!-- @if (!viewModeOnly) { -->
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Field Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="viewModeOnly ? 'view' : fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-dynamic-field-type-selector
        [control]="formGroup?.controls?.type"
        [dataType]="typeDataType"
        [viewMode]="viewModeOnly ? 'view' : fieldViewMode"
        [appendTo]="'body'"
        label="Type"
        [placeholder]="'Search Types'"
      >
      </app-dynamic-field-type-selector>
    </div>
    <!-- } -->
    <div class="collapsible-section w-full">
      <!-- Toggle Button -->
      <!-- @if (formGroup?.controls?.options?.value) {
        <div
          class="flex gap-3 justify-content-start w-full items-center px-2 mb-4 cursor-pointer"
          (click)="toggleOptions()"
        >
          <p class="p-component m-0">{{ 'general.actions.options' | translate }}</p>
          <i class="pi p-component" [ngClass]="isOptionsCollapsed ? 'pi-chevron-down' : 'pi-chevron-up'"></i>
        </div>
      } -->

      <!-- Collapsible Content -->
      <div
        class="transition-all duration-300"
        [ngStyle]="{ height: isOptionsCollapsed ? '0' : 'auto', overflow: isOptionsCollapsed ? 'hidden' : 'visible' }"
      >
        <app-dynamic-field-type-options
          [control]="formGroup?.controls?.options"
          [viewMode]="viewModeOnly ? 'view' : fieldViewMode"
          label="Options"
          [placeholder]="'Options'"
          [fieldType]="formGroup?.controls?.type?.value"
          class="formgrid grid p-fluid w-full m-auto"
        ></app-dynamic-field-type-options>
      </div>
    </div>
  </div>

  <div class="flex flex-row-reverse gap-2">
    @if (fieldViewMode != 'view' && showSaveAndClose) {
      <app-button [action]="submitButtonAction"></app-button>
    }
    @if (showSaveButton) {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }
  </div>
</form>
