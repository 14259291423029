import { Injectable } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private direction: 'ltr' | 'rtl' = 'ltr'; //@NOTE: subscribe to direction service if we get one in future
  private notiType: 'primeng' = 'primeng';

  constructor(
    private messageService: MessageService,
    private localeService: LocaleService
  ) {}

  showPrimengToast(
    title: string,
    message: string,
    severity: primengToastSeverity,
    titleTranslationKeyPrefix: string = 'general.messages.',
    messageTranslationKeyPrefix: string = 'general.messages.',
    placement: primengToastPlacement = null
  ) {
    //@TODO: add onClose handler on the <p-toast> component (specifically make it onClick handler)
    let position = placement
      ? placement
      : this.direction == 'ltr'
        ? primengToastPlacement['top-right']
        : primengToastPlacement['top-left'];
    this.messageService.add({
      severity: severity,
      summary: title ? this.localeService.translate('general.messages.' + title) : null,
      detail: message ? this.localeService.translate('general.messages.' + message) : null,
      key: position,
    });
  }
  success(
    title: string,
    message: string = '',
    payload?: Function,
    titleTranslationKeyPrefix: string = 'general.messages.',
    messageTranslationKeyPrefix: string = 'general.messages.',
    placement: primengToastPlacement = null
  ) {
    this.showPrimengToast(
      title,
      message,
      primengToastSeverity.success,
      titleTranslationKeyPrefix,
      messageTranslationKeyPrefix,
      placement
    );
  }
  warn(
    title: string,
    message: string = '',
    payload?: Function,
    titleTranslationKeyPrefix: string = 'general.messages.',
    messageTranslationKeyPrefix: string = 'general.messages.',
    placement: primengToastPlacement = null
  ) {
    this.showPrimengToast(
      title,
      message,
      primengToastSeverity.warn,
      titleTranslationKeyPrefix,
      messageTranslationKeyPrefix,
      placement
    );
  }
  info(
    title: string,
    message: string = '',
    payload?: (notification) => void,
    notification?,
    titleTranslationKeyPrefix: string = 'general.messages.',
    messageTranslationKeyPrefix: string = 'general.messages.',
    placement: primengToastPlacement = null
  ) {
    this.showPrimengToast(
      title,
      message,
      primengToastSeverity.info,
      titleTranslationKeyPrefix,
      messageTranslationKeyPrefix,
      placement
    );
  }
  error(
    title: string,
    message: string = '',
    payload?: Function,
    titleTranslationKeyPrefix: string = 'general.messages.',
    messageTranslationKeyPrefix: string = 'general.messages.',
    placement: primengToastPlacement = null
  ) {
    this.showPrimengToast(
      title,
      message,
      primengToastSeverity.error,
      titleTranslationKeyPrefix,
      messageTranslationKeyPrefix,
      placement
    );
  }
  show(
    title: string,
    message: string = '',
    payload?: Function,
    titleTranslationKeyPrefix: string = 'general.messages.',
    messageTranslationKeyPrefix: string = 'general.messages.',
    placement: primengToastPlacement = null
  ) {
    this.showPrimengToast(
      title,
      message,
      primengToastSeverity.custom,
      titleTranslationKeyPrefix,
      messageTranslationKeyPrefix,
      placement
    );
  }
}
export enum primengToastPlacement {
  'top-right' = 'tr',
  'top-left' = 'tl',
  'bottom-right' = 'br',
  'bottom-left' = 'bl',
  'top-center' = 'tc',
  'bottom-center' = 'bc',
  'center' = 'c',
}
export enum primengToastSeverity {
  info = 'info',
  warn = 'warn',
  error = 'error',
  success = 'success',
  custom = 'custom',
}
