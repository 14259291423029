import { Component, Input, OnInit } from '@angular/core';
import { IssueType } from '@shared/classes/model/backend/issue';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from '@core/services/locale/locale.service';
import { DataTypeEnum, EscalationDto, isNullObj, ModuleKeywords } from '@shared/classes';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { ViewModeService } from '@shared/services/view-mode.service';
import { BaseForm } from 'app/shared/classes/view/BaseForm';
import { omit } from 'lodash-es';
import { catchError, forkJoin, of } from 'rxjs';
import { EscalationDataService } from '../../services/data/escalation-data.service';
import { IssueTypeDataService } from '../../services/data/issue-type-data.service';

@Component({
  selector: 'app-issue-types-item-form',
  templateUrl: './issue-types-item-form.component.html',
  styleUrls: ['./issue-types-item-form.component.scss'],
})
export class IssueTypesItemFormComponent extends BaseForm<IssueType> implements OnInit {
  @Input()
  itemId: string;

  @Input()
  searchT: string = null;

  @Input()
  editMode: Boolean = false;
  formRepeaterFields = [];
  emitPaused = false;
  LVList = [];
  constructor(
    private requestService: IssueTypeDataService,
    public viewModeService: ViewModeService,
    private escalationService: EscalationDataService,
    public localeService: LocaleService
  ) {
    super(viewModeService, ModuleKeywords.IssueType);
    this.initFormRepeaterFields();
  }
  getData() {
    let escalations = [...(this.formGroup.getRawValue().escalationsDto as EscalationDto[])]
      .sort((a, b) => (a?.order || 0) - (b?.order || 0))
      .map((x) => x.code);
    let dataVal = { ...omit(this.formGroup.getRawValue(), 'escalations', 'escalationsDto'), escalations: escalations };
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(dataVal)
      : this.getChangedFormValues(dataVal).updateItems;
  }

  setData(data: IssueType) {
    this.patchFormData({
      ...data,
      escalationsDto: isNullObj(data?.escalationsDto)
        ? null
        : data?.escalationsDto?.sort((a, b) => (a?.order || 0) - (b?.order || 0)),
    });
    this.data = data;
  }
  ngOnInit(): void {
    this.setupFormSubscribers();
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      resolutionDays: new FormControl(null),
      escalations: new FormControl(null),
      escalationsDto: new FormControl(null),
      description: new FormControl(null),
      lockResolutionDate: new FormControl(true),
    });
  }
  initFormRepeaterFields() {
    this.formRepeaterFields = [
      {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: this.localeService.translate(this.moduleFieldString + '.assignee.label', 'Assignee'),
          name: 'assignee',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: undefined,
            },
          },
          showLabelInViewMode: false,
        },
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: this.localeService.translate(this.moduleFieldString + '.daysToResolve.label', 'Days to Resolve'),
          name: 'daysToResolve',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Number,
          inputOptions: {
            numberInput: {
              showButtons: true,
              min: 0,
              maxFractionDigits: 0,
            },
          },
          showLabelInViewMode: false,
        },
      },
    ];
  }
  setupFormSubscribers() {
    this.handleValueChanges(
      this.formGroup,
      'escalationsDto',
      this.LVList,
      this.getDataKeyValueFormatFromPrev.bind(this),
      this.getDataKeyValueFormat.bind(this),
      {
        patchSingleUpdate: this.escalationService.patchSingleUpdate.bind(this.escalationService),
        create: this.escalationService.create.bind(this.escalationService),
        updateValues: this.updateLV.bind(this),
        getUpdatedValues: () => this.LVList,
      }
    );
  }
  handleValueChanges(
    formGroup,
    controlName,
    oldValues,
    getDataKeyValueFormatFromPrev,
    getDataKeyValueFormat,
    serviceMethods
  ) {
    formGroup.controls[controlName].valueChanges.subscribe(async (newValues) => {
      if (this.emitPaused) return;
      const oldVals = serviceMethods.getUpdatedValues();
      console.log('values changed');
      if (newValues?.length) {
        const forkList = [];

        newValues.forEach((element) => {
          let existedVal = null;

          if (element.code && oldVals?.length) {
            existedVal = oldVals.find((y) => y.code === element.code);

            if (existedVal) {
              const req = getDataKeyValueFormatFromPrev(element, existedVal);
              if (req?.length) {
                forkList.push(serviceMethods.patchSingleUpdate(req, element.code));
              }
            }
          } else if (!element.code) {
            forkList.push(serviceMethods.create(getDataKeyValueFormat({ ...element, code: undefined })));
          }
        });

        if (forkList.length) {
          forkJoin(
            forkList.map((obs) =>
              obs.pipe(
                catchError((err) => {
                  console.error('Error in processing request:', err);
                  return of(null); // Handle error and continue
                })
              )
            )
          ).subscribe({
            next: (responses) => {
              if (responses?.length) {
                responses.forEach((data) => {
                  if (data) {
                    serviceMethods.updateValues(data.data);
                    formGroup.controls[controlName].patchValue(serviceMethods.getUpdatedValues(), { emitEvent: false });
                  } else {
                    formGroup.controls[controlName].patchValue(serviceMethods.getUpdatedValues(), { emitEvent: false });
                  }
                });
              }
            },
          });
        }
      }
    });
  }
  updateLV(newVal) {
    const LVList = this.formGroup.controls.escalationsDto.value ?? [];
    const map = {};

    LVList.forEach((element) => {
      if (element.code) {
        map[element.code] = element;
      }
    });
    if (newVal.code) map[newVal.code] = newVal;
    this.LVList = map ? Object.values(map) : [];
    this.emitPaused = true;
    this.formGroup.controls.escalationsDto.patchValue(this.LVList);
    setTimeout(() => {
      this.emitPaused = false;
    }, 100);
  }
}
