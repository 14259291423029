import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';

@Component({
  selector: 'app-user-and-group-filter',
  templateUrl: './user-and-group-filter.component.html',
  styleUrls: ['./user-and-group-filter.component.scss'],
})
export class UserAndGroupFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }

  initInput() {
    this.dropDownInput = {
      componentType: UserAndGroupSelectorComponent,
      options: {
        label: '',
        name: '',
        placeholder: 'Search...',
        control: new FormControl(null),
        inputOptions: {
          extra: { principleModeSelection: 'ALL', principleMode: true, itemTypes: ['RESPONSIBILITIES', 'GROUPS', 'USERS'] },
          dropDownInput: {
            treeDropDown: true,
            optionValue: 'undefined',
            multi: this.col?.filter?.matchMode == 'in' ? true : false,
            items: null,
            optionLabel: undefined,
          },
        },
        showLabelInViewMode: false,
      },
    };
  }

}
