import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataTypeEnum, IFieldDefinition, RelationTypeDto, getEnumOptions } from '@shared/classes';
import { IconSelectorComponent } from '@shared/components/selectors/icon-selector/icon-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { ColorInputComponent } from '@shared/components/ui/input-fields/color-input/color-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class RelationTypesMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Icon',
      key: 'icon',
      dataType: DataTypeEnum.Icon,
      required: false,
      showInTable: true,
      description: 'Record Icon',
      tableDefinition: {
        sortDisabled: true,
      },
      useInBulk: true,
      formField: {
        componentType: IconSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: true,
      description: 'Record Name',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter Name',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },

    {
      name: 'Color',
      key: 'color',
      dataType: DataTypeEnum.Color,
      required: true,
      showInTable: true,
      description: 'Record Color',
      tableDefinition: {
        filter: {
          type: 'color',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: true,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: ColorInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null, Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'System Generated',
      key: 'systemGenerated',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Is the record system generated?',
      tableDefinition: {
        filter: {
          type: 'boolean',
          display: 'menu',
          matchMode: 'equals',
        },
      },
    },
    {
      name: 'Risk Cascade Direction',
      key: 'riskCascadeDirection',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Risk Cascade Direction of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RelationTypeDto.RiskCascadeDirectionEnum,
        },
      },
      useInBulk: true,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(RelationTypeDto.RiskCascadeDirectionEnum),
              multi: false
            },
          },
        },
      },

    },
    {
      name: 'Compliance Mode',
      key: 'complianceMode',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Current Compliance Mode of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RelationTypeDto.ComplianceModeEnum,
        },
      },
      useInBulk: true,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(RelationTypeDto.ComplianceModeEnum),
              multi: false
            },
          },
        },
      },

    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: true,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },

    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
