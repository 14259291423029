@switch (col.filter.type) {
  @case ('dropdown') {
    <app-selector-filter [col]="col"></app-selector-filter>
  }
  @case ('enum') {
    <app-enum-filter [col]="col"></app-enum-filter>
  }
  @case ('recordCode') {
    <app-record-code-filter [col]="col"></app-record-code-filter>
  }
  @case ('multiDropdown') {
    <app-selector-filter [col]="col"></app-selector-filter>
  }
  @case ('date') {
    <app-date-filter [col]="col"></app-date-filter>
  }
  @case ('boolean') {
    <app-boolean-filter [col]="col"></app-boolean-filter>
  }
  @case ('userSelector') {
    <app-user-selector-filter [col]="col"></app-user-selector-filter>
  }
  @case ('userAndGroupSelector') {
    <app-user-and-group-filter [col]="col"></app-user-and-group-filter>
  }
  @case ('tagsSelector') {
    <app-tags-selector-filter [col]="col" [control]="tagsControl"></app-tags-selector-filter>
  }
  @case ('numeric') {
    <app-number-filter [col]="col"></app-number-filter>
  }
  @case ('relation') {
    <app-relation-form-filter [col]="col"></app-relation-form-filter>
  }
  @case ('color') {
    <app-color-filter [col]="col"></app-color-filter>
  }
  @default {
    <app-default-filter [col]="col"></app-default-filter>
  }
}
