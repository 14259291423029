import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFilter, IDynamicComponent } from '@shared/classes';
import { ColorInputComponent } from '@shared/components/ui/input-fields/color-input/color-input.component';

@Component({
  selector: 'app-color-filter',
  templateUrl: './color-filter.component.html',
  styleUrl: './color-filter.component.scss'
})
export class ColorFilterComponent extends BaseFilter implements OnInit {
  dropDownInput?: IDynamicComponent;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initInput();
  }
  initInput() {
    this.dropDownInput = {
      componentType: ColorInputComponent,
      options: {
        name: 'color',
        label: '',
        placeholder: '',
        control: new FormControl(null),
      },
    };
  }
}
