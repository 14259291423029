import { toRouteCase } from '../helpers';
import { ModuleKeywords } from './ModuleKeywords';
namespace ModuleRoutePrefixs {
  export type ModuleRoutePrefixEnum =
    | 'ENTITY'
    // | 'QUESTION'
    | 'QUESTION_MANAGEMENT'
    | 'RISK'
    | 'FRAMEWORK'
    | 'FRAMEWORK/POLICY'
    | 'FRAMEWORK/AUTHORITY'
    | 'FRAMEWORK/PUBLISHED'
    | 'ACTIVITY_FEED'
    | 'ACTIVITY_FEED/TASK'
    | 'ACTIVITY_FEED/NOTIFICATION'
    | 'USER_MANAGEMENT'
    // | 'ISSUE'
    // | 'ACKNOWLEDGMENT'
    | 'ACKNOWLEDGMENT_MANAGEMENT'
    | 'DATA_MANAGEMENT'
    | 'DOCUMENT'
    | 'AUDIT'
    | 'GLOBAL_PACKAGE'
    | 'TRANSPARENCY_MANAGEMENT'
    | 'PERFORMANCE'
    | 'RELATION';
  export const ModuleRoutePrefixEnum = {
    Entity: 'ENTITY' as ModuleRoutePrefixEnum,
    // Question: 'QUESTION' as ModuleRoutePrefixEnum,
    QuestionManagement: 'QUESTION_MANAGEMENT' as ModuleRoutePrefixEnum,
    Risk: 'RISK' as ModuleRoutePrefixEnum,
    Framework: 'FRAMEWORK' as ModuleRoutePrefixEnum,
    FrameworkPolicy: 'FRAMEWORK/POLICY' as ModuleRoutePrefixEnum,
    FrameworkAuthority: 'FRAMEWORK/AUTHORITY' as ModuleRoutePrefixEnum,
    FrameworkPublished: 'FRAMEWORK/PUBLISHED' as ModuleRoutePrefixEnum,
    ActivityFeed: 'ACTIVITY_FEED' as ModuleRoutePrefixEnum,
    ActivityFeedTask: 'ACTIVITY_FEED/TASK' as ModuleRoutePrefixEnum,
    ActivityFeedNotification: 'ACTIVITY_FEED/NOTIFICATION' as ModuleRoutePrefixEnum,
    UserManagement: 'USER_MANAGEMENT' as ModuleRoutePrefixEnum,
    // Issue: 'ISSUE' as ModuleRoutePrefixEnum,
    IssueManagement: 'ISSUE_MANAGEMENT' as ModuleRoutePrefixEnum,
    // Acknowledgment: 'ACKNOWLEDGMENT' as ModuleRoutePrefixEnum,
    AcknowledgmentManagement: 'ACKNOWLEDGMENT_MANAGEMENT' as ModuleRoutePrefixEnum,
    DataManagement: 'DATA_MANAGEMENT' as ModuleRoutePrefixEnum,
    Document: 'DOCUMENT' as ModuleRoutePrefixEnum,
    Audit: 'AUDIT' as ModuleRoutePrefixEnum,
    GlobalPackage: 'GLOBAL_PACKAGE' as ModuleRoutePrefixEnum,
    TransparencyManagement: 'TRANSPARENCY_MANAGEMENT' as ModuleRoutePrefixEnum,
    Performance: 'PERFORMANCE' as ModuleRoutePrefixEnum,
    Relation: 'RELATION' as ModuleRoutePrefixEnum,
  };
}
const ModuleRoutePrefix = { ...ModuleRoutePrefixs.ModuleRoutePrefixEnum };
// Define the final type
type ModuleRoutePrefixType = (typeof ModuleRoutePrefix)[keyof typeof ModuleRoutePrefix];

export type ModuleRoutePrefix = ModuleRoutePrefixType;

export { ModuleRoutePrefix };

export const CodeMapper = {
  //@TODONewModule: when you add new module make sure to define these
  // ENT : ModuleKeywords.Entity,
  RES: ModuleKeywords.Responsibility,
  AST: ModuleKeywords.Asset,
  OBJ: ModuleKeywords.Objective,
  EVE: ModuleKeywords.Event,
  GEO: ModuleKeywords.Geography,
  PRO: ModuleKeywords.Process,
  ETY: ModuleKeywords.EntityType,

  POL: ModuleKeywords.Policy,
  PBD: ModuleKeywords.PolicyBody,
  CON: ModuleKeywords.Control,
  COB: ModuleKeywords.ControlObjective,
  CGR: ModuleKeywords.ControlGroup,
  CCA: ModuleKeywords.ControlCategory,
  CPR: ModuleKeywords.ControlProcedure,
  EXP: ModuleKeywords.Exception,
  ATS: ModuleKeywords.Attestation,

  ACK: ModuleKeywords.Acknowledgment,
  AKR: ModuleKeywords.AcknowledgmentRequest,

  TSK: ModuleKeywords.Task,
  NTO: ModuleKeywords.Notification,

  // IFA : ModuleKeywords.ImpactFactor,
  RIT: ModuleKeywords.RiskItem,
  RAR: ModuleKeywords.RiskAssessmentRequest,
  RPR: ModuleKeywords.RiskProject,
  RST: ModuleKeywords.RiskStatement,
  RSC: ModuleKeywords.RiskStatementCategory,
  THR: ModuleKeywords.RiskThreat,
  VUL: ModuleKeywords.RiskVulnerability,

  AUD: ModuleKeywords.AuthorityDocument,
  CIT: ModuleKeywords.Citation,
  CTY: ModuleKeywords.CitationType,

  ISS: ModuleKeywords.Issue,
  IST: ModuleKeywords.IssueType,
  RMD: ModuleKeywords.Remediation, //@TODO CHECK

  QST: ModuleKeywords.Question,
  QSR: ModuleKeywords.Questionnaire,
  QSV: ModuleKeywords.QuestionnaireValue, //@TODO CHECK

  FDT: ModuleKeywords.FieldTemplate,
  ORG: ModuleKeywords.Organization,
  RTY: ModuleKeywords.RelationType,
  EXM: ModuleKeywords.Exemption,
  GVL: ModuleKeywords.GlobalValueList,
  PBS: ModuleKeywords.PolicyBodySection,
  STA: ModuleKeywords.Standard,
  // STS: ModuleKeywords.StandardSection,
  GUI: ModuleKeywords.Guideline,
  CRE: ModuleKeywords.ComplianceReport,
  CRR: ModuleKeywords.ComplianceReportResult,
  GAR: ModuleKeywords.ControlsGapReport,
  GRR: ModuleKeywords.ControlsGapReportResult,
  ETM: ModuleKeywords.EmailTemplate,
  TTM: ModuleKeywords.TaskTemplate,
  // CCE: ModuleKeywords.CitationComplianceReport,
  // CCR: ModuleKeywords.CitationComplianceReportResult,
  ADT: ModuleKeywords.AuditTrail,
  DOC: ModuleKeywords.Document,

  AUU: ModuleKeywords.AuditUniverse,
  APG: ModuleKeywords.AuditProgram,
  ARR: ModuleKeywords.AuditProgramReport,
  APR: ModuleKeywords.AuditProjectReport,
  APT: ModuleKeywords.AuditProgramType,
  ATT: ModuleKeywords.AuditCaseTemplate,
  ACT: ModuleKeywords.AuditCaseTemplate,
  APJ: ModuleKeywords.AuditProject,
  ACS: ModuleKeywords.AuditCase,
  ACR: ModuleKeywords.AuditCaseResult,
  AEX: ModuleKeywords.AuditExpense,
  ARE: ModuleKeywords.AuditRequest,
  FIL: ModuleKeywords.Filter,
  CVW: ModuleKeywords.ContentView,
  DSH: ModuleKeywords.Dashboard,
  EGP: ModuleKeywords.ExportGlobalPackage,
  IGP: ModuleKeywords.ImportGlobalPackage,
  REP: ModuleKeywords.Report,
  TCC: ModuleKeywords.TransparencyCaseCategory,
  TCA: ModuleKeywords.TransparencyCase,
  SCO: ModuleKeywords.StatisticCollector,
  GST: ModuleKeywords.GeneratedStatistic,
  RCT: ModuleKeywords.ReportConfigurationTemplate,
  TAG: ModuleKeywords.Tag,
  RPS: ModuleKeywords.ReportScheduler,
  MTR: ModuleKeywords.Metric,
  MTV: ModuleKeywords.MetricValue,
  MTC: ModuleKeywords.MetricCategory,
  MTH: ModuleKeywords.RiskMethodology,
  GRE: ModuleKeywords.GeneratedReport,
  LNG: ModuleKeywords.Language,
  TEX: ModuleKeywords.Campaign,
  QRQ: ModuleKeywords.QuestionnaireRequest,
  WRK: ModuleKeywords.Workspace,
  QTY: ModuleKeywords.QuestionnaireType,
  AOB: ModuleKeywords.AuditObjective,
  RAC: ModuleKeywords.RemediationAction,
  JER: ModuleKeywords.Evidence,
  PTE: ModuleKeywords.PolicyBodyTemplate,
  PBT: ModuleKeywords.PolicyBodySectionTemplate,
  EVI: ModuleKeywords.Evidence
};
export const ModuleKeywordRootPath = {
  //@TODONewModule: when you add new module make sure to define these
  // [ModuleKeywords.EntityLevels] : 'entity',
  ['EntityFilters']: toRouteCase(ModuleRoutePrefix.Entity),
  [ModuleKeywords.EntityType]: toRouteCase(ModuleRoutePrefix.Entity),
  [ModuleKeywords.Asset]: toRouteCase(ModuleRoutePrefix.Entity),
  [ModuleKeywords.Event]: toRouteCase(ModuleRoutePrefix.Entity),
  [ModuleKeywords.Geography]: toRouteCase(ModuleRoutePrefix.Entity),
  [ModuleKeywords.Objective]: toRouteCase(ModuleRoutePrefix.Entity),
  [ModuleKeywords.Process]: toRouteCase(ModuleRoutePrefix.Entity),
  [ModuleKeywords.Responsibility]: toRouteCase(ModuleRoutePrefix.Entity),
  // [ModuleKeywords.Entity] : null,
  [ModuleKeywords.Question]: toRouteCase(ModuleRoutePrefix.QuestionManagement),
  [ModuleKeywords.Questionnaire]: toRouteCase(ModuleRoutePrefix.QuestionManagement),
  [ModuleKeywords.QuestionnaireValue]: toRouteCase(ModuleRoutePrefix.QuestionManagement),
  [ModuleKeywords.MyQuestionnaireValue]: toRouteCase(ModuleRoutePrefix.QuestionManagement),
  [ModuleKeywords.Campaign]: toRouteCase(ModuleRoutePrefix.QuestionManagement),
  [ModuleKeywords.QuestionnaireRequest]: toRouteCase(ModuleRoutePrefix.QuestionManagement),
  [ModuleKeywords.QuestionnaireType]: toRouteCase(ModuleRoutePrefix.QuestionManagement),
  [ModuleKeywords.RiskItem]: toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.RiskRegister]: toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.RiskProject]: toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.RiskAssessmentRequest]: toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.MyRiskAssessmentRequest]: toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.RiskStatement]: toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.RiskStatementCategory]: toRouteCase(ModuleRoutePrefix.Risk),
  // [ModuleKeywords.ImpactFactor] : toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.RiskMethodology]: toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.RiskVulnerability]: toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.RiskThreat]: toRouteCase(ModuleRoutePrefix.Risk),
  [ModuleKeywords.Control]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.ControlGroup]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.Policy]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.PolicyBody]: toRouteCase(ModuleRoutePrefix.FrameworkPolicy),
  [ModuleKeywords.PolicyBodyTemplate]: toRouteCase(ModuleRoutePrefix.FrameworkPolicy),
  [ModuleKeywords.PolicyBodySection]: toRouteCase(ModuleRoutePrefix.FrameworkPolicy),
  [ModuleKeywords.PolicyBodySectionTemplate]: toRouteCase(ModuleRoutePrefix.FrameworkPolicy),
  [ModuleKeywords.PublishedPolicy]: toRouteCase(ModuleRoutePrefix.FrameworkPublished),
  [ModuleKeywords.PublishedStandard]: toRouteCase(ModuleRoutePrefix.FrameworkPublished),
  [ModuleKeywords.PublishedGuideline]: toRouteCase(ModuleRoutePrefix.FrameworkPublished),
  [ModuleKeywords.Exception]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.Attestation]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.ComplianceReport]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.ComplianceReportResult]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.ControlsGapReport]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.ControlsGapReportResult]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.AuthorityDocument]: toRouteCase(ModuleRoutePrefix.FrameworkAuthority),
  [ModuleKeywords.CitationType]: toRouteCase(ModuleRoutePrefix.FrameworkAuthority),
  [ModuleKeywords.Citation]: toRouteCase(ModuleRoutePrefix.FrameworkAuthority),
  [ModuleKeywords.Exemption]: toRouteCase(ModuleRoutePrefix.FrameworkAuthority),
  // [ModuleKeywords.CitationComplianceReport]: 'authority',
  // [ModuleKeywords.CitationComplianceReportResult]: 'authority',
  [ModuleKeywords.Task]: toRouteCase(ModuleRoutePrefix.ActivityFeed),
  [ModuleKeywords.MyTask]: toRouteCase(ModuleRoutePrefix.ActivityFeedTask),
  [ModuleKeywords.Notification]: toRouteCase(ModuleRoutePrefix.ActivityFeed),
  [ModuleKeywords.MyNotification]: toRouteCase(ModuleRoutePrefix.ActivityFeedNotification),
  [ModuleKeywords.User]: toRouteCase(ModuleRoutePrefix.UserManagement),
  [ModuleKeywords.Role]: toRouteCase(ModuleRoutePrefix.UserManagement),
  [ModuleKeywords.Group]: toRouteCase(ModuleRoutePrefix.UserManagement),
  [ModuleKeywords.Finding]: toRouteCase(ModuleRoutePrefix.IssueManagement),
  [ModuleKeywords.Issue]: toRouteCase(ModuleRoutePrefix.IssueManagement),
  [ModuleKeywords.IssueType]: toRouteCase(ModuleRoutePrefix.IssueManagement),
  [ModuleKeywords.Remediation]: toRouteCase(ModuleRoutePrefix.IssueManagement),
  [ModuleKeywords.RemediationAction]: toRouteCase(ModuleRoutePrefix.IssueManagement),
  [ModuleKeywords.Acknowledgment]: toRouteCase(ModuleRoutePrefix.AcknowledgmentManagement),
  [ModuleKeywords.AcknowledgmentRequest]: toRouteCase(ModuleRoutePrefix.AcknowledgmentManagement),
  [ModuleKeywords.MyAcknowledgment]: toRouteCase(ModuleRoutePrefix.AcknowledgmentManagement),
  [ModuleKeywords.FieldTemplate]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.Organization]: toRouteCase(ModuleRoutePrefix.Entity),
  [ModuleKeywords.RelationType]: toRouteCase(ModuleRoutePrefix.Relation),
  // [ModuleKeywords.RelationTypes] : 'data-management',
  [ModuleKeywords.GlobalValueList]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.Filter]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.ContentView]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.Dashboard]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.Report]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.ReportScheduler]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.ReportConfigurationTemplate]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.StatisticCollector]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.GeneratedStatistic]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.GeneratedReport]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.Tag]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.Language]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.Workspace]: toRouteCase(ModuleRoutePrefix.DataManagement),
  [ModuleKeywords.EmailTemplate]: toRouteCase(ModuleRoutePrefix.ActivityFeed),
  [ModuleKeywords.TaskTemplate]: toRouteCase(ModuleRoutePrefix.ActivityFeed),
  [ModuleKeywords.Document]: toRouteCase(ModuleRoutePrefix.Document), //@TODO:Document
  [ModuleKeywords.AuditTrail]: toRouteCase(ModuleRoutePrefix.ActivityFeed), //@TODO:AuditTrail
  [ModuleKeywords.AuditUniverse]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditProgram]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditProgramReport]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditProgramType]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditCaseTemplate]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditProject]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditProjectReport]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditCase]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditCaseResult]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditRequest]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditExpense]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.AuditObjective]: toRouteCase(ModuleRoutePrefix.Audit),
  [ModuleKeywords.ExportGlobalPackage]: toRouteCase(ModuleRoutePrefix.GlobalPackage),
  [ModuleKeywords.ImportGlobalPackage]: toRouteCase(ModuleRoutePrefix.GlobalPackage),
  [ModuleKeywords.TransparencyCaseCategory]: toRouteCase(ModuleRoutePrefix.TransparencyManagement),
  [ModuleKeywords.TransparencyCase]: toRouteCase(ModuleRoutePrefix.TransparencyManagement),
  [ModuleKeywords.Standard]: toRouteCase(ModuleRoutePrefix.Framework),
  // [ModuleKeywords.StandardSection]: 'framework/standard',
  [ModuleKeywords.Guideline]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.ControlGroup]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.ControlCategory]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.ControlProcedure]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.ControlObjective]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.Evidence]: toRouteCase(ModuleRoutePrefix.Framework),
  [ModuleKeywords.Metric]: toRouteCase(ModuleRoutePrefix.Performance),
  [ModuleKeywords.MetricValue]: toRouteCase(ModuleRoutePrefix.Performance),
  [ModuleKeywords.MetricCategory]: toRouteCase(ModuleRoutePrefix.Performance),
};
