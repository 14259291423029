import { Component, OnInit } from '@angular/core';
import { BaseViewItem, locales } from '@shared/classes';
import { LanguageDataService } from 'app/modules/data-structure-module/services/data/language-data.service';

@Component({
  selector: 'app-language-code-view',
  templateUrl: './language-code-view.component.html',
  styleUrl: './language-code-view.component.scss',
})
export class LanguageCodeViewComponent extends BaseViewItem implements OnInit {
  item: any;
  localesMap = {};
  isLoading = true;
  constructor(private languageService: LanguageDataService) {
    super();
  }

  ngOnInit(): void {
    locales.forEach((locale) => {
      this.localesMap[locale.locale] = locale.code.toLowerCase();
    });
  }
  onSetData() {
    if (!this.data) {
      this.isLoading = false;
    } else {
      this.isLoading = true;
      this.languageService.downloadAllLanguagesWithFiles().subscribe({
        next: (allLangs) => {
          this.item = allLangs.find((x) => x.langCode == this.data || x.code == this.data);
          this.isLoading = false;
        },
      });
    }
  }
}
