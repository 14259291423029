import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  AppPermissions,
  DataTypeEnum,
  IFieldDefinition,
  QuestionnaireAnswer,
  QuestionnaireAnswerDto,
  TargetTypeEnum,
  getEnumOptions
} from '@shared/classes';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireValueMappingService extends BaseFieldDefinitionsService {
  recordFields: IFieldDefinition[] = [
    {
      name: 'Questionnaire',
      key: 'questionnaire',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Linked Questionnaire',
      permissions: AppPermissions.ReadQuestionnaire,
      tableDefinition: {
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Questionnaire,
        },
      },
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Items',
          control: new FormControl(null),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['QUESTIONNAIRE'],
            },
            dropDownInput: {
              multi: false,
              items: null,
              customProjectionFields: ['code', 'recordStatus', 'label', 'name'],
              optionLabel: 'name',
              optionValue: 'code'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Questionnaire Type',
      key: 'questionnaireType',
      dataType: DataTypeEnum.CodeLink,
      required: true,
      showInTable: true,
      description: 'Record Type',
      tableDefinition: {
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
    },
    {
      name: 'Scoring Type',
      key: 'scoringType',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      useInBulk: false,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(QuestionnaireAnswer.ScoringTypeEnum),
              multi: false,
            },
          },
        },
      },
    },
    // {
    //   name: 'Scored Questionnaire',
    //   key: 'scoredQuestionnaire',
    //   dataType: DataTypeEnum.Text,
    //   required: true,
    //   showInTable: false,
    //   formField: {
    //     componentType: SwitchInputComponent,
    //     options: {
    //       label: '',
    //       name: '',
    //       control: new FormControl(null),
    //     },
    //   },
    // },
    {
      name: 'Score Status',
      key: 'scoreStatus',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: true,
      useInBulk: false,
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Select Item',
          control: new FormControl(null),
          inputOptions: {
            dropDownInput: {
              optionLabel: 'label',
              optionValue: 'value',
              appendTo: 'body',
              items: getEnumOptions(QuestionnaireAnswerDto.ScoreStatusEnum),
              multi: false,
            },
          },
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.LongWithHoverText,
      required: true,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },

    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
