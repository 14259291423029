<app-base-page-item
  [data]="data"
  [recordStatusActions]="recordStatusActions"
  [extendLockTimeAction]="extendLockTimeAction"
  [showLocked]="showLocked"
  [showCommentsButton]="editMode && viewModeService.viewMode != 'create'"
  [showStateMachineAuditTrail]="showStateMachineAuditTrail"
  [showHistoryButton]="editMode && viewModeService.viewMode != 'create'"
  [showAuditButton]="editMode && viewModeService.viewMode != 'create'"
  [showAddCommentButton]="editMode && viewModeService.viewMode != 'create'"
  [showShareButton]="editMode && viewModeService.viewMode != 'create'"
  [showRecordStatus]="editMode && viewModeService.viewMode != 'create'"
  [breadCrumb]="breadCrumb"
  [sourceCode]="itemId"
  [hasEditChanges]="hasEditChanges$ | async"
  [fromType]="fromType"
  [showRelations]="editMode && viewModeService.viewMode != 'create'"
  [showViewSwitch]="editMode && viewModeService.viewMode != 'create'"
  (onViewModeChange)="onViewModeChange($event)"
  [moduleKeyword]="moduleKeyword"
>
  <ng-container page-header>
    <div class="flex flex-wrap gap-3">
      <h4 class="mb-0">
        {{ 'general.actions.' + (editMode ? viewModeService.viewMode : 'add') | translate }}
        {{
          'modules.' +
            routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword]) +
            '.' +
            toCamelCase(this.moduleKeyword) +
            '.' +
            toCamelCase(this.moduleKeyword) | translate
        }}
      </h4>
      @if (editMode) {
        <div class="align-self-center">
          <app-badge-item
            [status]="data?.issueStatus"
            [text]="data?.issueStatus"
            (click)="showStateMachine()"
            class="cursor-pointer"
          ></app-badge-item>
        </div>
      }
    </div>
  </ng-container>
  @if (editMode) {
    @if (escalationsEventList?.length > 0) {
      <div class="w-full">
        <div class="field">
          <span class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-center">
            <label [class]="'md:w-12rem md:max-w-12rem md:min-w-12rem input-field-label '">
              {{
                'modules.' +
                  routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword]) +
                  '.' +
                  toCamelCase(this.moduleKeyword) +
                  '.fields.escalations.label' | translate: 'Escalations'
              }}
            </label>
            <app-timeline-container
              style="display: block; width: 100%"
              [events]="escalationsEventList"
            ></app-timeline-container>

            <!-- <p-timeline class="escalation-timeline w-full" [value]="escalationsEventList" layout="horizontal">
              <ng-template pTemplate="marker" let-event>
                <span
                  class="flex align-items-center justify-content-center text-white border-circle z-1 shadow-1"
                  [class.bg-green-500]="event.date"
                  [class.bg-surface-300]="!event.date"
                  style="width: 1.6rem; height: 1.6rem"
                >
                  <i [class]="event.date ? 'pi pi-check' : 'pi pi-circle'"></i>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <app-user-list-view [data]="event?.newAssignee?.name" [showName]="false"></app-user-list-view>
              </ng-template>
              <ng-template pTemplate="opposite" let-event>
                @if (event?.date) {
                  <app-date-view [data]="event?.date" [dateFormat]="'short'"></app-date-view>
                }
              </ng-template>
            </p-timeline> -->
          </span>
        </div>
      </div>
    }
  }
  <app-issues-item-form
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    (formSubmitInPlace)="onSubmit($event, 'NONE')"
    (cancelEvent)="onCancel($event)"
    [resetForm]="resetFormTrigger"
    (valueEditChanged)="valueEditChanged($event)"
    [itemId]="itemId"
    [editMode]="editMode"
    [formData]="data"
  ></app-issues-item-form>

  <ng-container page-actions>
    @if (viewMode == 'edit' && showRuleHandler) {
      <app-flat-rule-handler
        [actionMap]="actionsMap"
        [config]="{ requestService: requestService, itemId: itemId }"
      ></app-flat-rule-handler>
    }
  </ng-container>
  <!-- @if (editMode) {
    <ng-container below-main-card>
      <app-base-page [showViewSwitch]="editMode">
        <ng-container page-header>
          <div class="flex flex-wrap gap-3">
            <h4 class="mb-0">Remediation Plans</h4>
          </div>
        </ng-container>
        <app-table
          [cols]="cols"
          [tableActions]="[]"
          [actionCol]="null"
          [inputData]="data?.remediations"
          [offlineConfig]="offlineConfig"
          [passIndexWithRowDataToAction]="true"
          [sessionStorageKey]="undefined"
          [selectionMode]="null"
          class="w-full"
        ></app-table>
      </app-base-page>
    </ng-container>
  } -->
</app-base-page-item>
