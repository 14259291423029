import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  BaseFileViewerItem,
  DocumentVersionsDto,
  FileViewerType,
  IAction,
  MessageKeys,
  getFileViewerType,
} from '@shared/classes';
import { AppDialogService, ToastService } from '@shared/services';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-file-viewer-popup',
  templateUrl: './file-viewer-popup.component.html',
  styleUrl: './file-viewer-popup.component.scss',
})
export class FileViewerPopupComponent extends BaseFileViewerItem implements OnInit {
  progress: number = 0;
  inProgress: boolean = false;
  progressBg: string;
  documentUrl: string = '';
  documentName: string;
  lastDownloadedFile: Blob;
  isLoading: boolean = false;
  showDeleteButton: boolean = false;
  documentData: DocumentVersionsDto;
  documentExtension: string;
  externalCode: string;
  downloadAttachmentAction: IAction = {
    id: 1,
    icon: 'pi pi-cloud-download',
    iconPos: 'left',
    color: 'primary',
    command: this.downloadAttachment.bind(this, true),
    label: 'Download',
    buttonStyle: 'outlined',
  };
  deleteAttachmentAction: IAction = {
    id: 2,
    icon: 'pi pi-trash',
    iconPos: 'left',
    color: 'danger',
    command: this.deleteAttachment.bind(this),
    label: 'Delete',
    buttonStyle: 'outlined',
    passEvent: true,
  };
  cancelRequestAction: IAction = {
    id: 3,
    icon: 'pi pi-times',
    iconPos: 'left',
    color: 'warning',
    command: this.cancelDownload.bind(this),
    buttonStyle: 'outlined',
    label: 'Stop',
  };
  hasPreviewer: boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig<FileViewerPopupOptions>,
    private toast: ToastService,
    private requestService: FilesDataService,
    private appDialogService: AppDialogService
  ) {
    super();
    this.documentUrl = this.config?.data?.documentUrl;
    this.documentName = this.config?.data?.documentName;
    this.documentExtension = this.config?.data?.documentExtension;
    this.documentData = this.config?.data?.documentData;
    this.progressBg = this.config?.data?.progressBg;
    this.lastDownloadedFile = this.config?.data?.lastDownloadedFile;
    this.showDeleteButton = this.config?.data?.showDeleteButton;
    this.fileViewerType = getFileViewerType(this.documentName);
    this.hasPreviewer = this.fileViewerType != FileViewerType.OTHER;
    this.externalCode = this.config?.data?.externalCode;
  }
  ngOnInit(): void {
    if (this.hasPreviewer) {
      this.downloadAttachment();
    } else {
      this.data = { file: null, fileName: this.documentName };
    }
  }
  downloadAttachment(saveFile: boolean = false) {
    if (this.lastDownloadedFile) {
      if (!this.data) this.data = { file: this.lastDownloadedFile, fileName: this.documentName };
      if (saveFile) {
        this.saveFileToDisk(this.lastDownloadedFile, this.documentName);
      }
    } else {
      this.inProgress = true;
      this.progress = 0;
      this.subs.sink = this.requestService.downloadFileWithProgress(this.documentUrl).subscribe({
        next: (event) => {
          if (event.type === HttpEventType.DownloadProgress) {
            this.inProgress = true;

            this.progress = (event.loaded / event.total) * 100;
          }
          if (event.type === HttpEventType.Response) {
            this.inProgress = false;
            this.progress = 0;

            const response = event;
            const blobObj = new Blob([response.body], { type: response.body.type });
            const contentDisposition = response.headers.get('content-disposition');

            if (contentDisposition != null) {
              const fileName = contentDisposition
                .split(';')[1]
                .split('filename')[1]
                .split('=')[1]
                .replace(/["]+/g, '')
                .trim();
              this.documentName = fileName;
            } else {
              this.documentName = this.documentName || 'file';
            }
            this.lastDownloadedFile = blobObj;
            this.data = { file: blobObj, fileName: this.documentName };
            if (saveFile) {
              this.saveFileToDisk(this.lastDownloadedFile, this.documentName);
            }
          }
        },
        error: (err) => {
          this.inProgress = false;
          this.progress = 0;
          this.toast.error(MessageKeys.errorHappened, MessageKeys.tryAgainLater);
        },
      });
    }
  }
  onDestroy(): void {
    this.subs.unsubscribe();
    this.progress = 0;
    this.inProgress = false;
  }
  cancelDownload() {
    this.subs.unsubscribe();
    this.inProgress = false;
    this.progress = 0;
  }
  deleteAttachment(event) {
    this.appDialogService.confirmPopup(
      {
        accept: () => {
          // this.requestService.moveToTrash([this.documentData?.document]).subscribe((res) => {
          if (this.externalCode) {
            this.requestService.moveToTrashWithCode(this.externalCode, [this.documentData?.document]).subscribe((res) => {
              // this.data = null;
              this.ref.close({ deleted: true });
              // this.onDeleteItem.emit(true);
            });
          } else {
            this.requestService.moveToTrash([this.documentData?.document]).subscribe((res) => {
              // this.data = null;
              this.ref.close({ deleted: true });
              // this.onDeleteItem.emit(true);
            });
          }
        },
      },
      event?.target,
      {
        message: `Delete attachment?`,
        header: 'Delete',
        icon: 'pi pi-exclamation-triangle',
      }
    );
  }
  saveFileToDisk(blob: Blob, fileName: string) {
    const data = URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    // this.progress = 100;
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}
export interface FileViewerPopupOptions {
  progressBg: string;
  documentUrl: string;
  documentName: string;
  documentExtension: string;
  lastDownloadedFile: Blob;
  showDeleteButton: boolean;
  documentData: DocumentVersionsDto;
  externalCode: string
}
