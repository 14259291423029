import { AfterViewInit, Component, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EditorComponent } from '@progress/kendo-angular-editor';
import { EditorCommand } from '@progress/kendo-angular-editor/common/commands';
import { RTL } from '@progress/kendo-angular-l10n';
import { SVGIcon, filterAddExpressionIcon } from '@progress/kendo-svg-icons';
import { ConfigService } from 'app/app.config.service';
import { TextEditorDialogComponent } from './dialog.component';

@Component({
  selector: 'app-kendo-editor',
  templateUrl: './kendo-editor.component.html',
  styleUrl: './kendo-editor.component.scss',
  host: {
    '[style.width]': "'100%'",
    '[style.min-width]': '0',
  },
  providers: [
    {
      provide: RTL,
      useFactory: (injector: Injector) => {
        const isRtl = document?.body?.dir == 'rtl';
        return isRtl;
      },
      deps: [Injector],
    },
  ],
})
export class KendoEditorComponent implements AfterViewInit {
  @ViewChild('upload') public dialog: TextEditorDialogComponent;

  @ViewChild('kendoEditor') kendoEditor: EditorComponent;
  @Input() fControl: FormControl;
  @Input() advanced: boolean = false;
  @Input() placeholder: string;
  @Output() onCustomDropdownOpen: EventEmitter<any> = new EventEmitter();
  private _customDropdownData: KendoEditorDropdownToolbarData[] = [];
  @Input() set customDropdownData(data: KendoEditorDropdownToolbarData[]) {
    this._customDropdownData = data.map((x) => {
      return { ...x };
    });
  }
  get customDropdownData() {
    return this._customDropdownData;
  }
  customDropdownIcon = filterAddExpressionIcon;
  readonly fontFamily = `font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';`;
  customStyleContent = `body{color:#44486d; ${this.fontFamily}} ${editorStyles}`;
  showEditor = true;
  constructor(private configService: ConfigService) {}
  ngAfterViewInit(): void {
    if (this.configService?.config?.dark) {
      this.customStyleContent = `body{color:#ffffff; ${this.fontFamily}}  ${editorStyles}`;
    } else {
      this.customStyleContent = `body{color:#44486d; ${this.fontFamily}}  ${editorStyles}`;
    }
    this.configService.configUpdate$.subscribe((appConfig) => {
      const newColor = appConfig?.dark
        ? `body{color:#ffffff; ${this.fontFamily}}  ${editorStyles}`
        : `body{color:#44486d; ${this.fontFamily}}  ${editorStyles}`;
      if (this.customStyleContent != newColor) {
        this.customStyleContent = newColor;
        this.showEditor = false;
        setTimeout(() => {
          this.showEditor = true;
        }, 1);
      }
    });
  }
  onKendoEditorCustomToolbarClick(event: KendoEditorDropdownToolbarData, editor: EditorComponent) {
    if (event.value) {
      const editorCommand: EditorCommand = event.clickActionType || 'insertText';
      if (editorCommand == 'insertText') {
        editor.exec('insertText', { text: '#CURSOR#' });
        editor.value = editor.value.replace(/#CURSOR#/, event.value);
      } else {
        editor.exec(editorCommand, event.value);
      }
    }
  }
  onKendoEditorCustomToolbarDropdownOpen(event) {
    this.onCustomDropdownOpen.emit(event);
  }
  public open() {
    this.dialog.open();
  }
}
export interface KendoEditorDropdownToolbarData {
  text: string;
  value: string;
  clickActionType?: EditorCommand;
  click?: () => {};
  svgIcon?: SVGIcon;
}
const editorStyles = `::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
  display: none;
}
div::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
  display: none;
}
body::-webkit-scrollbar,
div::-webkit-scrollbar,
gridster::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track,
gridster::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
gridster::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background-color: #85838d;
  border-radius: 3px;
}`;
