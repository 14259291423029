/**
 * Sightflare Service
 * Rest Api for Sightflare Service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ApiResponseListErrorsMessages { 
    status?: number;
    data?: Array<ApiResponseListErrorsMessages.DataEnum>;
    error?: boolean;
    errorMessage?: string;
}
export namespace ApiResponseListErrorsMessages {
    export type DataEnum = 'DUPLICATE_KEY_VIOLATION' | 'PERMANENT_DELETE_REQUIRED';
    export const DataEnum = {
        DuplicateKeyViolation: 'DUPLICATE_KEY_VIOLATION' as DataEnum,
        PermanentDeleteRequired: 'PERMANENT_DELETE_REQUIRED' as DataEnum
    };
}


