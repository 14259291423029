import { Injectable } from '@angular/core';
// import { LocalizationService, MessageService } from '@progress/kendo-angular-l10n';
import { LanguageDto, translate } from '@shared/classes';
import { NgxCsvParser } from 'ngx-csv-parser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private _language = new BehaviorSubject<LanguageDto>(null);
  languageState = this._language.asObservable();

  get language() {
    return this._language.getValue();
  }
  set language(state: LanguageDto) {
    this._language.next(state);
  }
  languageFile: any;
  constructor(
    private ngxCsvParser: NgxCsvParser
    // private localizationService: LocalizationService,
    // private messages: MessageService
  ) {}
  async setLanguage(lang: LanguageDto, file: Blob) {
    // this.languageFile = file;
    // this.languageFile = this.transformArrayToObject(file as string[][]);
    if (!file) {
      localStorage.removeItem('langFile');
    }
    await this.parseCsv(file);
    this.language = lang;
    // this.messages.notify(lang?.direction == 'RTL');
  }
  translate(key: string, fallbackValue?: string, langFile = this.languageFile) {
    return translate(key, langFile, fallbackValue);
  }
  parseCsv(csvText: any): Promise<any> {
    const csvBlob = new Blob([csvText], { type: 'text/csv' });
    const csvFile = new File([csvBlob], 'data.csv', { type: 'text/csv' });

    return new Promise((resolve, reject) => {
      this.ngxCsvParser
        .parse(csvFile, { header: false, delimiter: ',', encoding: 'utf8' })
        .pipe()
        .subscribe({
          next: (result): void => {
            this.languageFile = this.transformArrayToObject(result as string[][]);
            localStorage.setItem('langFile', JSON.stringify(this.languageFile));
            resolve(this.languageFile); // Resolve the promise with the result
          },
          error: (err): void => {
            reject(err); // Reject the promise in case of an error
          },
        });
    });
  }
  downloadTemplateFile() {
    const link = document.createElement('a');
    link.href = 'assets/public/translation_template.csv'; // path to your file in the assets folder
    link.download = 'translation_template.csv'; // the name the file will be downloaded as
    link.click();
    setTimeout(function () {
      link.remove();
    }, 100);
  }
  transformArrayToObject(arr: string[][]): any {
    const result: any = {};
    arr.forEach(([key, _, translation]) => {
      const keyParts = key.split('.');
      keyParts.reduce((acc, part, index) => {
        if (!acc[part]) {
          acc[part] = {};
        }
        if (index === keyParts.length - 1) {
          acc[part] = translation;
        }
        return acc[part];
      }, result);
    });
    return result;
  }
}
