import { PermissionActions } from './AppPermissions.enum';
import { ModuleKeywords } from './ModuleKeywords';

export const MessageKeys = {
  //@TODONewModule: add messages once done
  createComment: 'Comment Created Successfully',
  updateComment: 'Comment Updated Successfully',
  deleteComment: 'Comment Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Relation]: 'Relation Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Relation]: 'Relation Created Successfully',
  [PermissionActions.Delete + ModuleKeywords.Relation]: 'Relation Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.RelationType]: 'Relation Type Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RelationType]: 'Relation Type Created Successfully',
  [PermissionActions.Delete + ModuleKeywords.RelationType]: 'Relation Type Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.EntityType]: 'Created Entity Type Successfully',
  [PermissionActions.Update + ModuleKeywords.EntityType]: 'Updated Entity Type Successfully',
  [PermissionActions.Delete + ModuleKeywords.EntityType]: 'Deleted Entity Type Successfully',
  createFolder: 'Created Folder Successfully',
  success: 'Success',
  failed: 'Failed',
  errorHappened: 'Error Happened',
  tryAgainLater: 'Try Again Later',
  editedSuccessfully: 'Edited Successfully',
  invalidAction: 'Invalid Action',
  sendAssessment: 'Risk Submitted Successfully',
  updateAllMatchingRows: 'All Matching Rows Updated Successfully',
  changedPassword: 'Changed Password Successfully',
  assignedRiskItems: 'Assigned Risk Items Successfully',
  columnsAssigned: 'Columns Assigned Successfully',
  noResultsFound: 'No Results Found!',
  [PermissionActions.Create + 'Entity']: 'Entity Created Successfully',
  [PermissionActions.Update + 'Entity']: 'Entity Updated Successfully',
  [PermissionActions.Delete + 'Entity']: 'Entity Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Question]: 'Question Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Question]: 'Question Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Question]: 'Question Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Questionnaire]: 'Questionnaire Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Questionnaire]: 'Questionnaire Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Questionnaire]: 'Questionnaire Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.QuestionnaireValue]: 'Questionnaire Answer Created Successfully',
  [PermissionActions.Update + ModuleKeywords.QuestionnaireValue]: 'Questionnaire Answer Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.QuestionnaireValue]: 'Questionnaire Answer Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.MyQuestionnaireValue]: 'My Questionnaire Answer Created Successfully',
  [PermissionActions.Update + ModuleKeywords.MyQuestionnaireValue]: 'My Questionnaire Answer Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.MyQuestionnaireValue]: 'My Questionnaire Answer Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.RiskItem]: 'Risk Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RiskItem]: 'Risk Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.RiskItem]: 'Risk Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.RiskAssessmentRequest]: 'Risk Assessment Request Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RiskAssessmentRequest]: 'Risk Assessment Request Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.RiskAssessmentRequest]: 'Risk Assessment Request Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.MyRiskAssessmentRequest]: 'Risk Assessment Request Created Successfully',
  [PermissionActions.Update + ModuleKeywords.MyRiskAssessmentRequest]: 'Risk Assessment Request Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.MyRiskAssessmentRequest]: 'Risk Assessment Request Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.RiskProject]: 'Risk Project Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RiskProject]: 'Risk Project Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.RiskProject]: 'Risk Project Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.RiskStatement]: 'Risk Statement Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RiskStatement]: 'Risk Statement Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.RiskStatement]: 'Risk Statement Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.RiskStatementCategory]: 'Risk Statement Category Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RiskStatementCategory]: 'Risk Statement Category Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.RiskStatementCategory]: 'Risk Statement Category Deleted Successfully',

  [PermissionActions.Create + ModuleKeywords.RiskMethodology]: 'Risk Methodology Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RiskMethodology]: 'Risk Methodology Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.RiskMethodology]: 'Risk Methodology Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Control]: 'Control Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Control]: 'Control Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Control]: 'Control Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.ControlGroup]: 'Control Objective Created Successfully',
  [PermissionActions.Update + ModuleKeywords.ControlGroup]: 'Control Objective Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.ControlGroup]: 'Control Objective Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Policy]: 'Policy Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Policy]: 'Policy Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Policy]: 'Policy Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Exception]: 'Exception Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Exception]: 'Exception Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Exception]: 'Exception Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.CitationType]: 'Citation Type Created Successfully',
  [PermissionActions.Update + ModuleKeywords.CitationType]: 'Citation Type Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.CitationType]: 'Citation Type Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Citation]: 'Citation Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Citation]: 'Citation Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Citation]: 'Citation Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Task]: 'Task Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Task]: 'Task Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Task]: 'Task Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.IssueType]: 'Issue Type Created Successfully',
  [PermissionActions.Update + ModuleKeywords.IssueType]: 'Issue Type Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.IssueType]: 'Issue Type Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Issue]: 'Issue Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Issue]: 'Issue Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Issue]: 'Issue Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Remediation]: 'Remediation Plan Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Remediation]: 'Remediation Plan Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Remediation]: 'Remediation Plan Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.User]: 'User Created Successfully',
  [PermissionActions.Update + ModuleKeywords.User]: 'User Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.User]: 'User Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Role]: 'Role Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Role]: 'Role Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Role]: 'Role Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Group]: 'Group Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Group]: 'Group Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Group]: 'Group Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Finding]: 'Finding Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Finding]: 'Finding Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Finding]: 'Finding Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Asset]: 'Asset Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Asset]: 'Asset Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Asset]: 'Asset Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Event]: 'Event Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Event]: 'Event Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Event]: 'Event Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Geography]: 'Geographies Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Geography]: 'Geographies Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Geography]: 'Geographies Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Objective]: 'Objective Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Objective]: 'Objective Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Objective]: 'Objective Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Process]: 'Process Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Process]: 'Process Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Process]: 'Process Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Responsibility]: 'Responsibility Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Responsibility]: 'Responsibility Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Responsibility]: 'Responsibility Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Organization]: 'Organization Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Organization]: 'Organization Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Organization]: 'Organization Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Acknowledgment]: 'Acknowledgment Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Acknowledgment]: 'Acknowledgment Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Acknowledgment]: 'Acknowledgment Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AcknowledgmentRequest]: 'Acknowledgment Request Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AcknowledgmentRequest]: 'Acknowledgment Request Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AcknowledgmentRequest]: 'Acknowledgment Request Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Notification]: 'Notification Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Notification]: 'Notification Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Notification]: 'Notification Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.FieldTemplate]: 'Field Template Created Successfully',
  [PermissionActions.Update + ModuleKeywords.FieldTemplate]: 'Field Template Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.FieldTemplate]: 'Field Template Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.PolicyBody]: 'Policy Body Created Successfully',
  [PermissionActions.Update + ModuleKeywords.PolicyBody]: 'Policy Body Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.PolicyBody]: 'Policy Body Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Attestation]: 'Created Attestation Successfully',
  [PermissionActions.Update + ModuleKeywords.Attestation]: 'Updated Attestation Successfully',
  [PermissionActions.Delete + ModuleKeywords.Attestation]: 'Deleted Attestation Successfully',
  [PermissionActions.Create + ModuleKeywords.GlobalValueList]: 'Created Global Value List Successfully',
  [PermissionActions.Update + ModuleKeywords.GlobalValueList]: 'Updated Global Value List Successfully',
  [PermissionActions.Delete + ModuleKeywords.GlobalValueList]: 'Deleted Global Value List Successfully',
  [PermissionActions.Create + ModuleKeywords.ComplianceReport]: 'Created Compliance Report Successfully',
  [PermissionActions.Update + ModuleKeywords.ComplianceReport]: 'Updated Compliance Report Successfully',
  [PermissionActions.Delete + ModuleKeywords.ComplianceReport]: 'Deleted Compliance Report Successfully',
  [PermissionActions.Create + ModuleKeywords.ComplianceReportResult]: 'Created Compliance Report Result Successfully',
  [PermissionActions.Update + ModuleKeywords.ComplianceReportResult]: 'Updated Compliance Report Result Successfully',
  [PermissionActions.Delete + ModuleKeywords.ComplianceReportResult]: 'Deleted Compliance Report Result Successfully',
  [PermissionActions.Create + ModuleKeywords.ControlsGapReport]: 'Created Gap Report Successfully',
  [PermissionActions.Update + ModuleKeywords.ControlsGapReport]: 'Updated Gap Report Successfully',
  [PermissionActions.Delete + ModuleKeywords.ControlsGapReport]: 'Deleted Gap Report Successfully',
  [PermissionActions.Create + ModuleKeywords.ControlsGapReportResult]: 'Created Gap Report Result Successfully',
  [PermissionActions.Update + ModuleKeywords.ControlsGapReportResult]: 'Updated Gap Report Result Successfully',
  [PermissionActions.Delete + ModuleKeywords.ControlsGapReportResult]: 'Deleted Gap Report Result Successfully',
  [PermissionActions.Create + ModuleKeywords.EmailTemplate]: 'Created Email Template Successfully',
  [PermissionActions.Update + ModuleKeywords.EmailTemplate]: 'Updated Email Template Successfully',
  [PermissionActions.Delete + ModuleKeywords.EmailTemplate]: 'Deleted Email Template Successfully',
  [PermissionActions.Create + ModuleKeywords.TaskTemplate]: 'Created Task Template Successfully',
  [PermissionActions.Update + ModuleKeywords.TaskTemplate]: 'Updated Task Template Successfully',
  [PermissionActions.Delete + ModuleKeywords.TaskTemplate]: 'Deleted Task Template Successfully',
  [PermissionActions.Create + ModuleKeywords.MyAcknowledgment]: 'Created Acknowledgment Successfully',
  [PermissionActions.Update + ModuleKeywords.MyAcknowledgment]: 'Updated Acknowledgment Successfully',
  [PermissionActions.Delete + ModuleKeywords.MyAcknowledgment]: 'Deleted Acknowledgment Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditTrail]: 'Created Audit Trail Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditTrail]: 'Updated Audit Trail Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditTrail]: 'Deleted Audit Trail Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditUniverse]: 'Audit Universe Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditUniverse]: 'Audit Universe Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditUniverse]: 'Audit Universe Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditProgramType]: 'Audit Program Type Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditProgramType]: 'Audit Program Type Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditProgramType]: 'Audit Program Type Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditProgram]: 'Audit Program Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditProgram]: 'Audit Program Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditProgram]: 'Audit Program Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditProgramReport]: 'Audit Program Report Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditProgramReport]: 'Audit Program Report Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditProgramReport]: 'Audit Program Report Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditCaseTemplate]: 'Audit Task Template Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditCaseTemplate]: 'Audit Task Template Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditCaseTemplate]: 'Audit Task Template Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditProject]: 'Audit Project Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditProject]: 'Audit Project Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditProject]: 'Audit Project Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditProjectReport]: 'Audit Project Report Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditProjectReport]: 'Audit Project Report Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditProjectReport]: 'Audit Project Report Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditCaseResult]: 'Audit Case Result Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditCaseResult]: 'Audit Case Result Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditCaseResult]: 'Audit Case Result Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditRequest]: 'Audit Request Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditRequest]: 'Audit Request Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditRequest]: 'Audit Request Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuditExpense]: 'Audit Expense Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuditExpense]: 'Audit Expense Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuditExpense]: 'Audit Expense Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.RiskThreat]: 'Risk Threat Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RiskThreat]: 'Risk Threat Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.RiskThreat]: 'Risk Threat Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.RiskVulnerability]: 'Risk Vulnerability Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RiskVulnerability]: 'Risk Vulnerability Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.RiskVulnerability]: 'Risk Vulnerability Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Filter]: 'Filter Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Filter]: 'Filter Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Filter]: 'Filter Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.ContentView]: 'Content View Created Successfully',
  [PermissionActions.Update + ModuleKeywords.ContentView]: 'Content View Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.ContentView]: 'Content View Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Dashboard]: 'Dashboard Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Dashboard]: 'Dashboard Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Dashboard]: 'Dashboard Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Report]: 'Report Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Report]: 'Report Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Report]: 'Report Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.TransparencyCaseCategory]:
    'Transparency Case Category Created Successfully',
  [PermissionActions.Update + ModuleKeywords.TransparencyCaseCategory]:
    'Transparency Case Category Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.TransparencyCaseCategory]:
    'Transparency Case Category Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.TransparencyCase]: 'Transparency Case Created Successfully',
  [PermissionActions.Update + ModuleKeywords.TransparencyCase]: 'Transparency Case  Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.TransparencyCase]: 'Transparency Case  Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.ExportGlobalPackage]: 'Export Global Package Created Successfully',
  [PermissionActions.Update + ModuleKeywords.ExportGlobalPackage]: 'Export Global Package  Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.ExportGlobalPackage]: 'Export Global Package  Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Standard]: 'Standard Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Standard]: 'Standard Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Standard]: 'Standard Deleted Successfully',
  // [PermissionActions.Create + ModuleKeywords.StandardSection]: 'Standard Section Created Successfully',
  // [PermissionActions.Update + ModuleKeywords.StandardSection]: 'Standard Section Updated Successfully',
  // [PermissionActions.Delete + ModuleKeywords.StandardSection]: 'Standard Section Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Guideline]: 'Guideline Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Guideline]: 'Guideline Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Guideline]: 'Guideline Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.AuthorityDocument]: 'Authority Document Created Successfully',
  [PermissionActions.Update + ModuleKeywords.AuthorityDocument]: 'Authority Document Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.AuthorityDocument]: 'Authority Document Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.ControlCategory]: 'Control Category Created Successfully',
  [PermissionActions.Update + ModuleKeywords.ControlCategory]: 'Control Category Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.ControlCategory]: 'Control Category Deleted Successfully',
  [PermissionActions.Delete + ModuleKeywords.ControlProcedure]: 'Control Procedure Deleted Successfully',
  [PermissionActions.Delete + ModuleKeywords.ControlProcedure]: 'Control Procedure Deleted Successfully',
  [PermissionActions.Delete + ModuleKeywords.ControlProcedure]: 'Control Procedure Deleted Successfully',
  ExportGlobalPackageRunSuccessfully: 'Global Package Ran Successfully',
  ExportGlobalPackageScheduledSuccessfully: 'Global Package Scheduled Successfully',
  [PermissionActions.Create + ModuleKeywords.StatisticCollector]: 'Statistic Collector Created Successfully',
  [PermissionActions.Update + ModuleKeywords.StatisticCollector]: 'Statistic Collector Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.StatisticCollector]: 'Statistic Collector Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.GeneratedStatistic]: 'Generated Statistic Created Successfully',
  [PermissionActions.Update + ModuleKeywords.GeneratedStatistic]: 'Generated Statistic Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.GeneratedStatistic]: 'Generated Statistic Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.ReportConfigurationTemplate]: 'Report Configuration Created Successfully',
  [PermissionActions.Update + ModuleKeywords.ReportConfigurationTemplate]: 'Report Configuration Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.ReportConfigurationTemplate]: 'Report Configuration Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Tag]: 'Tag Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Tag]: 'Tag Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Tag]: 'Tag Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.ReportScheduler]: 'Report Scheduler Created Successfully',
  [PermissionActions.Update + ModuleKeywords.ReportScheduler]: 'Report Scheduler Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.ReportScheduler]: 'Report Scheduler Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Metric]: 'Metric Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Metric]: 'Metric Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Metric]: 'Metric Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.MetricValue]: 'Metric Value Created Successfully',
  [PermissionActions.Update + ModuleKeywords.MetricValue]: 'Metric Value Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.MetricValue]: 'Metric Value Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.MetricCategory]: 'Metric Category Created Successfully',
  [PermissionActions.Update + ModuleKeywords.MetricCategory]: 'Metric Category Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.MetricCategory]: 'Metric Category Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.GeneratedReport]: 'Generated Report Created Successfully',
  [PermissionActions.Update + ModuleKeywords.GeneratedReport]: 'Generated Report Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.GeneratedReport]: 'Generated Report Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Language]: 'Language Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Language]: 'Language Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Language]: 'Language Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Campaign]: 'Campaign Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Campaign]: 'Campaign Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Campaign]: 'Campaign Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.QuestionnaireRequest]: 'Questionnaire Request Created Successfully',
  [PermissionActions.Update + ModuleKeywords.QuestionnaireRequest]: 'Questionnaire Request Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.QuestionnaireRequest]: 'Questionnaire Request Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.Workspace]: 'Workspace Created Successfully',
  [PermissionActions.Update + ModuleKeywords.Workspace]: 'Workspace Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.Workspace]: 'Workspace Deleted Successfully',
  [PermissionActions.Create + ModuleKeywords.RemediationAction]: 'Remediation Action Created Successfully',
  [PermissionActions.Update + ModuleKeywords.RemediationAction]: 'Remediation Action Updated Successfully',
  [PermissionActions.Delete + ModuleKeywords.RemediationAction]: 'Remediation Action Deleted Successfully',
};
