import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  ApiResponseRiskMethodologyEvent,
  IAction,
  ItemBasePage,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  RiskMethodologyDto,
  TargetTypeEnum,
  makePlural,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
} from '@shared/classes';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { RiskMethodologyImpactFactorDataService } from 'app/modules/risk/services/data/risk-methodology-impact-factor-data.service';
import { RiskMethodologyImpactValueDataService } from 'app/modules/risk/services/data/risk-methodology-impact-value-data.service';
import { RiskMethodologyLikelihoodValueDataService } from 'app/modules/risk/services/data/risk-methodology-likelihood-value-data.service';
import { RiskMethodologyThresholdValueDataService } from 'app/modules/risk/services/data/risk-methodology-threshold-value-data.service';
import { cloneDeep } from 'lodash-es';
import { RiskMethodologyDataService } from '../../../services/data/risk-methodology-data.service';

@Component({
  selector: 'app-risk-methodology-item',
  templateUrl: './risk-methodology-item.component.html',
  styleUrls: ['./risk-methodology-item.component.scss'],
})
export class RiskMethodologyItemComponent extends ItemBasePage<RiskMethodologyDto> implements OnInit {
  toDraftAction: IAction = {
    id: 1,
    label: 'Return to Draft',
    buttonType: 'button',
    buttonClass: 'ml-2',
    command: this.onChangeStatus.bind(this, ApiResponseRiskMethodologyEvent.DataEnum.Draft),
    icon: 'fas fa-clock-rotate-left',
    color: 'danger',
  };
  toPublishedAction: IAction = {
    id: 2,
    label: 'Publish',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseRiskMethodologyEvent.DataEnum.Published),
    icon: 'fas fa-check-double',
    color: 'success',
  };
  toApprovedAction: IAction = {
    id: 3,
    label: 'Approve',
    buttonType: 'button',
    buttonClass: 'ml-2',
    command: this.onChangeStatus.bind(this, ApiResponseRiskMethodologyEvent.DataEnum.Approved),
    icon: 'fas fa-check',
    color: 'success',
  };
  toReviewAction: IAction = {
    id: 4,
    label: 'Send For Review',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseRiskMethodologyEvent.DataEnum.Review),
    icon: 'far fa-eye',
    color: 'help',
  };

  prevMeth;

  constructor(
    private route: ActivatedRoute,
    public requestService: RiskMethodologyDataService,
    public impactFactorService: RiskMethodologyImpactFactorDataService,
    public impactValueService: RiskMethodologyImpactValueDataService,
    public likelihoodValueService: RiskMethodologyLikelihoodValueDataService,
    public thresholdValueService: RiskMethodologyThresholdValueDataService,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private router: Router,
    public localeService: LocaleService
  ) {
    super(
      { moduleKeyword: ModuleKeywords.RiskMethodology, routePrefix: ModuleRoutePrefix.Risk },
      router,
      requestService,
      toastService
    );
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.itemId = params['id'];
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(this.ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
                ),
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
                ),
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId
                  ? this.itemId
                  : this.localeService.translate('general.actions.add') +
                  ' ' +
                  this.localeService.translate(
                    `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
                  ),
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.Policy,
        });
      },
    });
  }

  ngOnInit(): void { }

  // async onSubmit(data, redirectState: 'BACK' | 'PAGE' | 'NONE' = 'BACK') {
  //   const edit = this.editMode && this.viewMode != 'create';
  //   let impactFactorRes = null;
  //   let impactValueRes = null;
  //   let likelihoodValueRes = null;
  //   let thresholdValueRes = null;

  //   const impactFactorReq = (edit ? data?.value : data?.value?.createItems)?.find(x => x.key == 'impactFactorsDto')?.value?.filter((x, index) => {
  //     return !x.code || this.getDataKeyValueFormat(x, this.prevMeth?.impactFactorsDto?.find(y => y.code == x.code))?.length;
  //   }).map(x => x.code ? this.impactFactorService.patchSingleUpdate(this.getDataKeyValueFormat(x, this.prevMeth?.impactFactorsDto?.find(y => y.code == x.code)), x?.code) : this.impactFactorService.create({ createItems: this.getDataKeyValueFormat({ ...x, code: undefined }) }))
  //   // : data?.createItems?.find(x => x.key == 'impactFactorsDto')?.value?.map(x => this.impactFactorService.create({ createItems: this.getDataKeyValueFormat({ ...x, code: undefined }) }));

  //   // const impactValueReq = (edit ? data?.value : data?.value?.createItems)?.find(x => x.key == 'impactValuesDto')?.value?.filter((x, index) => {
  //   //   return !x.code || this.getDataKeyValueFormat(x, this.prevMeth?.impactValuesDto?.find(y => y.code == x.code))?.length;
  //   // }).map(x => x.code ? this.impactValueService.patchSingleUpdate(this.getDataKeyValueFormat(x, this.prevMeth?.impactValuesDto?.find(y => y.code == x.code)), x?.code) : this.impactValueService.create({ createItems: this.getDataKeyValueFormat({ ...x, code: undefined }) }))
  //   // // : data?.createItems?.find(x => x.key == 'impactValuesDto')?.value?.map(x => this.impactValueService.create({ createItems: this.getDataKeyValueFormat({ ...x, code: undefined }) }));

  //   const likelihoodValueReq = (edit ? data?.value : data?.value?.createItems)?.find(x => x.key == 'likelihoodValuesDto')?.value?.filter((x, index) => {
  //     return !x.code || this.getDataKeyValueFormat(x, this.prevMeth?.likelihoodValuesDto?.find(y => y.code == x.code))?.length;
  //   }).map(x => x.code ? this.likelihoodValueService.patchSingleUpdate(this.getDataKeyValueFormat(x, this.prevMeth?.likelihoodValuesDto?.find(y => y.code == x.code)), x?.code) : this.likelihoodValueService.create({ createItems: this.getDataKeyValueFormat({ ...x, code: undefined }) }))
  //   // : data?.createItems?.find(x => x.key == 'likelihoodValuesDto')?.value?.map(x => this.likelihoodValueService.create({ createItems: this.getDataKeyValueFormat({ ...x, code: undefined }) }));

  //   const thresholdValueReq = (edit ? data?.value : data?.value?.createItems)?.find(x => x.key == 'thresholdValuesDto')?.value?.filter((x, index) => {
  //     return !x.code || this.getDataKeyValueFormat(x, this.prevMeth?.thresholdValuesDto?.find(y => y.code == x.code))?.length;
  //   }).map(x => x.code ? this.thresholdValueService.patchSingleUpdate(this.getDataKeyValueFormat(x, this.prevMeth?.thresholdValuesDto?.find(y => y.code == x.code)), x?.code) : this.thresholdValueService.create({ createItems: this.getDataKeyValueFormat({ ...x, code: undefined }) }))
  //   // : data?.createItems?.find(x => x.key == 'thresholdValuesDto')?.value?.map(x => this.thresholdValueService.create({ createItems: this.getDataKeyValueFormat({ ...x, code: undefined }) }));


  //   if (impactFactorReq?.length) {
  //     impactFactorRes = await forkJoin(
  //       impactFactorReq.map(x =>
  //         x.pipe(catchError(err => {
  //           console.error('Error in impactFactorReq:', err);
  //           return of(null); // Handle error and continue
  //         }))
  //       )
  //     ).toPromise();
  //     if (impactFactorRes?.length) {
  //       if (edit) {
  //         const mySet = new Set([...(impactFactorRes.filter(x => x?.data?.code).map(x => x?.data?.code)), ...(data?.obj?.impactFactorsDto?.filter(x => x?.code).map(x => x?.code))]);
  //         data?.value?.push({ key: 'impactFactors', value: Array.from(new Set(mySet)) });
  //       } else {
  //         data?.value?.createItems?.push({ key: 'impactFactors', value: impactFactorRes.map(x => x?.data?.code) });
  //       }
  //     }
  //   }


  //   if (data?.obj?.impactValuesDto?.length) {
  //     if (edit) {
  //       const list = [...(data?.obj?.impactValuesDto?.filter(x => x?.code).map(x => x?.code))];
  //       data?.value?.push({ key: 'impactValues', value: list });
  //     } else {
  //       data?.value?.createItems?.push({ key: 'impactValues', value: impactValueRes.map(x => x?.data?.code) });
  //     }
  //   }
  //   // if (impactValueReq?.length) {
  //   //   impactValueRes = await forkJoin(
  //   //     impactValueReq.map(x =>
  //   //       x.pipe(catchError(err => {
  //   //         console.error('Error in impactValueReq:', err);
  //   //         return of(null); // Handle error and continue
  //   //       }))
  //   //     )
  //   //   ).toPromise();
  //   //   if (impactValueRes?.length) {
  //   //     if (edit) {
  //   //       const mySet = new Set([...(impactValueRes.filter(x => x?.data?.code).map(x => x?.data?.code)), ...(data?.obj?.impactValuesDto?.filter(x => x?.code).map(x => x?.code))]);
  //   //       data?.value?.push({ key: 'impactValues', value: Array.from(new Set(mySet)) });
  //   //     } else {
  //   //       data?.value?.createItems?.push({ key: 'impactValues', value: impactValueRes.map(x => x?.data?.code) });
  //   //     }
  //   //   }
  //   // }

  //   if (likelihoodValueReq?.length) {
  //     likelihoodValueRes = await forkJoin(
  //       likelihoodValueReq.map(x =>
  //         x.pipe(catchError(err => {
  //           console.error('Error in likelihoodValueReq:', err);
  //           return of(null); // Handle error and continue
  //         }))
  //       )
  //     ).toPromise();
  //     if (likelihoodValueRes?.length) {
  //       if (edit) {
  //         const mySet = new Set([...(likelihoodValueRes.filter(x => x?.data?.code).map(x => x?.data?.code)), ...(data?.obj?.likelihoodValuesDto?.filter(x => x?.code).map(x => x?.code))]);
  //         data?.value?.push({ key: 'likelihoodValues', value: Array.from(new Set(mySet)) });
  //       } else {
  //         data?.value?.createItems?.push({ key: 'likelihoodValues', value: likelihoodValueRes.map(x => x?.data?.code) });
  //       }
  //     }
  //   }

  //   if (thresholdValueReq?.length) {
  //     thresholdValueRes = await forkJoin(
  //       thresholdValueReq.map(x =>
  //         x.pipe(catchError(err => {
  //           console.error('Error in thresholdValueReq:', err);
  //           return of(null); // Handle error and continue
  //         }))
  //       )
  //     ).toPromise();
  //     if (thresholdValueRes?.length) {
  //       if (edit) {
  //         const mySet = new Set([...(thresholdValueRes.filter(x => x?.data?.code).map(x => x?.data?.code)), ...(data?.obj?.thresholdValuesDto?.filter(x => x?.code).map(x => x?.code))]);
  //         data?.value?.push({ key: 'thresholdValues', value: Array.from(new Set(mySet)) });
  //       } else {
  //         data?.value?.createItems?.push({ key: 'thresholdValues', value: thresholdValueRes.map(x => x?.data?.code) });
  //       }
  //     }
  //   }

  //   if (edit) {
  //     data.value = data?.value.filter(x => x.key != 'thresholdValuesDto' && x.key != 'likelihoodValuesDto' && x.key != 'impactValuesDto' && x.key != 'impactFactorsDto');
  //   } else {
  //     data.value.createItems = data?.value?.createItems?.filter(x => x.key != 'thresholdValuesDto' && x.key != 'likelihoodValuesDto' && x.key != 'impactValuesDto' && x.key != 'impactFactorsDto');
  //   }


  //   this.editMode && this.viewMode != 'create'
  //     ? this.updateItemV3(data?.value, redirectState)
  //     : this.createItem(data?.value, redirectState);
  //   // this.requestService.patchUpdate(data).subscribe((res) => {
  //   //   this.toastService.success('', 'Risk Methodology Updated');
  //   //   this.riskMethodologyDataService.navigateToListPage();
  //   // });
  // }

  getDataKeyValueFormat(fields, prevValues = null) {
    let resFields: { key: string; value: any }[] = [];
    Object.keys(fields).forEach((key) => {
      if (!prevValues || (prevValues && fields[key] != prevValues[key]))
        resFields.push({ key: key, value: fields[key] !== undefined ? fields[key] : null });
    });

    return resFields;
  }

  formChanges(data) { }
  get actionsMap() {
    return {
      [ApiResponseRiskMethodologyEvent.DataEnum.Approved]: this.toApprovedAction,
      [ApiResponseRiskMethodologyEvent.DataEnum.Draft]: this.toDraftAction,
      [ApiResponseRiskMethodologyEvent.DataEnum.Published]: this.toPublishedAction,
      [ApiResponseRiskMethodologyEvent.DataEnum.Review]: this.toReviewAction,
    };
  }

  afterGetitemData() {
    this.prevMeth = cloneDeep({ ...this.data });
  }

  onChangeStatus(status: ApiResponseRiskMethodologyEvent.DataEnum) {
    this.requestService.changeStatus(this.itemId, status).subscribe((red) => {
      // this.requestService.navigateToListPage();
      this.getItemData(this.itemId);
      this.refreshRules();
      // this.data.acknowledgmentStatus = StatusUpdateMap[status];
    });
  }
  // onViewModeChange(viewMode: IViewMode) {
  //   this.router.navigateByUrl(this.getPageViewModeLink(viewMode, this.itemId));
  // }
  // getPageViewModeLink(viewMode: IViewMode, id = null) {
  //   return `/${this.routePrefix}${toKebabCase(this.moduleKeyword)}${this.routeMiddlefix}/${viewMode}`;
  // }
}
