import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, locales } from '@shared/classes';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss',
  host: {
    '[style.display]': 'content',
  },
})
export class LanguageSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() langFile: any = null;
  localesMap = {};
  dataType: DataTypeEnum = DataTypeEnum.LanguageCodeView;
  @Input() optionValue = 'langCode';
  ngOnInit(): void {
    locales.forEach((locale) => {
      this.localesMap[locale.locale] = locale.code.toLowerCase();
    });
  }
  setInputOptions(): void {
    this.langFile = this.inputOptions?.languageInput?.langFile ?? this.langFile;
  }
}
