import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuditUniverse } from '@shared/classes/model/backend/audit';
import { IFieldDefinition } from '@shared/classes/view/AppFieldDefinition';
import { DataTypeEnum } from '@shared/classes/view/view-enums';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { UserAndGroupSelectorComponent } from '@shared/components/selectors/user-and-group-selector/user-and-group-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { DropDownInputComponent } from '@shared/components/ui/input-fields/drop-down-input/drop-down-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { BaseFieldDefinitionsService } from '@shared/services/base-field-definitions.service';

@Injectable({
  providedIn: 'root',
})
export class AuditUniverseMappingService extends BaseFieldDefinitionsService {
  // "entities",
  // "auditUniverseStatus",
  recordFields: IFieldDefinition[] = [
    {
      name: 'Name',
      key: 'name',
      dataType: DataTypeEnum.Text,
      required: false,
      showInTable: true,
      description: 'Name of the record',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: BasicInputComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Enter a name',
          control: new FormControl('', Validators.required),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Approver',
      key: 'approver',
      dataType: DataTypeEnum.ResponsibilityListView,
      required: false,
      showInTable: true,
      description: 'Users who should approve',
      tableDefinition: {
        filter: {
          extraKey: 'name',
          type: 'userAndGroupSelector',
          matchMode: 'equals',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
        },
      },
      useInBulk: true,
      formField: {
        componentType: UserAndGroupSelectorComponent,
        options: {
          label: 'Approver',
          name: 'approver',
          placeholder: 'Search...',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            extra: { principleMode: true },
            dropDownInput: {
              optionValue: 'undefined',
              multi: false,
              items: null,
              optionLabel: 'name'
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Audit Universe Status',
      key: 'auditUniverseStatus',
      dataType: DataTypeEnum.Badge,
      required: false,
      showInTable: true,
      description: 'Audit Universe Status of the record',
      useInBulk: true,
      formField: {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: '',
          name: '',
          placeholder: 'Search Entities',
          control: new FormControl(null, Validators.required),
          inputOptions: {
            codeSelectorInput: {
              targetTypes: ['RESPONSIBILITY', 'ASSET', 'OBJECTIVE', 'PROCESS', 'EVENT', 'GEOGRAPHY', 'ORGANIZATION'],
            },
            dropDownInput: {
              optionLabel: 'name',
              optionValue: 'code',
              multi: true,
              items: []
            },
          },
          showLabelInViewMode: false,
        },
      },
    },
    {
      name: 'Entities',
      key: 'entities',
      dataType: DataTypeEnum.Badge,
      required: true,
      showInTable: false,
      description: 'Entities of the record',
      tableDefinition: {
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: AuditUniverse.AuditUniverseStatusEnum,
        },
      },
      formField: {
        componentType: DropDownInputComponent,
        options: {
          label: '',
          name: '',
          control: new FormControl(null)
        },
      },
    },
    {
      name: 'Description',
      key: 'description',
      dataType: DataTypeEnum.Text,
      required: true,
      showInTable: false,
      description: 'Record Description',
      tableDefinition: {
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      useInBulk: true,
      formField: {
        componentType: TextEditorComponent,
        options: {
          inputOptions: {
            textEditorInput: {
              advanced: true
            }
          },
          label: '',
          name: '',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
      },
    },
  ];
  constructor() {
    super();
    this.setAllFields();
  }
}
