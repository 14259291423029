import { Component } from '@angular/core';

import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { Issue } from '@shared/classes/model/backend/issue';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import {
  ApiResponseIssueEvent,
  AppPermissions,
  DataTypeEnum,
  IAction,
  IColumn,
  ItemBasePage,
  makePlural,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  OfflineConfig,
  RecordStatusEnumFilterWithoutDeleted,
  RemediationDto,
  RequestHandlerOptions,
  routeToLocaleCase,
  TargetTypeEnum,
  toCamelCase,
  toKebabCase,
} from 'app/shared/classes';
import { combineLatest, takeUntil } from 'rxjs';
import { IssuesDataService } from '../../services/data/issues-data.service';

@Component({
  selector: 'app-issues-item',
  templateUrl: './issues-item.component.html',
  styleUrls: ['./issues-item.component.scss'],
})
export class IssuesItemComponent extends ItemBasePage<Issue> {
  realTimeFormData: any = {}; //Copy of form data that changes realtime when form changes

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: false,
  };
  toDraftAction: IAction = {
    id: 1,
    label: 'Return to Draft',
    buttonType: 'button',
    color: 'secondary',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.Draft),
    icon: 'fas fa-clock-rotate-left',
  };

  toRemediationAction: IAction = {
    id: 2,
    label: 'Remediation',
    buttonType: 'button',
    color: 'info',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.Remediation),
    icon: 'fas fa-user-clock',
  };
  toAnalysisAction: IAction = {
    id: 3,
    label: 'Analysis',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.Analysis),
    icon: 'fas fa-check-double',
    color: 'success',
  };
  toClosedResolvedAction: IAction = {
    id: 4,
    label: 'Close As Resolved',
    buttonType: 'button',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.ClosedResolved),
    icon: 'pi pi-check',
    color: 'success',
  };
  toClosedUnresolvedAction: IAction = {
    id: 5,
    label: 'Close As Unresolved',
    buttonType: 'button',
    color: 'danger',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.ClosedUnresolved),
    icon: 'pi pi-times',
  };
  toReviewAction: IAction = {
    id: 6,
    label: 'Review',
    buttonType: 'button',
    color: 'warning',
    command: this.onChangeStatus.bind(this, ApiResponseIssueEvent.DataEnum.Review),
    icon: 'pi pi-user-edit',
  };
  cols: IColumn[] = [];
  offlineConfig: OfflineConfig = {
    lazy: false,
    paginator: true,
    showActionBar: false,
  };
  get actionsMap() {
    return {
      [ApiResponseIssueEvent.DataEnum.Analysis]: this.toAnalysisAction,
      [ApiResponseIssueEvent.DataEnum.ClosedResolved]: this.toClosedResolvedAction,
      [ApiResponseIssueEvent.DataEnum.ClosedUnresolved]: this.toClosedUnresolvedAction,
      [ApiResponseIssueEvent.DataEnum.Draft]: this.toDraftAction,
      [ApiResponseIssueEvent.DataEnum.Remediation]: this.toRemediationAction,
      [ApiResponseIssueEvent.DataEnum.Review]: this.toReviewAction,
    };
  }
  get statusChangeValue() {
    return {
      [ApiResponseIssueEvent.DataEnum.Analysis]: Issue.IssueStatusEnum.Analysis,
      [ApiResponseIssueEvent.DataEnum.ClosedResolved]: Issue.IssueStatusEnum.ClosedResolved,
      [ApiResponseIssueEvent.DataEnum.ClosedUnresolved]: Issue.IssueStatusEnum.ClosedUnresolved,
      [ApiResponseIssueEvent.DataEnum.Draft]: Issue.IssueStatusEnum.Draft,
      [ApiResponseIssueEvent.DataEnum.Remediation]: Issue.IssueStatusEnum.Remediation,
      [ApiResponseIssueEvent.DataEnum.Review]: Issue.IssueStatusEnum.Review,
    };
  }
  currentStatusToChangeTo = null;
  onChangeStatus(status: ApiResponseIssueEvent.DataEnum) {
    this.currentStatusToChangeTo = status;
    this.changeStatusDialog(status);
    // this.requestService.changeStatus(this.itemId, status).subscribe(red => {
    //     // this.requestService.navigateToListPage();
    //     this.data.issueStatus = this.statusChangeValue[status];
    //     this.getItemData(this.itemId);
    //     this.refreshRules();
    // })
  }
  escalationsEventList: any[] = [];
  constructor(
    public requestService: IssuesDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    private appDialogService: AppDialogService,
    public localeService: LocaleService
  ) {
    super(
      {
        moduleKeyword: ModuleKeywords.Issue,
        routePrefix: ModuleRoutePrefix.IssueManagement,
      },
      router,
      requestService,
      toastService
    );

    combineLatest([this.activatedRoute.paramMap])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.itemId = data[0].get('id');
        this.editMode = !!this.itemId;
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(this.ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
                ),
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
                ),
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId
                  ? this.itemId
                  : this.localeService.translate('general.actions.add') +
                    ' ' +
                    this.localeService.translate(
                      `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
                    ),
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.Issue,
        });
      });
    this.initRemediationCols();
  }
  initRemediationCols() {
    this.cols = [
      {
        name: 'Code',
        key: 'code',
        dataType: DataTypeEnum.CodeWithStatus,
        passRowAsData: true,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
        // frozen: true,
        alignFrozen: 'left',
      },
      {
        name: 'Record Status',
        key: 'recordStatus',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RecordStatusEnumFilterWithoutDeleted,
        },
      },
      {
        name: 'Name',
        key: 'name',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
      },
      {
        name: 'Owner',
        key: 'owner.name',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      {
        name: 'Status',
        key: 'status',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RemediationDto.StatusEnum,
        },
      },
      {
        name: 'Issues',
        key: 'issues',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Issue,
        },
      },
      {
        name: 'Approvers',
        key: 'approvers',
        dataType: DataTypeEnum.ResponsibilityListView,
        permissions: AppPermissions.ReadResponsibility,
        filter: {
          type: 'recordCode',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          recordCodeType: TargetTypeEnum.Responsibility,
        },
      },
      {
        name: 'Progress',
        key: 'progress',
        dataType: DataTypeEnum.ProgressBar,
      },
      {
        name: 'start Date',
        key: 'startDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      {
        name: 'Due Date',
        key: 'dueDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      {
        name: 'actual Start Date',
        key: 'actualStartDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      {
        name: 'actual Close Date',
        key: 'actualCloseDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    ];
  }
  changeStatusDialog(status) {
    this.requestService.changeStatus(this.itemId, status, { messages: {} }).subscribe((red) => {
      // this.requestService.navigateToListPage();
      this.data.issueStatus = this.statusChangeValue[status];
      this.getItemData(this.itemId);
      this.refreshRules();
    });
    // this.appDialogService.showDialog(BaseFormPopupComponent, "Change Status", (data) => {

    //     if (data) {
    //         this.requestService.changeStatus(this.itemId, status,{messages:{}}).subscribe(red => {
    //             // this.requestService.navigateToListPage();
    //             this.data.issueStatus = this.statusChangeValue[status];
    //             this.getItemData(this.itemId);
    //             this.refreshRules();
    //         })
    //     }
    // }, {
    //     data:
    //     {
    //         dynamicViewComponent: IssueChangeStatusFormComponent,
    //         dataService: this.requestService,
    //         filters: [],
    //         selectedRows: [],
    //         patchData: false,
    //         formData: {}
    //     }
    // })
  }
  onSetData() {
    if (this.data && this.data?.issueType?.escalationsDto?.length > 0) {
      this.escalationsEventList = [
        { newAssignee: this.data?.assignee, date: new Date(this.data?.creationDate) },
        ...this.data?.issueType?.escalationsDto.map((item, index) => {
          return {
            newAssignee: item?.assignee,
            date: this.data?.escalationsHistoryDto?.[index]?.creationDate
              ? new Date(this.data?.escalationsHistoryDto?.[index]?.creationDate)
              : new Date(new Date().setDate(new Date(this.data?.creationDate).getDate() + item?.daysToResolve)),
          };
        }),
      ];
    }
  }
}
